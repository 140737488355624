import { sec } from '@/middlewares/security';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = await sec.getAccessTokenSilently()();
    headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
  credentials: 'include', // This allows server to set cookies
});

import React, { useEffect, useRef } from 'react';
import { InputProps } from 'antd';
import { IntusInput } from '@/shared/elements';
import { UnitSystemTypes } from '@/models';
import { useClickAway } from 'react-use';
import {
  addSpacesToThousands,
  removeSpacesFromThousands,
  sanitizeInputMetricValue,
} from '@/shared/helpers/format-data';

interface MeasurementInputProps extends InputProps {
  units: UnitSystemTypes;
  onMeasurementChange: (val: string) => void;
  onEscape: () => void;
}
export const MeasurementInput = ({
  onMeasurementChange,
  onEscape,
  units,
  value,
}: MeasurementInputProps) => {
  const inputWrapperRef = useRef<HTMLInputElement>(null!);

  const isImperialUnits = units === UnitSystemTypes.Imperial;

  useClickAway(inputWrapperRef, (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onEscape();
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      e.stopPropagation();
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onEscape();
    }
  };

  useEffect(() => {
    inputWrapperRef.current?.addEventListener('keydown', handleKeyDown);
    return () => {
      inputWrapperRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputWrapperRef.current]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
      removeSpacesFromThousands(event.target.value, isImperialUnits),
      isImperialUnits,
      2
    );
    onMeasurementChange &&
      onMeasurementChange(
        addSpacesToThousands(sanitizedValueWithoutSpaces, isImperialUnits)
      );
  };

  return (
    <>
      <div ref={inputWrapperRef}>
        <IntusInput
          autoFocus
          value={value}
          onFocus={(event) => event.target.select()}
          autoComplete="off"
          size="small"
          onChange={handleChange}
        ></IntusInput>
      </div>
    </>
  );
};

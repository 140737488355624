import React from 'react';
import { ReportGenerationIcon } from '@/shared/icons/ReportGenerationIcon';
import IntusProgressBar from '@/shared/elements/ProgressBar/ProgressBar';
import { useParams } from 'react-router';

interface ProcessContentProps {
  percent: number;
}

const ProcessContent: React.FC<ProcessContentProps> = ({ percent }) => {
  const { id } = useParams();

  if (!id) return <></>;

  const estimatedTime = 2;

  return (
    <>
      <ReportGenerationIcon className="mb-10" />
      <div className="flex flex-col gap items-center mb-[86px]">
        <p className="m-0 mb-1 text-xs leading-5 text-dark-gray-100">
          {percent}%
        </p>
        <IntusProgressBar
          strokeColor={'#65BD51'}
          percent={percent}
          showInfo={false}
          className="h-[6px]"
        />
        <p className="m-0 mt-2 text-dark-gray-80 text-xs leading-5">
          Estimated waiting time {estimatedTime} minutes
        </p>
      </div>
    </>
  );
};

export default ProcessContent;

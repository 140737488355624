import React from 'react';

export const ReportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.49976 9.00299C4.22361 9.00299 3.99976 9.22685 3.99976 9.50299V11.503C3.99976 11.7791 4.22361 12.003 4.49976 12.003C4.7759 12.003 4.99976 11.7791 4.99976 11.503V11.3366H5.33296C5.97736 11.3366 6.49976 10.8142 6.49976 10.1698C6.49976 9.52538 5.97736 9.00299 5.33296 9.00299H4.49976ZM5.33296 10.3366H4.99976V10.003H5.33296C5.42508 10.003 5.49976 10.0777 5.49976 10.1698C5.49976 10.2619 5.42508 10.3366 5.33296 10.3366ZM10.0005 9.50236C10.0008 9.22646 10.2246 9.00299 10.5005 9.00299H11.4988C11.7749 9.00299 11.9988 9.22685 11.9988 9.50299C11.9988 9.77913 11.7749 10.003 11.4988 10.003H10.9998L10.9994 10.3375H11.4988C11.7749 10.3375 11.9988 10.5613 11.9988 10.8375C11.9988 11.1136 11.7749 11.3375 11.4988 11.3375H11L11.0005 11.5017C11.0012 11.7779 10.7779 12.0023 10.5017 12.003C10.2256 12.0037 10.0012 11.7804 10.0005 11.5043L9.99878 10.8368L10.0005 9.50236ZM7.49829 9.00299C7.22215 9.00299 6.99829 9.22685 6.99829 9.50299V11.503C6.99829 11.7791 7.22215 12.003 7.49829 12.003H7.99974C8.82816 12.003 9.49973 11.3314 9.49974 10.503C9.49974 9.67457 8.82817 9.00299 7.99974 9.00299H7.49829ZM7.99829 11.003V10.003H7.99974C8.27588 10.003 8.49974 10.2269 8.49974 10.503C8.49974 10.7791 8.27588 11.003 7.99974 11.003H7.99829ZM3 3.50195V7.08413C2.4174 7.29005 2 7.84567 2 8.49878V12.502C2 13.3304 2.67157 14.002 3.5 14.002H12.5C13.3284 14.002 14 13.3304 14 12.502V8.49878C14 7.84567 13.5826 7.29005 13 7.08413V5.4133C13 5.0149 12.8415 4.63287 12.5595 4.35147L10.6439 2.44012C10.3627 2.15953 9.98167 2.00195 9.58442 2.00195H4.5C3.67157 2.00195 3 2.67353 3 3.50195ZM4.5 3.00195H9V4.49994C9 5.32837 9.67157 5.99994 10.5 5.99994H12V6.99878H4V3.50195C4 3.22581 4.22386 3.00195 4.5 3.00195ZM10 3.21029L11.7936 4.99994H10.5C10.2239 4.99994 10 4.77608 10 4.49994V3.21029ZM3.5 7.99878H12.5C12.7761 7.99878 13 8.22264 13 8.49878V12.502C13 12.7781 12.7761 13.002 12.5 13.002H3.5C3.22386 13.002 3 12.7781 3 12.502V8.49878C3 8.22264 3.22386 7.99878 3.5 7.99878Z"
        fill="white"
      />
    </svg>
  );
};

import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import './ContextMenu.scss';
import { useOutsideMouseDown } from '@/shared/hooks';
import { IntusIconButton } from '@/shared/elements';
import { MoreIcon } from '@/shared/icons';
import IntusDivider from '../IntusDivider/Divider';

export enum ContextActionTypes {
  Default = 'default',
  Danger = 'danger',
}

export interface ContextAction {
  label: string;
  action: (params?: unknown) => void;
  type?: ContextActionTypes;
  divider?: boolean;
  hasDropdown?: boolean;
}

export const ContextMenu = ({
  actions,
  children,
  onClick,
  onContextAction,
  contextStatusCallback,
  className,
  hasDropdown,
  openMenuByLeftClick,
}: {
  hasDropdown?: boolean;
  actions: ContextAction[];
  children: JSX.Element;
  onClick?: MouseEventHandler;
  onContextAction?: (event: React.MouseEvent) => void;
  contextStatusCallback?: (status: boolean, byClick: boolean) => void;
  className?: string;
  openMenuByLeftClick?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const actionsRef = useRef<HTMLDivElement>(null!);
  const actionButtonRef = useRef<HTMLDivElement>(null!);
  const [triggeredByLeftClick, setTriggeredByLeftClick] = useState(false);
  const [context, setContext] = useState(false);
  const [xYPosition, setXyPosition] = useState({ x: 0, y: 0 });

  const actionsWidth = 200;
  useOutsideMouseDown(ref, () => setContext(false));

  const openContextMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    byClick: boolean = false
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setContext(false);
    if (onContextAction) onContextAction(e);
    if (!actions.length) return;
    // action height is 30px, 8px is the divider height
    const totalHeight = actions.reduce((sum, item) => {
      return sum + (item.divider ? 38 : 30);
    }, 0);
    const yPosition =
      e.pageY + totalHeight + 5 > window.innerHeight
        ? window.innerHeight - totalHeight - 5
        : e.pageY + 5;

    setTriggeredByLeftClick(false);
    const positionChange = {
      x: byClick
        ? actionButtonRef.current.getBoundingClientRect().left -
          actionsWidth +
          actionButtonRef.current.clientWidth
        : e.pageX + 5,
      y: byClick
        ? actionButtonRef.current.getBoundingClientRect().top +
          actionButtonRef.current.clientHeight +
          8
        : yPosition,
    };
    setXyPosition(positionChange);
    setTriggeredByLeftClick(byClick);
    setContext(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => setContext(false), true);
    return () => {
      window.removeEventListener('scroll', () => setContext(false), true);
    };
  }, []);

  const handleContextAction = (contextAction: ContextAction) => {
    contextAction.action();
    setContext(false);
  };

  useEffect(() => {
    contextStatusCallback &&
      contextStatusCallback(context, triggeredByLeftClick);
  }, [context, triggeredByLeftClick]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (openMenuByLeftClick) {
      context ? setContext(false) : openContextMenu(e);
    } else {
      onClick && onClick(e);
    }
  };

  return (
    <>
      {hasDropdown && (
        <div
          ref={actionButtonRef}
          className={`group-hover:opacity-100 hover:opacity-100 absolute top-2 right-2 z-40 ${context ? 'outline outline-4 outline-light-green-15 rounded-lg' : 'opacity-0'}`}
          onClick={(e) => openContextMenu(e, true)}
        >
          <IntusIconButton icon={<MoreIcon />} id="context-menu__more-button" />
        </div>
      )}
      <div
        ref={ref}
        onContextMenu={(e) => !openMenuByLeftClick && openContextMenu(e)}
        onClick={handleClick}
        className={className}
      >
        {children}

        <div
          ref={actionsRef}
          className={
            `z-40 bg-white context-menu__wrapper last:border-none font-normal text-xs w-[${actionsWidth}px]` +
            `${context ? ' fixed' : ' hidden'}`
          }
          style={{
            top: xYPosition.y,
            left: xYPosition.x,
          }}
        >
          {actions.map((action) => (
            <ActionItem
              key={action.label}
              label={action.label}
              type={action.type}
              divider={action.divider}
              action={() => handleContextAction(action)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ActionItem = ({
  label,
  action,
  type = ContextActionTypes.Default,
  divider,
}: ContextAction) => {
  const handleAction = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    action();
  };
  return (
    <>
      <div
        onContextMenu={(e) => e.stopPropagation()}
        className={`h-[30px] flex items-center pl-3 cursor-pointer hover:bg-neutral-100  
                
                  ${type === ContextActionTypes.Danger ? 'text-red' : ''}`}
        onClick={(e) => handleAction(e)}
      >
        {label}
      </div>
      {divider && (
        <div className="w-full h-2 flex items-center">
          <IntusDivider />
        </div>
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { UserBuildingCoordinatesObject } from '@/models';
import Building from '@/routes/dashboard/projects/project/UserBuilding/components/Building';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { setBuildingFacadeData } from '@/store/slices/canvasBuildingSlice';
import { useAppDispatch } from '@/store/hooks';

interface EditUserBuildingProps {
  userBuildings?: UserBuildingCoordinatesObject[];
}

const EditUserBuilding: React.FC<EditUserBuildingProps> = ({
  userBuildings,
}) => {
  const dispatch = useAppDispatch();
  const { getBuildingFacadesData } = useFacadeData();

  useEffect(() => {
    const buildingFacadesData = getBuildingFacadesData();
    buildingFacadesData && dispatch(setBuildingFacadeData(buildingFacadesData));
  }, [userBuildings]);

  if (!userBuildings || !userBuildings.length) return null;

  return (
    <>
      {userBuildings.map((building) => (
        <group key={building.guid}>
          <Building building={building} />
        </group>
      ))}
    </>
  );
};

export default EditUserBuilding;

import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import SphereRotateIcon from '@/components/Toolbar/icons/SphereRotateIcon';
import { CanvasActionsModes } from '@/models';
import { useMouseMode } from '@/shared/hooks/useMouseMode';

const RotateMode = () => {
  const { onClick, isActive } = useMouseMode(CanvasActionsModes.rotate);

  return (
    <ToolbarButton
      id="toolbar-button-rotate-mode"
      icon={<SphereRotateIcon />}
      tooltipText={'Rotate'}
      tooltipSubText={'Shift + RMB'}
      onClick={onClick}
      isActive={isActive}
    />
  );
};

export default RotateMode;

import { CustomError, ProjectStructure } from '@/models';
import {
  ContextMenu,
  IntusButton,
  IntusIconButton,
  IntusLoader,
  IntusTooltip,
} from '@/shared/elements';
import defaultProjectImg from '@/images/default-project.png';
import { LocationIcon } from '@/shared/icons';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ContextAction,
  ContextActionTypes,
} from '@/shared/elements/ContextMenu/ContextMenu';
import EditableField from '@/shared/elements/EditableField';
import { Form } from 'antd';
import {
  maxLengthValidator,
  requiredValidator,
} from '@/shared/form/validators';
import {
  useDeleteProjectMutation,
  useDuplicateProjectMutation,
  useUpdateProjectMutation,
} from '@/store/apis/projectsApi';
import { setFormErrorFromServer } from '@/shared/form/error-handlers';
import ProjectCardSettings from './ProjectCardSettings';
import { LockClosedIcon } from '@/shared/icons/LockClosedIcon';

const ProjectCard = ({ data }: { data: ProjectStructure }) => {
  const navigate = useNavigate();
  const [deleteProject, { isLoading: isProjectDeleting }] =
    useDeleteProjectMutation();
  const [updateProject, { isLoading: isProjectUpdating }] =
    useUpdateProjectMutation();
  const [duplicateProject, { isLoading: isProjectDuplicating }] =
    useDuplicateProjectMutation();
  const [form] = Form.useForm<{ name: string }>();
  const [isEditingName, setIsEditingName] = useState(false);
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const [contextHighlighted, setIsContextHighlighted] = useState(false);

  const handleProjectNavigate = () =>
    navigate(`${data.id}`, { relative: 'path' });

  const handleRename = () => setIsEditingName(true);

  const handleOpenSettings = () => {
    setIsSettingsOpened(true);
  };

  const handleProjectNavigateToNewTab = () =>
    window.open(`${window.location.origin}/dashboard/projects/${data.id}`);

  const handleContextStatus = (status: boolean, byLeftClick: boolean) =>
    setIsContextHighlighted(status && !byLeftClick);

  const projectActions = useMemo(() => {
    const actions: ContextAction[] = [
      { label: 'Open', action: handleProjectNavigate },
      {
        label: 'Open in new tab',
        action: handleProjectNavigateToNewTab,
        divider: true,
      },
      {
        label: 'Settings',
        action: handleOpenSettings,
      },
    ];

    if (!data.locked) {
      actions.push({ label: 'Rename', action: handleRename });
    }

    actions.push(
      {
        label: 'Duplicate',
        action: () =>
          duplicateProject({
            projectId: data.id,
          }),
        divider: true,
      },
      {
        label: 'Delete',
        action: () => deleteProject(data.id),
        type: ContextActionTypes.Danger,
      }
    );

    return actions;
  }, [data.locked]);

  const handleFinishEditingName = async () => {
    if (form.getFieldValue('name') === data.name) {
      setIsEditingName(false);
      return;
    }
    try {
      setIsEditingName(false);
      await form.validateFields();
      await updateProject({
        id: data.id,
        name: form.getFieldValue('name'),
      }).unwrap();
    } catch (err) {
      if (Object.hasOwn(err as never, 'data')) {
        setFormErrorFromServer(form, err as CustomError);
        setIsEditingName(true);
      } else {
        await handleEscape();
      }
    }
  };

  const handleEscape = async () => {
    try {
      form.setFieldValue('name', data.name);
      await form.validateFields();
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => setIsEditingName(false), 200);
    }
  };

  return (
    <IntusLoader
      loading={isProjectDeleting || isProjectUpdating || isProjectDuplicating}
    >
      <div className="aspect-[8/5] min-w-[320px] max-w-[550px] flex-1 relative group">
        <ContextMenu
          onClick={handleProjectNavigate}
          actions={projectActions}
          contextStatusCallback={handleContextStatus}
          className={'h-full'}
          hasDropdown
        >
          <IntusButton
            id="project-card__button"
            className={`h-[220px] w-full flex justify-center items-center dashboard-projects-view__project-card
                      border border-solid ${contextHighlighted ? 'border-light-green-100' : 'border-light-gray-20'}`}
          >
            <img
              src={data.image ?? defaultProjectImg}
              alt="Default Project image"
              className={data.image ? 'w-full h-full' : ''}
            />
          </IntusButton>
        </ContextMenu>
        <div className="flex flex-col text-left pt-2 h-[42px]">
          <div className="flex items-start">
            {data.locked && !isProjectUpdating && (
              <IntusTooltip
                title="Locked for editing"
                className="-ml-[3px]"
                placement={'bottom'}
              >
                <IntusIconButton
                  type="text"
                  icon={<LockClosedIcon />}
                  id="card__locker"
                  transparent
                  className="m-0 p-0 h-fit"
                />
              </IntusTooltip>
            )}
            <Form
              form={form}
              name={`project-${data.id}`}
              initialValues={{ name: data.name }}
              onFinish={handleFinishEditingName}
            >
              <Form.Item
                name="name"
                rules={[
                  requiredValidator('Project Name is required'),
                  maxLengthValidator('Max 50 characters', 50),
                ]}
              >
                <EditableField
                  isEditing={isEditingName}
                  required
                  size="small"
                  textSize="sm"
                  disabled={data.locked}
                  onEscape={handleEscape}
                  onClickOutside={handleFinishEditingName}
                  setEditMode={() => setIsEditingName(true)}
                  textClassNames={'font-medium text-sm truncate'}
                />
              </Form.Item>
            </Form>
          </div>
          {!!data.address.length && !isEditingName && !isProjectUpdating && (
            <div className="flex mt-[-24px]">
              <LocationIcon style={{ color: '#999999' }} className="mr-1" />
              <span className="text-xs text-black-40 truncate">
                {data.address}
              </span>
            </div>
          )}
        </div>
      </div>

      {isSettingsOpened && (
        <ProjectCardSettings
          data={data}
          isOpen={isSettingsOpened}
          setIsOpen={setIsSettingsOpened}
        />
      )}
    </IntusLoader>
  );
};

export default ProjectCard;

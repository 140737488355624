import React from 'react';

export const QuoteProcessingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="110"
      viewBox="0 0 190 110"
      fill="none"
    >
      <circle cx="75.5038" cy="44.7401" r="3.90414" fill="#E8F5E5" />
      <circle cx="106.026" cy="18.0275" r="7.02746" fill="#E8F5E5" />
      <circle cx="141.026" cy="56.0275" r="7.02746" fill="#E8F5E5" />
      <ellipse
        cx="162.684"
        cy="36.685"
        rx="4.68497"
        ry="4.68497"
        fill="#E8F5E5"
      />
      <circle cx="149.828" cy="8.38894" r="3.12331" fill="#E8F5E5" />
      <circle cx="21.9944" cy="25.5139" r="3.12331" fill="#E8F5E5" />
      <circle cx="179.122" cy="62.1233" r="3.12331" fill="#E8F5E5" />
      <path
        d="M48.5416 25L155.795 77.0024C149.191 99.2153 121.777 107.474 83.795 110.002L0.974609 52.6811L48.5416 25Z"
        fill="#F9FAFB"
      />
      <path
        d="M53.1279 80.131L95.8457 55.4677V52.1719L53.1279 76.8352V80.131Z"
        fill="#D0D5D7"
      />
      <path
        d="M53.1279 80.1196V76.8238L1.56628 47.0547V50.3505L53.1279 80.1196Z"
        fill="#F6F6F6"
      />
      <path
        d="M53.1279 76.8308L95.8457 52.1676L44.2841 22.3984L1.56622 47.0617L53.1279 76.8308Z"
        fill="#EAEDED"
      />
      <path
        d="M16.3128 51.8515L52.5781 30.9139L43.6615 25.7656L7.39611 46.7036L16.3128 51.8515Z"
        fill="white"
      />
      <path
        d="M43.6615 27.2162L8.65248 47.4289L7.39611 46.7036L43.6615 25.7656L52.5781 30.9139L51.3218 31.6392L43.6615 27.2162Z"
        fill="#D0D5D7"
      />
      <path
        d="M43.6611 27.2162L8.65214 47.4289L7.39577 46.7036L43.6611 25.7656V27.2162Z"
        fill="#F6F6F6"
      />
      <path
        d="M55.2436 39.353L62.0254 35.4375V36.3761L55.2436 40.2915V39.353Z"
        fill="#D0D5D7"
      />
      <path
        d="M45.9454 45.6509V44.7123L52.7275 40.7969V41.7355L45.9454 45.6509Z"
        fill="#D0D5D7"
      />
      <path
        d="M35.7944 51.5103V50.5717L42.5762 46.6562V47.5945L35.7944 51.5103Z"
        fill="#D0D5D7"
      />
      <path
        d="M32.542 52.4297V53.3998L25.7602 57.3152V56.3451L32.542 52.4297Z"
        fill="#D0D5D7"
      />
      <path
        d="M64.4477 44.6733L71.2295 40.7578V41.6964L64.4477 45.6119V44.6733Z"
        fill="#D0D5D7"
      />
      <path
        d="M55.1499 50.0248L61.9316 46.1094V47.048L55.1499 50.9634V50.0248Z"
        fill="#D0D5D7"
      />
      <path
        d="M44.9981 55.892L51.7803 51.9766V52.9151L44.9981 56.8306V55.892Z"
        fill="#D0D5D7"
      />
      <path
        d="M41.7461 58.7145L34.9643 62.63V61.6576L41.7461 57.7422V58.7145Z"
        fill="#D0D5D7"
      />
      <path
        d="M73.6515 49.9627L80.4336 46.0469V46.9855L73.6515 50.9013V49.9627Z"
        fill="#D0D5D7"
      />
      <path
        d="M71.1357 52.3683L64.354 56.2837V55.3451L71.1357 51.4297V52.3683Z"
        fill="#D0D5D7"
      />
      <path
        d="M54.2026 61.1811L60.9844 57.2656V58.2331L54.2026 62.1486V61.1811Z"
        fill="#D0D5D7"
      />
      <path
        d="M89.6377 51.3672V52.3058L82.8559 56.2212V55.2826L89.6377 51.3672Z"
        fill="#D0D5D7"
      />
      <path
        d="M80.3398 56.7266V57.6987L73.5577 61.6141V60.642L80.3398 56.7266Z"
        fill="#D0D5D7"
      />
      <path
        d="M70.1885 62.5859V63.5421L63.4067 67.4576V66.5014L70.1885 62.5859Z"
        fill="#D0D5D7"
      />
      <path
        d="M60.1543 69.3384L53.3722 73.2542V72.4158L60.1543 68.5V69.3384Z"
        fill="#D0D5D7"
      />
      <path
        d="M48.462 36.3761V35.4375L55.2441 39.353V40.2915L48.462 36.3761Z"
        fill="#F6F6F6"
      />
      <path
        d="M45.9463 45.6509L39.1645 41.7355V40.7969L45.9463 44.7123V45.6509Z"
        fill="#F6F6F6"
      />
      <path
        d="M35.7949 51.5103L29.0128 47.5945V46.6562L35.7949 50.5717V51.5103Z"
        fill="#F6F6F6"
      />
      <path
        d="M18.9786 52.4297L25.7607 56.3451V57.3172L18.9786 53.4018V52.4297Z"
        fill="#F6F6F6"
      />
      <path
        d="M57.6661 41.6964V40.7578L64.4482 44.6733V45.6119L57.6661 41.6964Z"
        fill="#F6F6F6"
      />
      <path
        d="M48.3686 47.048V46.1094L55.1504 50.0252V50.9638L48.3686 47.048Z"
        fill="#F6F6F6"
      />
      <path
        d="M38.2172 52.9151V51.9766L44.999 55.892V56.8306L38.2172 52.9151Z"
        fill="#F6F6F6"
      />
      <path
        d="M34.9648 61.6576V62.6272L28.1831 58.7118V57.7422L34.9648 61.6576Z"
        fill="#F6F6F6"
      />
      <path
        d="M66.8706 46.9855V46.0469L73.6523 49.9627V50.9013L66.8706 46.9855Z"
        fill="#F6F6F6"
      />
      <path
        d="M64.3545 55.3451V56.2837L57.5724 52.3683V51.4297L64.3545 55.3451Z"
        fill="#F6F6F6"
      />
      <path
        d="M47.421 58.2332V57.2812L54.2031 61.1686V62.1487L47.421 58.2332Z"
        fill="#F6F6F6"
      />
      <path
        d="M76.0743 51.3672L82.8564 55.2826V56.2212L76.0743 52.3058V51.3672Z"
        fill="#F6F6F6"
      />
      <path
        d="M66.7768 56.7266L73.5586 60.642V61.6172L66.7768 57.7017V56.7266Z"
        fill="#F6F6F6"
      />
      <path
        d="M56.6251 62.5859L63.4072 66.5014V67.4601L56.6251 63.5446V62.5859Z"
        fill="#F6F6F6"
      />
      <path
        d="M53.373 72.2686V73.2406L37.387 64.0111V63.0391L53.373 72.2686Z"
        fill="#F6F6F6"
      />
      <path
        d="M48.4615 35.4233L55.2436 31.5078L62.0254 35.4233L55.2436 39.3387L48.4615 35.4233Z"
        fill="#F3F4F5"
      />
      <path
        d="M45.9454 44.6981L39.1636 40.7826L45.9454 36.8672L52.7275 40.7826L45.9454 44.6981Z"
        fill="#F3F4F5"
      />
      <path
        d="M35.7944 50.5578L29.0123 46.6424L35.794 42.7266L42.5762 46.6424L35.7944 50.5578Z"
        fill="#F3F4F5"
      />
      <path
        d="M25.7599 48.5312L32.542 52.4467L25.7602 56.3622L18.9781 52.4467L25.7599 48.5312Z"
        fill="#F3F4F5"
      />
      <path
        d="M57.6656 40.7439L64.4477 36.8281L71.2295 40.7439L64.4477 44.6594L57.6656 40.7439Z"
        fill="#F3F4F5"
      />
      <path
        d="M48.3681 46.0951L55.1499 42.1797L61.9316 46.0955L55.1499 50.0109L48.3681 46.0951Z"
        fill="#F3F4F5"
      />
      <path
        d="M38.2164 51.9623L44.9981 48.0469L51.7803 51.9623L44.9981 55.8778L38.2164 51.9623Z"
        fill="#F3F4F5"
      />
      <path
        d="M41.7461 57.7592L34.9643 61.6747L28.1825 57.7592L34.9643 53.8438L41.7461 57.7592Z"
        fill="#F3F4F5"
      />
      <path
        d="M66.8697 46.0326L73.6515 42.1172L80.4336 46.0326L73.6515 49.9484L66.8697 46.0326Z"
        fill="#F3F4F5"
      />
      <path
        d="M71.1357 51.4155L64.354 55.3309L57.5718 51.4155L64.354 47.5L71.1357 51.4155Z"
        fill="#F3F4F5"
      />
      <path
        d="M47.4205 57.2826L54.2026 53.3672L60.9844 57.2826L54.2026 61.1981L47.4205 57.2826Z"
        fill="#F3F4F5"
      />
      <path
        d="M82.8559 47.4375L89.6377 51.353L82.8559 55.2684L76.0738 51.353L82.8559 47.4375Z"
        fill="#F3F4F5"
      />
      <path
        d="M73.5577 52.8281L80.3398 56.7436L73.5577 60.659L66.7759 56.7436L73.5577 52.8281Z"
        fill="#F3F4F5"
      />
      <path
        d="M63.4064 58.6719L70.1885 62.5873L63.4067 66.5028L56.6246 62.5873L63.4064 58.6719Z"
        fill="#F3F4F5"
      />
      <path
        d="M60.1543 68.3702L53.3722 72.286L37.3862 63.0564L44.1683 59.1406L60.1543 68.3702Z"
        fill="#F3F4F5"
      />
      <path
        d="M163.45 60.1863L164.632 60.5326L163.915 62.9518C163.83 63.2385 163.707 63.5143 163.544 63.776C163.487 63.8659 163.425 63.954 163.359 64.0406C163.053 64.4417 162.648 64.8033 162.143 65.115C161.974 65.2199 161.793 65.3191 161.601 65.4117C161.41 65.5059 161.207 65.5936 160.991 65.6741C160.724 65.7759 160.439 65.867 160.136 65.9472C159.82 66.031 159.494 66.101 159.159 66.1571C158.907 66.1992 158.65 66.2339 158.388 66.2608C154.545 66.6586 149.776 65.4298 146.353 63.1646C146.119 63.0102 145.892 62.8516 145.674 62.6877C145.358 62.4544 145.057 62.2106 144.774 61.9583C144.542 61.7516 144.325 61.5436 144.125 61.3346C143.951 61.1536 143.79 60.9726 143.641 60.7912C143.493 60.6106 143.356 60.43 143.232 60.2494L143.231 60.2487C142.858 59.7108 142.592 59.1765 142.43 58.6554C142.396 58.543 142.365 58.431 142.339 58.3199C142.228 57.8351 142.21 57.3644 142.283 56.9182C142.306 56.778 142.339 56.6403 142.379 56.5049L143.056 54.2172L144.139 54.5341C144.253 54.4653 144.372 54.3989 144.496 54.3351C144.96 54.0962 145.492 53.894 146.091 53.7349C146.44 53.6428 146.8 53.567 147.171 53.5089C147.648 53.4336 148.141 53.3846 148.647 53.3627C152.059 53.2115 156.043 54.2377 159.145 56.0635C159.606 56.3343 160.047 56.6239 160.463 56.9284C160.811 57.183 161.142 57.4488 161.453 57.7248C161.932 58.151 162.343 58.5831 162.685 59.0149C162.997 59.4072 163.251 59.7989 163.45 60.1863Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.05"
        d="M163.916 62.9267C163.832 63.2135 163.709 63.4892 163.545 63.7509C163.489 63.8409 163.427 63.929 163.361 64.0155C163.054 64.4166 162.65 64.7783 162.144 65.09C162.082 65.1288 162.016 65.1659 161.951 65.203L163.445 60.1505C163.447 60.1541 163.45 60.1577 163.451 60.1608L164.634 60.5071L163.916 62.9267Z"
        fill="#102222"
      />
      <path
        opacity="0.15"
        d="M143.056 54.2173L144.138 54.5342C144.253 54.4654 144.371 54.399 144.495 54.3352C144.959 54.0963 145.491 53.8941 146.091 53.735C146.439 53.6429 146.799 53.5671 147.17 53.509C147.647 53.4337 148.14 53.3848 148.646 53.3629C149.123 53.3415 149.612 53.3461 150.108 53.3698L147.076 63.6148C146.829 63.4694 146.587 63.3195 146.353 63.1642C146.119 63.0097 145.892 62.8512 145.674 62.6873C145.358 62.454 145.057 62.2101 144.774 61.9578C144.542 61.7511 144.325 61.5431 144.125 61.3341C143.951 61.1532 143.79 60.9721 143.641 60.7908C143.493 60.6101 143.356 60.4296 143.231 60.249L143.231 60.2482C142.858 59.7104 142.592 59.176 142.43 58.655C142.395 58.5426 142.365 58.4305 142.339 58.3195C142.228 57.8346 142.21 57.364 142.283 56.9178C142.306 56.7776 142.339 56.6399 142.379 56.5045L143.056 54.2173Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M142.429 58.6492C142.395 58.5368 142.364 58.4248 142.338 58.3137C142.228 57.8289 142.209 57.3582 142.283 56.912C142.305 56.7718 142.338 56.6341 142.378 56.4987L143.056 54.211L144.138 54.5279C144.144 54.5245 144.15 54.5211 144.156 54.5177L144.481 54.7582L142.975 59.8466C142.733 59.4428 142.552 59.0422 142.429 58.6492Z"
        fill="white"
      />
      <path
        d="M162.216 55.1184C161.905 54.8419 161.574 54.5761 161.227 54.322C160.81 54.0174 160.37 53.7279 159.908 53.4571C156.807 51.6309 152.822 50.6052 149.41 50.7563C148.904 50.7782 148.411 50.8268 147.934 50.9025C147.563 50.961 147.203 51.0363 146.855 51.1285C146.256 51.2876 145.723 51.4898 145.259 51.7287C144.724 52.0038 144.28 52.327 143.93 52.69C143.409 53.226 143.092 53.8484 142.983 54.5242C142.91 54.9709 142.928 55.4411 143.039 55.9264C143.064 56.0375 143.095 56.1496 143.13 56.2619C143.292 56.7829 143.558 57.3173 143.931 57.8551L143.931 57.8559C144.056 58.0364 144.192 58.217 144.341 58.3972C144.49 58.5782 144.651 58.7596 144.825 58.9406C145.025 59.1496 145.242 59.3576 145.474 59.5643C145.757 59.8166 146.058 60.0601 146.373 60.2938C146.592 60.4576 146.818 60.6162 147.053 60.7707C150.476 63.0358 155.246 64.2646 159.088 63.8672C159.35 63.8408 159.607 63.8057 159.859 63.7635C160.194 63.7075 160.52 63.6374 160.836 63.5537C161.138 63.4735 161.423 63.3825 161.691 63.2806C161.906 63.2002 162.109 63.1124 162.3 63.0182C162.492 62.9257 162.673 62.8265 162.842 62.7216C163.347 62.4099 163.752 62.0478 164.058 61.6472C164.124 61.5607 164.186 61.4725 164.243 61.3825C164.489 60.989 164.647 60.5645 164.714 60.1162C164.815 59.4385 164.709 58.7078 164.388 57.9547C164.172 57.446 163.861 56.928 163.448 56.4099C163.107 55.9766 162.696 55.5446 162.216 55.1184Z"
        fill="#F6F9F9"
      />
      <path
        opacity="0.15"
        d="M147.782 61.2247C147.533 61.0783 147.29 60.9277 147.054 60.771C146.819 60.6169 146.594 60.4579 146.374 60.2941C146.059 60.0608 145.758 59.8169 145.475 59.5646C145.243 59.3579 145.025 59.15 144.826 58.9409C144.652 58.7599 144.491 58.5789 144.342 58.3975C144.193 58.2169 144.057 58.0363 143.932 57.8562L143.932 57.8554C143.559 57.3176 143.293 56.7832 143.131 56.2622C143.096 56.1498 143.066 56.0377 143.04 55.9267C142.929 55.4418 142.911 54.9712 142.984 54.525C143.093 53.8492 143.41 53.2267 143.931 52.6908C144.281 52.3278 144.725 52.0045 145.26 51.7294C145.724 51.4905 146.256 51.2884 146.856 51.1292C147.165 51.0473 147.485 50.9809 147.813 50.9255L147.782 61.2247Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M143.666 57.434C143.43 57.036 143.251 56.641 143.131 56.2533C143.096 56.1409 143.065 56.0288 143.039 55.9178C142.929 55.4329 142.91 54.9623 142.984 54.5161C143.074 53.958 143.308 53.4369 143.679 52.9704L143.666 57.434Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M150.949 62.714C150.356 62.4979 149.775 62.2546 149.212 61.9876L149.246 50.7716C149.3 50.7688 149.354 50.7646 149.409 50.7626C149.923 50.74 150.451 50.7472 150.986 50.7762L150.949 62.714Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M156.466 63.9086C155.844 63.8661 155.212 63.7888 154.578 63.6809L154.616 51.3354C155.252 51.4937 155.883 51.6831 156.502 51.9008L156.466 63.9086Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M158.092 63.939C157.767 63.9493 157.436 63.9492 157.102 63.9389L157.138 52.1351C157.473 52.2647 157.803 52.4006 158.127 52.5468L158.092 63.939Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M162.669 55.5442C162.787 55.6615 162.899 55.779 163.008 55.8969C163.044 55.936 163.081 55.9755 163.116 56.0149C163.152 56.0554 163.188 56.0963 163.223 56.1369C163.3 56.2267 163.376 56.3165 163.447 56.4062C163.859 56.9243 164.171 57.4428 164.387 57.9511C164.708 58.7041 164.814 59.4348 164.713 60.1125C164.646 60.5612 164.488 60.9853 164.242 61.3788C164.185 61.4688 164.123 61.5569 164.057 61.6435C163.751 62.0445 163.346 62.4062 162.841 62.7179C162.778 62.7568 162.712 62.7944 162.646 62.8315L162.669 55.5442Z"
        fill="#E5E9EA"
      />
      <path
        d="M160.06 55.6649C157.287 53.2164 152.458 51.9035 149.039 52.6174C148.894 52.6476 148.749 52.6816 148.609 52.7193C145.176 53.6447 144.738 56.4637 147.63 59.0163C150.521 61.569 155.648 62.8887 159.081 61.9633C159.22 61.9256 159.356 61.8838 159.486 61.8403C162.544 60.8077 162.834 58.1138 160.06 55.6649Z"
        fill="#E5E9EA"
      />
      <path
        d="M159.784 55.7466C159.694 55.668 159.603 55.5898 159.508 55.5138C156.823 53.3243 152.41 52.1761 149.252 52.8349C149.223 52.8411 149.196 52.8471 149.167 52.8529C149.057 52.8769 148.948 52.9028 148.842 52.9314C148.19 53.1073 147.651 53.3543 147.228 53.657C146.223 54.3755 145.868 55.4052 146.199 56.5299C146.394 57.1893 146.825 57.8823 147.5 58.5643C147.626 58.693 147.761 58.821 147.906 58.9488C148.039 59.0664 148.175 59.1799 148.318 59.2913C148.616 59.5253 148.933 59.7467 149.267 59.9547C151.596 61.408 154.706 62.208 157.291 62.0193C157.661 61.9924 158.021 61.9455 158.365 61.8766C158.53 61.8446 158.69 61.8066 158.847 61.7644C158.981 61.7285 159.111 61.6885 159.234 61.6471C159.269 61.6353 159.304 61.6235 159.338 61.6102C160.165 61.3145 160.769 60.8884 161.145 60.3757C161.788 59.5015 161.77 58.3758 161.057 57.2152C160.757 56.726 160.332 56.231 159.784 55.7466Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M159.784 55.7466C159.694 55.668 159.603 55.5898 159.508 55.5138C156.823 53.3243 152.41 52.1761 149.252 52.8349C149.223 52.8411 149.196 52.8471 149.167 52.8529C149.057 52.8769 148.948 52.9028 148.842 52.9314C148.19 53.1073 147.651 53.3543 147.228 53.657C146.223 54.3755 145.868 55.4052 146.199 56.5299C146.394 57.1893 146.825 57.8823 147.5 58.5643C147.626 58.693 147.761 58.821 147.906 58.9488C148.039 59.0664 148.175 59.1799 148.318 59.2913C148.616 59.5253 148.933 59.7467 149.267 59.9547C151.596 61.408 154.706 62.208 157.291 62.0193C157.661 61.9924 158.021 61.9455 158.365 61.8766C158.53 61.8446 158.69 61.8066 158.847 61.7644C158.981 61.7285 159.111 61.6885 159.234 61.6471C159.269 61.6353 159.304 61.6235 159.338 61.6102C160.165 61.3145 160.769 60.8884 161.145 60.3757C161.788 59.5015 161.77 58.3758 161.057 57.2152C160.757 56.726 160.332 56.231 159.784 55.7466Z"
        fill="#102222"
      />
      <path
        d="M159.244 56.419C159.339 56.4951 159.43 56.5733 159.519 56.6518C160.068 57.1367 160.492 57.6317 160.793 58.1209C161.209 58.7978 161.386 59.4631 161.336 60.0736C161.281 60.1779 161.218 60.2804 161.145 60.3786C160.769 60.8913 160.164 61.3174 159.338 61.6131C159.304 61.6264 159.269 61.6382 159.234 61.65C159.111 61.6914 158.981 61.731 158.847 61.7673C158.69 61.8095 158.529 61.8475 158.365 61.8795C158.02 61.9484 157.661 61.9958 157.291 62.0222C154.706 62.2109 151.596 61.4109 149.266 59.9576C148.933 59.75 148.615 59.5283 148.318 59.2942C148.175 59.1828 148.039 59.0688 147.906 58.9517C147.761 58.8238 147.626 58.6959 147.499 58.5672C146.824 57.8857 146.394 57.1927 146.199 56.5328C146.098 56.1888 146.062 55.8538 146.088 55.5336C146.279 55.1713 146.571 54.8427 146.964 54.5623C147.387 54.2599 147.926 54.0126 148.578 53.8367C148.684 53.808 148.793 53.7816 148.903 53.7581C148.931 53.7519 148.959 53.7459 148.988 53.7402C152.146 53.0813 156.559 54.2299 159.244 56.419Z"
        fill="#E5E9EA"
      />
      <path
        d="M164.056 61.6457C162.031 63.9954 158.299 64.064 155.372 63.7267C150.963 63.0787 146.512 61.2032 143.957 57.8264L143.963 57.8333C143.283 56.8665 142.803 55.7072 142.98 54.5237C142.776 55.7123 143.222 56.9003 143.898 57.8823L143.893 57.877C145.921 60.7001 149.366 62.4422 152.886 63.3809C156.465 64.2195 161.426 64.663 164.056 61.6457Z"
        fill="#F5F5F5"
      />
      <path
        d="M148.816 58.0067C149.862 58.8617 151.096 58.9933 152.303 58.9299L151.928 59.14L151.56 60.3803C151.792 60.5145 152.029 60.626 152.264 60.7202C152.692 60.8887 153.13 60.9953 153.563 61.0535C154.333 61.157 155.089 61.1091 155.76 60.9888L156.681 61.7432L158.363 61.2904L158.73 60.0502L158.497 59.8569L158.447 59.8154C158.494 59.7319 158.533 59.6388 158.562 59.5347C158.572 59.4994 158.577 59.4669 158.582 59.4339C158.609 59.2755 158.849 58.5849 158.929 58.2945C158.939 58.2592 158.944 58.2267 158.95 58.1937C159.011 57.8347 158.814 57.4379 158.3 57.0187C158.3 57.0156 158.3 57.0156 158.3 57.0156C157.751 56.5698 157.131 56.3261 156.495 56.2073C156.441 56.1972 156.382 56.19 156.329 56.1799L156.31 56.1764C156.252 56.1687 156.194 56.1615 156.135 56.1537C156.084 56.1476 156.033 56.1414 155.981 56.1384C155.958 56.1377 155.937 56.1339 155.918 56.1335C155.877 56.1293 155.837 56.1251 155.799 56.1248C155.466 56.1028 155.135 56.1088 154.814 56.1229C154.67 56.1297 154.525 56.1405 154.383 56.1536L154.874 55.8735L155.241 54.6333C154.1 54.0437 152.75 53.8557 151.296 54.0998L150.374 53.3485L148.703 53.8283L148.336 55.0685L148.624 55.3038C148.579 55.3772 148.543 55.4517 148.524 55.5292L148.521 55.5387C148.475 55.7345 148.212 56.5495 148.156 56.7694L148.154 56.7788C148.066 57.1501 148.27 57.5614 148.816 58.0067Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M158.912 58.3586C158.821 58.6768 158.609 59.293 158.583 59.4401C158.576 59.4746 158.57 59.5068 158.563 59.5413C158.535 59.6449 158.494 59.7378 158.446 59.8235L157.785 59.281C157.785 59.281 158.657 59.1386 158.912 58.3586Z"
        fill="#102222"
      />
      <path
        opacity="0.2"
        d="M151.281 56.0908C151.004 55.8651 151.025 55.6036 151.717 55.4019C152.456 55.1922 153.127 55.2795 153.63 55.5771L155.242 54.6603L154.873 55.8986L154.385 56.1795C154.37 56.1799 154.358 56.1825 154.342 56.1829C154.118 56.2002 153.898 56.2224 153.689 56.244C153.603 56.2555 153.52 56.2646 153.439 56.2712C152.847 56.3396 152.402 56.3834 152.062 56.3585C151.845 56.3459 151.669 56.3096 151.513 56.2361C151.429 56.1989 151.351 56.1484 151.281 56.0908Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M148.625 55.336L148.338 55.1013L148.707 53.8629L149.625 54.6138C149.138 54.8212 148.794 55.0635 148.625 55.336Z"
        fill="#102222"
      />
      <path
        opacity="0.1"
        d="M157.051 60.5216L158.732 60.0692L158.365 61.3098L156.681 61.7643L155.763 61.0086C155.09 61.1296 154.333 61.1771 153.564 61.0718C153.131 61.0149 152.692 60.9076 152.264 60.7407C152.03 60.6443 151.793 60.5362 151.562 60.4007L151.929 59.1601C152.16 59.2956 152.4 59.4059 152.631 59.5006C153.062 59.6696 153.498 59.7747 153.931 59.8317C154.7 59.937 155.457 59.8894 156.13 59.7684L157.051 60.5216Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M155.762 61.01C155.184 61.1713 154.572 61.2286 153.972 61.1895C153.373 61.1462 152.777 61.0085 152.263 60.7422C152.83 60.9007 153.403 61.0042 153.989 61.0423C154.281 61.0637 154.575 61.0663 154.871 61.061C155.166 61.0548 155.462 61.0322 155.762 61.01Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M148.155 56.7917L148.158 56.7849C148.214 56.5641 148.476 55.7496 148.522 55.5516C148.435 55.9246 148.636 56.3354 149.182 56.7807C150.396 57.7705 151.859 57.7911 153.241 57.6627C154.525 57.5391 155.504 57.2933 156.093 57.7718C156.46 58.0729 156.257 58.3164 155.712 58.4727C154.839 58.7247 154.04 58.4509 153.638 58.1964L152.304 58.9458L152.299 58.9461C151.091 59.008 149.859 58.874 148.815 58.0213C148.269 57.5755 148.068 57.1647 148.155 56.7917Z"
        fill="#102222"
      />
      <path
        d="M153.263 56.8144L153.631 55.5738L155.241 54.6554L154.874 55.8957L153.263 56.8144Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M153.263 56.8144L153.631 55.5738L155.241 54.6554L154.874 55.8957L153.263 56.8144Z"
        fill="#102222"
      />
      <path
        d="M148.703 53.8475L149.625 54.5988L148.624 55.3231L148.335 55.0878L148.703 53.8475Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.2"
        d="M148.703 53.8475L149.625 54.5988L148.624 55.3231L148.335 55.0878L148.703 53.8475Z"
        fill="#102222"
      />
      <path
        d="M158.497 59.8617L157.785 59.2777C157.785 59.2777 158.7 59.1281 158.929 58.2989C158.939 58.2636 158.944 58.2311 158.949 58.1982C159.011 57.8391 158.814 57.4423 158.299 57.0231C158.3 57.02 158.3 57.02 158.3 57.02C157.751 56.5743 157.13 56.3305 156.494 56.2118C156.441 56.2017 156.382 56.1944 156.328 56.1844L156.31 56.1809C156.252 56.1732 156.193 56.1659 156.135 56.1582C156.084 56.152 156.033 56.1459 155.98 56.1429C155.958 56.1422 155.936 56.1383 155.918 56.138C155.877 56.1338 155.837 56.1295 155.799 56.1292C155.465 56.1073 155.135 56.1132 154.813 56.1273C154.656 56.1351 154.498 56.1461 154.343 56.161C154.118 56.1796 153.897 56.2024 153.687 56.2237C153.601 56.2345 153.519 56.2429 153.437 56.2514C152.844 56.3194 152.402 56.3612 152.061 56.3381C151.845 56.3249 151.666 56.2882 151.513 56.2156C151.429 56.1767 151.352 56.1282 151.281 56.0712C151.003 55.8442 151.023 55.5821 151.717 55.3813C152.456 55.1691 153.127 55.2569 153.63 55.5561L155.24 54.6373C154.1 54.0477 152.75 53.8598 151.296 54.1038L150.374 53.3526L148.703 53.8323L149.625 54.5835C149 54.8476 148.616 55.1698 148.523 55.5332L148.521 55.5426C148.433 55.9135 148.637 56.3252 149.183 56.7705C150.394 57.7608 151.858 57.7812 153.239 57.6517C154.524 57.5275 155.503 57.2826 156.092 57.7627C156.458 58.0635 156.255 58.305 155.709 58.4626C154.838 58.7143 154.041 58.4412 153.636 58.1871L151.927 59.1449C152.159 59.2791 152.396 59.3906 152.631 59.4849C153.059 59.6533 153.497 59.7599 153.93 59.8182C154.7 59.9217 155.456 59.8737 156.127 59.7535L157.049 60.5078L158.73 60.055L158.497 59.8617Z"
        fill="#E5E9EA"
      />
      <path
        d="M158.497 59.8617L157.785 59.2777C157.785 59.2777 158.7 59.1281 158.929 58.2989C158.939 58.2636 158.944 58.2311 158.949 58.1982C159.011 57.8391 158.814 57.4423 158.299 57.0231C158.3 57.02 158.3 57.02 158.3 57.02C157.751 56.5743 157.13 56.3305 156.494 56.2118C156.441 56.2017 156.382 56.1944 156.328 56.1844L156.31 56.1809C156.252 56.1732 156.193 56.1659 156.135 56.1582C156.084 56.152 156.033 56.1459 155.98 56.1429C155.958 56.1422 155.936 56.1383 155.918 56.138C155.877 56.1338 155.837 56.1295 155.799 56.1292C155.465 56.1073 155.135 56.1132 154.813 56.1273C154.656 56.1351 154.498 56.1461 154.343 56.161C154.118 56.1796 153.897 56.2024 153.687 56.2237C153.601 56.2345 153.519 56.2429 153.437 56.2514C152.844 56.3194 152.402 56.3612 152.061 56.3381C151.845 56.3249 151.666 56.2882 151.513 56.2156C151.429 56.1767 151.352 56.1282 151.281 56.0712C151.003 55.8442 151.023 55.5821 151.717 55.3813C152.456 55.1691 153.127 55.2569 153.63 55.5561L155.24 54.6373C154.1 54.0477 152.75 53.8598 151.296 54.1038L150.374 53.3526L148.703 53.8323L149.625 54.5835C149 54.8476 148.616 55.1698 148.523 55.5332L148.521 55.5426C148.433 55.9135 148.637 56.3252 149.183 56.7705C150.394 57.7608 151.858 57.7812 153.239 57.6517C154.524 57.5275 155.503 57.2826 156.092 57.7627C156.458 58.0635 156.255 58.305 155.709 58.4626C154.838 58.7143 154.041 58.4412 153.636 58.1871L151.927 59.1449C152.159 59.2791 152.396 59.3906 152.631 59.4849C153.059 59.6533 153.497 59.7599 153.93 59.8182C154.7 59.9217 155.456 59.8737 156.127 59.7535L157.049 60.5078L158.73 60.055L158.497 59.8617Z"
        fill="#F6F9F9"
      />
      <path
        d="M149.184 56.7891C149.64 57.1387 150.176 57.3991 150.754 57.5345C151.331 57.6761 151.939 57.7048 152.541 57.6776C153.145 57.6595 153.747 57.5743 154.353 57.5131C154.656 57.4852 154.962 57.4626 155.266 57.4826C155.569 57.5063 155.873 57.5934 156.092 57.7813C155.863 57.6035 155.56 57.5325 155.262 57.5219C154.962 57.5102 154.659 57.5393 154.358 57.575C153.757 57.649 153.152 57.7338 152.542 57.7586C151.934 57.7789 151.316 57.7517 150.733 57.5945C150.443 57.5162 150.162 57.4131 149.901 57.2774C149.642 57.138 149.403 56.9726 149.184 56.7891Z"
        fill="white"
      />
      <path
        d="M157.047 60.516L156.68 61.7562L155.759 61.0018L156.126 59.7616L157.047 60.516Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M157.047 60.516L156.68 61.7562L155.759 61.0018L156.126 59.7616L157.047 60.516Z"
        fill="#102222"
      />
      <path
        d="M158.731 60.0594L157.097 60.3747L156.129 59.7578L157.05 60.5122L158.731 60.0594Z"
        fill="white"
      />
      <path
        d="M151.296 54.1058L150.343 53.4853L148.704 53.8347L150.374 53.355L151.296 54.1058Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M155.241 54.6447C155.035 54.5386 154.821 54.4501 154.603 54.3702L154.601 55.0091L155.241 54.6447Z"
        fill="white"
      />
      <path
        d="M95.2263 27.2793L96.5973 27.8222L95.4759 30.6254C95.3435 30.9575 95.1667 31.2727 94.9426 31.5661C94.8652 31.6669 94.7817 31.7652 94.6936 31.861C94.2846 32.3051 93.7621 32.6916 93.126 33.0074C92.9131 33.1138 92.6864 33.2121 92.4481 33.3013C92.2103 33.3924 91.9576 33.4744 91.6928 33.5467C91.3628 33.6384 91.0134 33.7158 90.6437 33.778C90.2576 33.8426 89.8629 33.8905 89.457 33.9201C89.1524 33.9425 88.8423 33.9557 88.527 33.9588C83.9052 34.0097 78.3584 32.0204 74.5295 28.9447C74.2677 28.7354 74.0156 28.521 73.7727 28.302C73.4233 27.9893 73.0912 27.6659 72.7811 27.3338C72.5274 27.0619 72.2916 26.7904 72.0762 26.5195C71.8893 26.2847 71.7172 26.0514 71.5598 25.8192C71.4029 25.588 71.2603 25.3578 71.1315 25.1291L71.1304 25.1281C70.7454 24.4461 70.4872 23.7805 70.3517 23.1418C70.3227 23.0038 70.2983 22.8673 70.2799 22.7318C70.2015 22.1415 70.2315 21.5793 70.368 21.0552C70.4103 20.8907 70.4648 20.7303 70.528 20.5734L71.5863 17.9225L72.8417 18.4191C72.9853 18.3498 73.1335 18.2836 73.2883 18.2215C73.8669 17.9877 74.5239 17.8059 75.2552 17.6821C75.6805 17.6103 76.1175 17.5604 76.5667 17.5314C77.1422 17.4937 77.736 17.4901 78.3411 17.5197C82.4231 17.7152 87.0567 19.3766 90.551 21.8935C91.0705 22.2673 91.564 22.6605 92.0264 23.0695C92.4125 23.4107 92.7781 23.7642 93.1178 24.1273C93.6424 24.688 94.084 25.2483 94.444 25.7998C94.7725 26.301 95.0318 26.796 95.2263 27.2793Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.05"
        d="M95.4761 30.6098C95.3437 30.9419 95.167 31.2571 94.9429 31.5505C94.8655 31.6513 94.782 31.7496 94.6939 31.8454C94.2849 32.2895 93.7624 32.676 93.1263 32.9918C93.0478 33.031 92.9653 33.0682 92.8828 33.1049L95.2205 27.25C95.222 27.2541 95.2246 27.2587 95.2261 27.2632L96.5971 27.8061L95.4761 30.6098Z"
        fill="#102222"
      />
      <path
        opacity="0.15"
        d="M71.5868 17.9225L72.8422 18.4191C72.9858 18.3498 73.134 18.2836 73.2889 18.2215C73.8674 17.9877 74.5244 17.8059 75.2558 17.6821C75.681 17.6103 76.118 17.5604 76.5672 17.5314C77.1427 17.4937 77.7365 17.4901 78.3416 17.5197C78.9125 17.5472 79.4946 17.6063 80.0824 17.6893L75.3413 29.561C75.0632 29.3608 74.7923 29.1551 74.5295 28.9442C74.2677 28.7349 74.0156 28.5205 73.7727 28.3015C73.4233 27.9888 73.0912 27.6654 72.7811 27.3333C72.5274 27.0614 72.2916 26.7899 72.0762 26.519C71.8893 26.2842 71.7172 26.0509 71.5598 25.8187C71.4029 25.5875 71.2603 25.3572 71.1315 25.1286L71.1304 25.1276C70.7454 24.4456 70.4872 23.78 70.3517 23.1413C70.3227 23.0033 70.2983 22.8668 70.2799 22.7313C70.2015 22.141 70.2315 21.5788 70.368 21.0547C70.4103 20.8902 70.4648 20.7298 70.528 20.5729L71.5868 17.9225Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M70.3527 23.1411C70.3237 23.0031 70.2992 22.8666 70.2809 22.7312C70.2025 22.1409 70.2325 21.5786 70.369 21.0546C70.4113 20.8901 70.4658 20.7296 70.5289 20.5728L71.5873 17.9219L72.8427 18.4184C72.8498 18.4149 72.8575 18.4118 72.8646 18.4083L73.2252 18.7306L70.8707 24.6273C70.6272 24.12 70.4546 23.6229 70.3527 23.1411Z"
        fill="white"
      />
      <path
        d="M94.316 21.1192C93.9763 20.7561 93.6106 20.4026 93.2246 20.0614C92.7622 19.6524 92.2686 19.2592 91.7492 18.8859C88.2549 16.369 83.6213 14.7082 79.5393 14.5121C78.9342 14.482 78.3409 14.4861 77.7649 14.5238C77.3157 14.5523 76.8787 14.6027 76.4534 14.6745C75.7221 14.7983 75.0656 14.9806 74.4865 15.2139C73.8188 15.4828 73.2545 15.8189 72.7977 16.2126C72.1178 16.7937 71.6717 17.5006 71.4674 18.2936C71.3309 18.8172 71.3014 19.3799 71.3793 19.9702C71.3976 20.1052 71.4221 20.2422 71.4511 20.3797C71.5866 21.0178 71.8443 21.684 72.2298 22.3659L72.2303 22.367C72.3592 22.5956 72.5018 22.8258 72.6587 23.0571C72.8165 23.2893 72.9882 23.5226 73.1751 23.7573C73.3905 24.0283 73.6263 24.2997 73.88 24.5717C74.1901 24.9038 74.5217 25.2272 74.8716 25.5399C75.1145 25.7594 75.3666 25.9733 75.6284 26.1826C79.4573 29.2583 85.0045 31.2476 89.6259 31.1966C89.9412 31.1936 90.2513 31.1809 90.5559 31.1579C90.9618 31.1284 91.357 31.0805 91.7425 31.0158C92.1123 30.9537 92.4617 30.8763 92.7917 30.7846C93.057 30.7123 93.3091 30.6303 93.547 30.5391C93.7858 30.45 94.012 30.3517 94.2248 30.2453C94.861 29.9295 95.384 29.543 95.7925 29.0989C95.8806 29.0031 95.9641 28.9048 96.0415 28.804C96.3787 28.3619 96.6129 27.874 96.7418 27.3469C96.9374 26.5508 96.891 25.6682 96.5921 24.7357C96.3909 24.1057 96.0762 23.4543 95.6417 22.7912C95.2822 22.2396 94.8406 21.6799 94.316 21.1192Z"
        fill="#F6F9F9"
      />
      <path
        opacity="0.15"
        d="M76.445 26.7977C76.1654 26.596 75.8919 26.3892 75.6276 26.1768C75.3658 25.9675 75.1137 25.7531 74.8708 25.5341C74.5209 25.2214 74.1894 24.898 73.8792 24.5659C73.6256 24.294 73.3898 24.0225 73.1743 23.7516C72.9874 23.5168 72.8158 23.2835 72.6579 23.0513C72.5011 22.8201 72.3584 22.5899 72.2296 22.3612L72.2291 22.3602C71.8441 21.6782 71.5858 21.0126 71.4504 20.3739C71.4213 20.2359 71.3969 20.0994 71.3786 19.9644C71.3001 19.3742 71.3302 18.8119 71.4667 18.2878C71.6709 17.4948 72.117 16.7879 72.797 16.2068C73.2538 15.8126 73.8181 15.4765 74.4858 15.2081C75.0649 14.9743 75.7213 14.7925 76.4527 14.6688C76.8306 14.6051 77.2192 14.5613 77.6159 14.5312L76.445 26.7977Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M71.9591 21.8478C71.7208 21.3472 71.5517 20.8572 71.4513 20.3826C71.4223 20.2445 71.3979 20.108 71.3795 19.9731C71.3011 19.3828 71.3312 18.8205 71.4677 18.2965C71.6367 17.6415 71.9729 17.0467 72.4669 16.5312L71.9591 21.8478Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M80.0582 28.9235C79.3747 28.6006 78.7091 28.2466 78.0684 27.8667L79.3437 14.5078C79.4088 14.5104 79.473 14.5114 79.5387 14.5149C80.1534 14.5445 80.7814 14.6112 81.4155 14.7049L80.0582 28.9235Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M86.4985 30.9415C85.7621 30.8223 85.0175 30.6609 84.2744 30.4622L85.6786 15.7578C86.4191 16.0165 87.1499 16.3119 87.8645 16.6394L86.4985 30.9415Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M88.4329 31.1765C88.0438 31.153 87.6506 31.1164 87.2539 31.067L88.5959 17.0078C88.9794 17.1988 89.3583 17.3974 89.7281 17.6073L88.4329 31.1765Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M94.8091 21.6797C94.9374 21.8325 95.0587 21.9848 95.1753 22.137C95.214 22.188 95.2532 22.2384 95.2904 22.2893C95.3291 22.3418 95.3673 22.3942 95.4045 22.4462C95.4865 22.5618 95.5669 22.6769 95.6418 22.792C96.0762 23.4546 96.3905 24.1065 96.5921 24.7365C96.8911 25.669 96.9374 26.5511 96.7419 27.3477C96.613 27.8748 96.3782 28.3627 96.0416 28.8048C95.9642 28.9056 95.8807 29.0039 95.7926 29.0996C95.3836 29.5437 94.861 29.9303 94.2249 30.2461C94.146 30.2853 94.063 30.323 93.9805 30.3601L94.8091 21.6797Z"
        fill="#E5E9EA"
      />
      <path
        d="M91.6872 21.5283C88.6523 18.3055 83.0439 16.2102 78.8911 16.6839C78.7148 16.7042 78.5396 16.7287 78.3685 16.7582C74.177 17.4829 73.3438 20.7929 76.5081 24.1527C79.6723 27.5125 85.6342 29.649 89.8262 28.9243C89.9968 28.8948 90.1633 28.8601 90.3228 28.8224C94.0798 27.9286 94.7221 24.7511 91.6872 21.5283Z"
        fill="#E5E9EA"
      />
      <path
        d="M91.3487 21.5906C91.2514 21.4872 91.1505 21.3843 91.0461 21.2829C88.0887 18.3789 82.957 16.5251 79.122 16.9621C79.0873 16.9661 79.0532 16.9702 79.0186 16.9743C78.8851 16.9906 78.7532 17.0099 78.6229 17.0324C77.8268 17.1699 77.1571 17.4052 76.6198 17.7189C75.343 18.464 74.8077 19.6522 75.0782 21.0288C75.2376 21.836 75.674 22.709 76.4033 23.5957C76.5398 23.7627 76.6865 23.9303 76.8449 24.0983C76.9906 24.2527 77.1408 24.4034 77.2982 24.5521C77.6272 24.8638 77.9811 25.1628 78.3555 25.447C80.9717 27.4353 84.5877 28.7304 87.6888 28.7905C88.1324 28.7992 88.5659 28.7829 88.984 28.7385C89.1831 28.7182 89.3792 28.6907 89.5707 28.6576C89.7342 28.629 89.8931 28.5965 90.0449 28.5608C90.0882 28.5506 90.1309 28.5404 90.1727 28.5282C91.1898 28.2669 91.9568 27.8259 92.462 27.2565C93.3237 26.2858 93.4261 24.9428 92.7044 23.4816C92.3998 22.8648 91.9491 22.2282 91.3487 21.5906Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M91.3487 21.5906C91.2514 21.4872 91.1505 21.3843 91.0461 21.2829C88.0887 18.3789 82.957 16.5251 79.122 16.9621C79.0873 16.9661 79.0532 16.9702 79.0186 16.9743C78.8851 16.9906 78.7532 17.0099 78.6229 17.0324C77.8268 17.1699 77.1571 17.4052 76.6198 17.7189C75.343 18.464 74.8077 19.6522 75.0782 21.0288C75.2376 21.836 75.674 22.709 76.4033 23.5957C76.5398 23.7627 76.6865 23.9303 76.8449 24.0983C76.9906 24.2527 77.1408 24.4034 77.2982 24.5521C77.6272 24.8638 77.9811 25.1628 78.3555 25.447C80.9717 27.4353 84.5877 28.7304 87.6888 28.7905C88.1324 28.7992 88.5659 28.7829 88.984 28.7385C89.1831 28.7182 89.3792 28.6907 89.5707 28.6576C89.7342 28.629 89.8931 28.5965 90.0449 28.5608C90.0882 28.5506 90.1309 28.5404 90.1727 28.5282C91.1898 28.2669 91.9568 27.8259 92.462 27.2565C93.3237 26.2858 93.4261 24.9428 92.7044 23.4816C92.3998 22.8648 91.9491 22.2282 91.3487 21.5906Z"
        fill="#102222"
      />
      <path
        d="M90.6324 22.3303C90.7374 22.4317 90.8377 22.5345 90.935 22.6379C91.5359 23.2756 91.9862 23.9122 92.2907 24.5279C92.7119 25.3805 92.8499 26.1923 92.7231 26.914C92.6452 27.0327 92.5591 27.1473 92.4624 27.2568C91.9571 27.8261 91.1901 28.2672 90.1731 28.5285C90.1313 28.5402 90.0885 28.5509 90.0452 28.5611C89.8935 28.5967 89.7346 28.6298 89.5711 28.6578C89.3796 28.6909 89.1835 28.7184 88.9844 28.7388C88.5662 28.7831 88.1328 28.7994 87.6892 28.7908C84.5881 28.7307 80.9716 27.4355 78.3558 25.4472C77.9815 25.163 77.6275 24.8646 77.2985 24.5524C77.1412 24.4037 76.9909 24.2529 76.8453 24.0986C76.6869 23.9305 76.5397 23.763 76.4037 23.5959C75.6744 22.7097 75.2379 21.8363 75.0785 21.0291C74.996 20.6079 74.9894 20.205 75.0556 19.8266C75.324 19.4161 75.708 19.0571 76.2061 18.7658C76.7434 18.452 77.4131 18.2167 78.2092 18.0792C78.339 18.0568 78.4715 18.0375 78.6049 18.0212C78.6395 18.0171 78.6736 18.013 78.7083 18.0089C82.5433 17.572 87.6744 19.4263 90.6324 22.3303Z"
        fill="#E5E9EA"
      />
      <path
        d="M95.7934 29.0943C93.1232 31.6708 88.6683 31.3418 85.2184 30.6176C80.0373 29.3602 74.9407 26.6359 72.2685 22.3314L72.2746 22.34C71.5702 21.1136 71.1261 19.6789 71.4673 18.2891C71.093 19.6825 71.4943 21.1472 72.1916 22.392L72.186 22.3848C74.2909 25.9718 78.2038 28.4266 82.293 29.9321C86.4656 31.325 92.3276 32.3996 95.7934 29.0943Z"
        fill="#F5F5F5"
      />
      <path
        d="M78.0352 23.1042C79.1872 24.2379 80.6433 24.5308 82.0877 24.5878L81.6176 24.7971L81.0436 26.2344C81.3044 26.4198 81.5748 26.5787 81.8442 26.7167C82.3362 26.9647 82.846 27.1394 83.3558 27.2565C84.2609 27.4643 85.1679 27.4908 85.9807 27.421L86.9953 28.4213L89.0477 28.0673L89.6217 26.6301L89.3655 26.3744L89.31 26.3194C89.3752 26.2247 89.4328 26.1182 89.4786 25.9975C89.4939 25.9568 89.5041 25.9181 89.5137 25.8799C89.5637 25.694 89.9248 24.8975 90.0526 24.5603C90.0679 24.5191 90.0781 24.4809 90.0877 24.4427C90.2003 24.022 90.0103 23.5275 89.4424 22.9713C89.444 22.9677 89.444 22.9677 89.444 22.9677C88.8389 22.3759 88.1264 22.0174 87.3818 21.806C87.3187 21.7882 87.2499 21.7729 87.1873 21.7551L87.1659 21.749C87.0971 21.7337 87.0284 21.7179 86.9596 21.7026C86.8995 21.6899 86.8389 21.6767 86.7773 21.6675C86.7508 21.6639 86.7253 21.6568 86.7029 21.6548C86.6551 21.6451 86.6077 21.6359 86.5629 21.6313C86.1677 21.5682 85.773 21.5392 85.3884 21.5203C85.2163 21.5132 85.0431 21.5096 84.8715 21.5096L85.4872 21.23L86.0612 19.7928C84.7671 18.9647 83.1796 18.5924 81.42 18.7227L80.404 17.7266L78.3607 18.1141L77.7867 19.5514L78.1045 19.8636C78.0423 19.9456 77.9914 20.0311 77.9598 20.1208L77.9552 20.132C77.8788 20.3601 77.4765 21.3023 77.3853 21.558L77.3813 21.5692C77.2366 22.0011 77.4337 22.514 78.0352 23.1042Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M90.0259 24.625C89.8813 24.9937 89.562 25.7052 89.5151 25.8774C89.5024 25.9176 89.4927 25.9553 89.4795 25.9955C89.4346 26.1157 89.3761 26.2216 89.3093 26.3189L88.5811 25.5993C88.5811 25.5993 89.6368 25.5265 90.0259 24.625Z"
        fill="#102222"
      />
      <path
        opacity="0.2"
        d="M81.1854 21.0494C80.8804 20.7499 80.9344 20.4408 81.7803 20.2768C82.6843 20.1082 83.4737 20.2865 84.0405 20.6965L86.0614 19.7812L85.4849 21.2159L84.8733 21.4966C84.8549 21.4955 84.8401 21.4971 84.8218 21.4961C84.5524 21.492 84.2886 21.494 84.0365 21.4966C83.9331 21.5006 83.8328 21.5022 83.736 21.5017C83.0225 21.518 82.4887 21.5215 82.0859 21.4538C81.8287 21.4151 81.6229 21.3524 81.4447 21.2475C81.3504 21.1945 81.2628 21.1258 81.1854 21.0494Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M78.1029 19.8631L77.7871 19.5519L78.3636 18.1172L79.3746 19.1129C78.7716 19.3064 78.3356 19.5575 78.1029 19.8631Z"
        fill="#102222"
      />
      <path
        opacity="0.1"
        d="M87.5701 26.9906L89.6225 26.6371L89.0491 28.0744L86.9936 28.4304L85.9831 27.4291C85.1672 27.4989 84.2607 27.4724 83.3562 27.2626C82.8458 27.1469 82.3355 26.9707 81.8435 26.7247C81.5751 26.5837 81.3052 26.4288 81.0439 26.2419L81.6174 24.8047C81.8787 24.9916 82.1512 25.1495 82.417 25.2875C82.9115 25.5365 83.4193 25.7097 83.9296 25.8253C84.8341 26.0351 85.7407 26.0616 86.5566 25.9919L87.5701 26.9906Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M85.9824 27.4309C85.276 27.5598 84.541 27.5603 83.8295 27.4477C83.1211 27.3301 82.4264 27.1004 81.8428 26.7266C82.5013 26.9776 83.1726 27.164 83.8662 27.2741C84.212 27.3321 84.5619 27.3667 84.9149 27.3937C85.2683 27.4187 85.6233 27.4243 85.9824 27.4309Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M77.3824 21.5627L77.3865 21.5546C77.4776 21.2974 77.88 20.3557 77.9564 20.125C77.8117 20.5599 78.0053 21.0713 78.6068 21.6621C79.9452 22.975 81.6855 23.1604 83.3463 23.1594C84.8894 23.1538 86.0832 22.9684 86.7316 23.603C87.1359 24.0023 86.8681 24.2697 86.2014 24.396C85.1334 24.6002 84.2121 24.1861 83.7603 23.8383L82.0888 24.5844L82.0827 24.5839C80.6368 24.5248 79.1828 24.2294 78.0328 23.0988C77.4318 22.509 77.2383 21.9977 77.3824 21.5627Z"
        fill="#102222"
      />
      <path
        d="M83.4678 22.1357L84.0418 20.6985L86.0616 19.7812L85.4876 21.219L83.4678 22.1357Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M83.4678 22.1357L84.0418 20.6985L86.0616 19.7812L85.4876 21.219L83.4678 22.1357Z"
        fill="#102222"
      />
      <path
        d="M78.3611 18.1094L79.3771 19.1061L78.1049 19.8588L77.7871 19.5466L78.3611 18.1094Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.2"
        d="M78.3611 18.1094L79.3771 19.1061L78.1049 19.8588L77.7871 19.5466L78.3611 18.1094Z"
        fill="#102222"
      />
      <path
        d="M89.366 26.3749L88.5827 25.6008C88.5827 25.6008 89.6884 25.5234 90.053 24.5608C90.0683 24.5196 90.0785 24.4814 90.0882 24.4432C90.2007 24.0225 90.0108 23.528 89.4434 22.9713C89.4449 22.9677 89.4449 22.9677 89.4449 22.9677C88.8399 22.3759 88.1274 22.0174 87.3828 21.806C87.3196 21.7882 87.2509 21.7729 87.1877 21.7551L87.1663 21.749C87.0976 21.7337 87.0288 21.7179 86.9601 21.7026C86.9 21.6894 86.8394 21.6767 86.7778 21.6675C86.7513 21.6639 86.7258 21.6568 86.7034 21.6548C86.6555 21.6451 86.6081 21.6359 86.5633 21.6313C86.1681 21.5687 85.7734 21.5392 85.3889 21.5203C85.201 21.5122 85.0115 21.5081 84.8251 21.5086C84.5557 21.5061 84.2893 21.5086 84.0367 21.5111C83.9328 21.5147 83.8345 21.5157 83.7362 21.5168C83.0222 21.5325 82.4905 21.5336 82.0866 21.4684C81.8314 21.4286 81.6226 21.3655 81.4469 21.2621C81.3517 21.2061 81.2646 21.1404 81.1862 21.0645C80.8806 20.7635 80.9331 20.4533 81.782 20.2909C82.6861 20.1192 83.4755 20.298 84.0418 20.71L86.0622 19.7928C84.7681 18.9647 83.1806 18.5919 81.421 18.7227L80.4049 17.7266L78.3616 18.1141L79.3777 19.1108C78.604 19.3563 78.1116 19.6986 77.9608 20.1208L77.9567 20.132C77.8116 20.5644 78.0087 21.0772 78.6101 21.668C79.944 22.981 81.6863 23.1669 83.3461 23.1643C84.8908 23.1582 86.0836 22.9739 86.7324 23.6105C87.1358 24.0093 86.8679 24.2746 86.1997 24.4019C85.1342 24.6061 84.2145 24.1926 83.7592 23.8453L81.6186 24.7982C81.8793 24.9835 82.1498 25.1424 82.4192 25.2805C82.9112 25.5285 83.421 25.7032 83.9308 25.8203C84.8358 26.0281 85.7424 26.0546 86.5557 25.9848L87.5702 26.9851L89.6227 26.6306L89.366 26.3749Z"
        fill="#E5E9EA"
      />
      <path
        d="M89.366 26.3749L88.5827 25.6008C88.5827 25.6008 89.6884 25.5234 90.053 24.5608C90.0683 24.5196 90.0785 24.4814 90.0882 24.4432C90.2007 24.0225 90.0108 23.528 89.4434 22.9713C89.4449 22.9677 89.4449 22.9677 89.4449 22.9677C88.8399 22.3759 88.1274 22.0174 87.3828 21.806C87.3196 21.7882 87.2509 21.7729 87.1877 21.7551L87.1663 21.749C87.0976 21.7337 87.0288 21.7179 86.9601 21.7026C86.9 21.6894 86.8394 21.6767 86.7778 21.6675C86.7513 21.6639 86.7258 21.6568 86.7034 21.6548C86.6555 21.6451 86.6081 21.6359 86.5633 21.6313C86.1681 21.5687 85.7734 21.5392 85.3889 21.5203C85.201 21.5122 85.0115 21.5081 84.8251 21.5086C84.5557 21.5061 84.2893 21.5086 84.0367 21.5111C83.9328 21.5147 83.8345 21.5157 83.7362 21.5168C83.0222 21.5325 82.4905 21.5336 82.0866 21.4684C81.8314 21.4286 81.6226 21.3655 81.4469 21.2621C81.3517 21.2061 81.2646 21.1404 81.1862 21.0645C80.8806 20.7635 80.9331 20.4533 81.782 20.2909C82.6861 20.1192 83.4755 20.298 84.0418 20.71L86.0622 19.7928C84.7681 18.9647 83.1806 18.5919 81.421 18.7227L80.4049 17.7266L78.3616 18.1141L79.3777 19.1108C78.604 19.3563 78.1116 19.6986 77.9608 20.1208L77.9567 20.132C77.8116 20.5644 78.0087 21.0772 78.6101 21.668C79.944 22.981 81.6863 23.1669 83.3461 23.1643C84.8908 23.1582 86.0836 22.9739 86.7324 23.6105C87.1358 24.0093 86.8679 24.2746 86.1997 24.4019C85.1342 24.6061 84.2145 24.1926 83.7592 23.8453L81.6186 24.7982C81.8793 24.9835 82.1498 25.1424 82.4192 25.2805C82.9112 25.5285 83.421 25.7032 83.9308 25.8203C84.8358 26.0281 85.7424 26.0546 86.5557 25.9848L87.5702 26.9851L89.6227 26.6306L89.366 26.3749Z"
        fill="#F6F9F9"
      />
      <path
        d="M78.6094 21.6641C79.1146 22.1306 79.7242 22.4998 80.3985 22.7249C81.0703 22.9572 81.791 23.058 82.5116 23.0921C83.2338 23.1369 83.9595 23.1018 84.6883 23.0957C85.0525 23.0957 85.4197 23.1023 85.7803 23.1599C86.1383 23.221 86.4913 23.3585 86.7316 23.607C86.477 23.3697 86.1251 23.2521 85.7706 23.2067C85.4141 23.1599 85.051 23.1614 84.6889 23.1706C83.9636 23.1925 83.2338 23.2271 82.5045 23.1894C81.7782 23.1466 81.0448 23.0458 80.3685 22.7947C80.0313 22.6694 79.7074 22.5156 79.411 22.3251C79.1171 22.1306 78.8508 21.907 78.6094 21.6641Z"
        fill="white"
      />
      <path
        d="M87.569 27.0003L86.995 28.4375L85.9805 27.4377L86.5544 26L87.569 27.0003Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M87.569 27.0003L86.995 28.4375L85.9805 27.4377L86.5544 26L87.569 27.0003Z"
        fill="#102222"
      />
      <path
        d="M89.6222 26.6229L87.64 26.8184L86.5547 25.9766L87.5692 26.9768L89.6222 26.6229Z"
        fill="white"
      />
      <path
        d="M81.4202 18.7227L80.3527 17.8783L78.3613 18.1141L80.4046 17.7266L81.4202 18.7227Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M86.0614 19.7879C85.8287 19.6387 85.5832 19.5098 85.3326 19.3906L85.2598 20.1515L86.0614 19.7879Z"
        fill="white"
      />
      <path
        d="M97.181 72.3773L98.2134 73.2633L96.392 75.3704C96.1767 75.6206 95.9278 75.8421 95.644 76.0305C95.5463 76.0952 95.4435 76.1553 95.3383 76.213C94.8481 76.4772 94.2762 76.6568 93.6261 76.7408C93.4082 76.7698 93.1811 76.7874 92.9463 76.7932C92.7113 76.8011 92.4667 76.7966 92.2138 76.7797C91.8987 76.7603 91.5706 76.7228 91.2295 76.6656C90.8736 76.6063 90.5149 76.5285 90.1516 76.4323C89.8789 76.3606 89.6048 76.2786 89.3288 76.1862C85.2833 74.8398 81.0446 71.4363 78.6309 67.6021C78.4659 67.3404 78.3105 67.0783 78.1644 66.8138C77.9537 66.436 77.7619 66.0543 77.5911 65.6715C77.4518 65.3578 77.3276 65.0505 77.2217 64.749C77.1292 64.4879 77.0497 64.2326 76.982 63.983C76.9148 63.734 76.8594 63.4901 76.816 63.252L76.8155 63.2509C76.6848 62.5407 76.6602 61.8822 76.734 61.2845C76.7501 61.1555 76.7698 61.0288 76.795 60.9058C76.9044 60.3679 77.0992 59.8861 77.376 59.4703C77.4628 59.3396 77.558 59.216 77.6603 59.0982L79.3809 57.1047L80.3261 57.9157C80.4721 57.8984 80.6214 57.8856 80.7747 57.8774C81.3497 57.8481 81.9773 57.8868 82.6521 57.9989C83.0444 58.0642 83.4408 58.1522 83.8409 58.2624C84.3546 58.403 84.8731 58.5782 85.3918 58.7865C88.8925 60.1854 92.4336 63.0282 94.7234 66.2743C95.064 66.7564 95.3761 67.248 95.6565 67.7437C95.8901 68.1574 96.1026 68.5754 96.2899 68.9946C96.5786 69.6415 96.7956 70.2626 96.9434 70.8524C97.0793 71.3874 97.1568 71.8967 97.181 72.3773Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.05"
        d="M96.39 75.3828C96.1747 75.6329 95.9258 75.8544 95.6421 76.0429C95.5443 76.1076 95.4416 76.1677 95.3363 76.2254C94.8461 76.4896 94.2742 76.6692 93.6241 76.7532C93.5438 76.7637 93.461 76.7713 93.3777 76.7789L97.1778 72.3768C97.1781 72.3811 97.1788 72.3859 97.179 72.3902L98.2114 73.2761L96.39 75.3828Z"
        fill="#102222"
      />
      <path
        opacity="0.15"
        d="M79.3823 57.1047L80.3276 57.9157C80.4736 57.8984 80.6229 57.8857 80.7762 57.8774C81.3512 57.8481 81.9788 57.8868 82.6536 57.999C83.0459 58.0643 83.4423 58.1523 83.8424 58.2625C84.3561 58.4031 84.8746 58.5782 85.3933 58.7865C85.8825 58.9819 86.3726 59.2088 86.8604 59.458L79.1544 68.384C78.9725 68.1258 78.7973 67.8649 78.6318 67.6021C78.4668 67.3404 78.3115 67.0783 78.1654 66.8138C77.9546 66.436 77.7629 66.0543 77.592 65.6715C77.4528 65.3578 77.3286 65.0505 77.2226 64.749C77.1302 64.4879 77.0506 64.2326 76.9829 63.983C76.9157 63.734 76.8604 63.4901 76.817 63.252L76.8165 63.2509C76.6858 62.5407 76.6611 61.8822 76.7349 61.2845C76.7511 61.1555 76.7707 61.0288 76.796 60.9058C76.9053 60.3679 77.1002 59.8861 77.377 59.4703C77.4637 59.3396 77.559 59.216 77.6613 59.0982L79.3823 57.1047Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M76.735 61.2845C76.7511 61.1555 76.7708 61.0288 76.796 60.9058C76.9054 60.3679 77.1002 59.8861 77.377 59.4703C77.4638 59.3396 77.559 59.216 77.6613 59.0982L79.3819 57.1047L80.3271 57.9157C80.3341 57.9146 80.3416 57.9141 80.3491 57.9136L80.5665 58.3031L76.7394 62.7369C76.68 62.221 76.6792 61.7351 76.735 61.2845Z"
        fill="white"
      />
      <path
        d="M98.2382 66.7373C98.0515 66.3181 97.8389 65.9001 97.6048 65.4864C97.3244 64.9907 97.0123 64.499 96.6717 64.017C94.3819 60.7708 90.8409 57.9281 87.3401 56.5291C86.8214 56.3209 86.3029 56.1457 85.7892 56.0056C85.3891 55.8955 84.9926 55.808 84.6004 55.7421C83.925 55.6299 83.298 55.5913 82.723 55.6206C82.0601 55.654 81.4668 55.7775 80.9495 55.9836C80.1821 56.2856 79.5805 56.7678 79.1636 57.3984C78.8867 57.8142 78.692 58.2955 78.5826 58.8339C78.5578 58.957 78.5382 59.0836 78.5215 59.2126C78.4477 59.8103 78.4718 60.4687 78.6026 61.1789L78.603 61.18C78.6465 61.4182 78.7018 61.662 78.769 61.9111C78.8367 62.1612 78.9162 62.4165 79.0087 62.677C79.1152 62.9781 79.2389 63.2858 79.3781 63.5995C79.5489 63.9823 79.7407 64.364 79.9514 64.7418C80.097 65.0064 80.2524 65.2684 80.4179 65.5302C82.8316 69.3643 87.0704 72.7678 91.1158 74.1143C91.3913 74.2066 91.6659 74.2886 91.9386 74.3604C92.3019 74.4566 92.6602 74.5337 93.0165 74.5937C93.3576 74.6508 93.6857 74.6883 94.0008 74.7078C94.2537 74.7247 94.4984 74.7287 94.7333 74.7213C94.9681 74.7154 95.1953 74.6978 95.4131 74.6689C96.0627 74.5848 96.6351 74.4053 97.1253 74.1411C97.2311 74.0839 97.3333 74.0233 97.4311 73.9586C97.8576 73.6747 98.2095 73.3194 98.4803 72.8989C98.8905 72.2635 99.1155 71.4802 99.1355 70.5769C99.1493 69.9672 99.0711 69.3042 98.8917 68.5956C98.7434 68.0053 98.5269 67.3841 98.2382 66.7373Z"
        fill="#F6F9F9"
      />
      <path
        opacity="0.15"
        d="M80.9439 66.3171C80.7606 66.0572 80.5845 65.7947 80.4181 65.5302C80.253 65.2685 80.0977 65.0064 79.9516 64.7418C79.7408 64.364 79.5491 63.9818 79.3782 63.5995C79.2385 63.2858 79.1148 62.9786 79.0089 62.677C78.9164 62.416 78.8369 62.1607 78.7692 61.9111C78.7019 61.6621 78.6466 61.4182 78.6032 61.1801L78.6027 61.179C78.472 60.4687 78.4473 59.8103 78.5217 59.2126C78.5378 59.0836 78.5575 58.9569 78.5827 58.8339C78.6921 58.296 78.8874 57.8143 79.1638 57.3984C79.5806 56.7683 80.1822 56.2862 80.9497 55.9836C81.467 55.7775 82.0598 55.654 82.7232 55.6206C83.2982 55.5913 83.9257 55.63 84.6006 55.7422C84.9491 55.8004 85.3013 55.8792 85.6563 55.9721L80.9439 66.3171Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M78.5238 60.6512C78.4665 60.143 78.4667 59.6647 78.5221 59.2204C78.5382 59.0914 78.5579 58.9647 78.5831 58.8417C78.6925 58.3038 78.8878 57.8221 79.1642 57.4062C79.5087 56.886 79.9805 56.4683 80.5663 56.1675L78.5238 60.6512Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M83.4563 69.2629C82.9576 68.7753 82.4839 68.2667 82.0392 67.7426L87.171 56.4768C87.227 56.4987 87.2826 56.5189 87.3391 56.5413C87.8662 56.7518 88.3942 56.9992 88.9188 57.2719L83.4563 69.2629Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M88.4623 72.9734C87.8558 72.6475 87.2555 72.2831 86.6672 71.8859L92.3157 59.4857C92.8836 59.9341 93.4317 60.4115 93.9567 60.9123L88.4623 72.9734Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M90.0836 73.7448C89.7518 73.6072 89.4192 73.4573 89.0882 73.2947L94.4887 61.4389C94.7659 61.7214 95.0363 62.0081 95.2955 62.302L90.0836 73.7448Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M98.4982 67.388C98.5643 67.56 98.6236 67.7295 98.6798 67.897C98.6986 67.9529 98.7173 68.0093 98.7345 68.0645C98.7526 68.1219 98.7697 68.1787 98.7867 68.2355C98.8232 68.3611 98.8592 68.486 98.89 68.6085C99.0694 69.317 99.1476 69.98 99.1338 70.5897C99.1139 71.493 98.8889 72.2763 98.4786 72.9117C98.2078 73.3328 97.8559 73.6875 97.4294 73.9714C97.3316 74.0361 97.2289 74.0962 97.1237 74.1539C96.6335 74.4181 96.0616 74.5977 95.4114 74.6817C95.3306 74.6921 95.2473 74.6997 95.164 74.7073L98.4982 67.388Z"
        fill="#E5E9EA"
      />
      <path
        d="M95.8218 66.2987C94.145 62.575 89.8847 59.0604 86.1211 58.2241C85.9615 58.1886 85.801 58.1573 85.6428 58.1316C81.7692 57.5021 80.0472 60.1383 81.7954 64.0201C83.5436 67.9018 88.1001 71.5588 91.9737 72.1882C92.1314 72.214 92.2869 72.2337 92.4373 72.2487C95.9823 72.5998 97.4985 70.0224 95.8218 66.2987Z"
        fill="#E5E9EA"
      />
      <path
        d="M95.5084 66.2712C95.4544 66.1518 95.3977 66.0317 95.3368 65.9118C93.6315 62.4898 89.7145 59.3288 86.2387 58.5564C86.2071 58.5497 86.1767 58.5426 86.1447 58.5359C86.0233 58.5102 85.9022 58.4873 85.782 58.4675C85.0468 58.3481 84.3915 58.3517 83.829 58.4636C82.4915 58.7292 81.6671 59.604 81.4884 60.8856C81.3849 61.6375 81.5024 62.5304 81.8718 63.5228C81.9405 63.7095 82.018 63.9 82.1059 64.094C82.1864 64.2725 82.2719 64.4492 82.3648 64.626C82.5579 64.9966 82.7764 65.3638 83.0178 65.7241C84.7008 68.245 87.4646 70.4629 90.1509 71.4482C90.5348 71.5892 90.9179 71.705 91.2962 71.7928C91.4761 71.8351 91.655 71.8699 91.8322 71.8988C91.983 71.9234 92.1316 71.9421 92.2747 71.9566C92.3155 71.9607 92.3563 71.9649 92.3956 71.9668C93.3613 72.0448 94.163 71.8911 94.7745 71.5463C95.8181 70.9592 96.3117 69.8187 96.1218 68.3273C96.0415 67.6988 95.8405 67.0078 95.5084 66.2712Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M95.5084 66.2712C95.4544 66.1518 95.3977 66.0317 95.3368 65.9118C93.6315 62.4898 89.7145 59.3288 86.2387 58.5564C86.2071 58.5497 86.1767 58.5426 86.1447 58.5359C86.0233 58.5102 85.9022 58.4873 85.782 58.4675C85.0468 58.3481 84.3915 58.3517 83.829 58.4636C82.4915 58.7292 81.6671 59.604 81.4884 60.8856C81.3849 61.6375 81.5024 62.5304 81.8718 63.5228C81.9405 63.7095 82.018 63.9 82.1059 64.094C82.1864 64.2725 82.2719 64.4492 82.3648 64.626C82.5579 64.9966 82.7764 65.3638 83.0178 65.7241C84.7008 68.245 87.4646 70.4629 90.1509 71.4482C90.5348 71.5892 90.9179 71.705 91.2962 71.7928C91.4761 71.8351 91.655 71.8699 91.8322 71.8988C91.983 71.9234 92.1316 71.9421 92.2747 71.9566C92.3155 71.9607 92.3563 71.9649 92.3956 71.9668C93.3613 72.0448 94.163 71.8911 94.7745 71.5463C95.8181 70.9592 96.3117 69.8187 96.1218 68.3273C96.0415 67.6988 95.8405 67.0078 95.5084 66.2712Z"
        fill="#102222"
      />
      <path
        d="M94.6616 66.6787C94.7225 66.7986 94.7792 66.9187 94.8332 67.0381C95.1653 67.7752 95.3663 68.4656 95.4466 69.0941C95.5573 69.9641 95.4336 70.7139 95.1057 71.3047C95.0025 71.3845 94.8928 71.459 94.7752 71.5248C94.1631 71.8695 93.362 72.0233 92.3963 71.9453C92.3564 71.9434 92.3156 71.9392 92.2754 71.9351C92.1323 71.9206 91.9838 71.9015 91.8329 71.8773C91.6557 71.8485 91.4768 71.8137 91.2969 71.7713C90.9191 71.6841 90.536 71.5683 90.1516 71.4267C87.4653 70.4414 84.7015 68.2235 83.0185 65.7026C82.7776 65.3423 82.5586 64.9751 82.3655 64.6045C82.2726 64.4278 82.1871 64.251 82.1071 64.0726C82.0198 63.8781 81.9418 63.6881 81.8731 63.5014C81.5036 62.5089 81.3861 61.6161 81.4897 60.8642C81.5442 60.4722 81.66 60.1185 81.8318 59.8084C82.1895 59.5311 82.6319 59.3337 83.1544 59.23C83.7174 59.1181 84.3722 59.1145 85.1079 59.2339C85.2281 59.2537 85.3492 59.2766 85.4706 59.3023C85.5021 59.309 85.5331 59.3161 85.5646 59.3228C89.0394 60.0951 92.9563 63.2561 94.6616 66.6787Z"
        fill="#E5E9EA"
      />
      <path
        d="M97.1241 74.1419C94.0201 75.5856 90.2344 73.9576 87.4437 72.2881C83.304 69.6325 79.6794 65.7232 78.6445 61.1659L78.6476 61.1752C78.4023 59.8937 78.4468 58.5091 79.1628 57.3998C78.4174 58.5021 78.3266 59.9003 78.5601 61.1954L78.5575 61.1877C79.3138 64.9489 81.9873 68.2671 85.1004 70.8102C88.3192 73.2807 93.1073 75.9819 97.1241 74.1419Z"
        fill="#F5F5F5"
      />
      <path
        d="M83.4421 63.5799C84.1055 64.9153 85.287 65.6085 86.5296 66.0934L86.0567 66.1341L85.1234 67.2148C85.2949 67.4546 85.4833 67.6748 85.6763 67.8762C86.0306 68.2408 86.4224 68.5463 86.8318 68.8018C87.5589 69.2553 88.3411 69.5512 89.0715 69.7354L89.655 70.9126L91.5514 71.2213L92.4847 70.1406L92.3386 69.8403L92.3067 69.7754C92.392 69.713 92.474 69.637 92.5503 69.5455C92.5761 69.5144 92.5961 69.4839 92.6165 69.4534C92.7157 69.306 93.2706 68.7205 93.4836 68.4648C93.5095 68.4337 93.5294 68.4032 93.5498 68.3727C93.7746 68.0394 93.7579 67.5513 93.4299 66.8951C93.4323 66.892 93.4323 66.892 93.4323 66.892C93.0823 66.1941 92.5694 65.6671 91.9836 65.2584C91.9339 65.2237 91.8788 65.1896 91.8291 65.1549L91.8123 65.1435C91.7572 65.1095 91.7016 65.0754 91.6465 65.0414C91.598 65.0121 91.549 64.9823 91.4982 64.9555C91.476 64.9447 91.4562 64.931 91.4372 64.9221C91.3983 64.8994 91.3594 64.8767 91.3218 64.8594C90.9959 64.6854 90.6608 64.5412 90.3312 64.4096C90.1834 64.3515 90.0333 64.2964 89.884 64.2446L90.505 64.1862L91.4378 63.1055C90.5584 61.994 89.2863 61.1915 87.7124 60.7757L87.1265 59.601L85.2282 59.3241L84.2949 60.4048L84.4786 60.7726C84.3994 60.8259 84.3298 60.8847 84.275 60.9532L84.268 60.9617C84.1325 61.1373 83.4984 61.8385 83.3422 62.0339L83.3352 62.0425C83.0777 62.3775 83.0957 62.8839 83.4421 63.5799Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M93.4387 68.5358C93.2021 68.8143 92.7094 69.3383 92.6165 69.4743C92.5933 69.5057 92.5734 69.5357 92.5497 69.567C92.4745 69.6585 92.3914 69.7334 92.3039 69.7978L91.885 68.9511C91.885 68.9511 92.8284 69.2047 93.4387 68.5358Z"
        fill="#102222"
      />
      <path
        opacity="0.2"
        d="M86.8038 62.7489C86.628 62.3961 86.7683 62.1425 87.5553 62.254C88.3941 62.3791 89.0292 62.7717 89.4 63.2998L91.4375 63.1099L90.5033 64.1873L89.8854 64.2481C89.8699 64.2416 89.8562 64.2385 89.8407 64.2319C89.6068 64.1476 89.3762 64.0699 89.1559 63.9962C89.0645 63.9691 88.9765 63.94 88.8923 63.9102C88.2651 63.7098 87.7982 63.5519 87.4674 63.3722C87.2547 63.2611 87.0937 63.1444 86.9707 62.999C86.9042 62.9246 86.8482 62.8387 86.8038 62.7489Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M84.4786 60.7656L84.2969 60.3991L85.2311 59.3217L85.8133 60.494C85.2287 60.4815 84.7731 60.5694 84.4786 60.7656Z"
        fill="#102222"
      />
      <path
        opacity="0.1"
        d="M90.5908 69.8231L92.4867 70.1323L91.5539 71.213L89.6542 70.9051L89.0744 69.7281C88.342 69.5438 87.5592 69.2479 86.8339 68.7924C86.4238 68.5379 86.0316 68.2308 85.6767 67.8683C85.4849 67.6643 85.2962 67.4483 85.1248 67.207L86.0576 66.1262C86.229 66.3676 86.4195 66.5875 86.6094 66.7881C86.9657 67.1539 87.3566 67.4577 87.7666 67.7122C88.492 68.1672 89.2748 68.4631 90.0072 68.6479L90.5908 69.8231Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M89.0726 69.7422C88.4182 69.6417 87.7766 69.4209 87.1904 69.1094C86.6083 68.7933 86.0716 68.3846 85.6748 67.8828C86.1735 68.2999 86.7029 68.6643 87.2746 68.9694C87.5587 69.1238 87.8533 69.2596 88.1527 69.3889C88.4532 69.5167 88.7619 69.6286 89.0726 69.7422Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M83.3358 62.0532L83.3416 62.0472C83.4984 61.8503 84.1324 61.1506 84.2686 60.9725C84.0117 61.3081 84.0266 61.8126 84.3735 62.5082C85.1459 64.0562 86.6072 64.7412 88.0558 65.2404C89.4033 65.6995 90.4999 65.8973 90.8743 66.6462C91.107 67.1158 90.7928 67.2689 90.1734 67.1785C89.1806 67.0349 88.5015 66.3971 88.2127 65.9579L86.5306 66.1057L86.5255 66.1037C85.2825 65.6173 84.1032 64.9221 83.4406 63.59C83.0938 62.8934 83.0789 62.3889 83.3358 62.0532Z"
        fill="#102222"
      />
      <path
        d="M88.4672 64.3858L89.3999 63.3056L91.4376 63.1136L90.5048 64.1943L88.4672 64.3858Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M88.4672 64.3858L89.3999 63.3056L91.4376 63.1136L90.5048 64.1943L88.4672 64.3858Z"
        fill="#102222"
      />
      <path
        d="M85.229 59.3216L85.8149 60.4963L84.4794 60.77L84.2957 60.4023L85.229 59.3216Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.2"
        d="M85.229 59.3216L85.8149 60.4963L84.4794 60.77L84.2957 60.4023L85.229 59.3216Z"
        fill="#102222"
      />
      <path
        d="M92.3388 69.8302L91.8885 68.9198C91.8885 68.9198 92.8758 69.1852 93.4838 68.4553C93.5097 68.4241 93.5296 68.3936 93.55 68.3631C93.7747 68.0298 93.7581 67.5417 93.4306 66.8855C93.433 66.8825 93.433 66.8825 93.433 66.8825C93.0835 66.1847 92.5701 65.6576 91.9843 65.2489C91.9346 65.2142 91.8795 65.1801 91.8298 65.1454L91.813 65.134C91.7579 65.1 91.7023 65.0659 91.6472 65.0319C91.5987 65.0026 91.5497 64.9727 91.4989 64.946C91.4767 64.9352 91.457 64.9214 91.4379 64.9125C91.399 64.8899 91.3601 64.8672 91.3225 64.8499C90.9966 64.6759 90.6615 64.5317 90.3319 64.4001C90.1707 64.3367 90.0065 64.2758 89.8436 64.2203C89.6091 64.137 89.3764 64.0591 89.1551 63.9854C89.0632 63.9571 88.9773 63.9282 88.8914 63.8999C88.2638 63.6983 87.8001 63.5396 87.4671 63.3613C87.2566 63.2499 87.093 63.1319 86.9714 62.9893C86.9051 62.9117 86.8489 62.8285 86.8034 62.7386C86.6272 62.3842 86.7665 62.1294 87.5555 62.2432C88.3951 62.3656 89.0301 62.7588 89.3997 63.2884L91.4374 63.0964C90.558 61.9844 89.2859 61.1824 87.712 60.7666L87.1261 59.5919L85.2278 59.315L85.8142 60.4898C85.0656 60.4714 84.5332 60.6214 84.2746 60.9441L84.2676 60.9526C84.0108 61.2862 84.0287 61.7925 84.3751 62.4886C85.1433 64.0352 86.6066 64.7214 88.0549 65.2185C89.404 65.6777 90.4995 65.876 90.8733 66.6264C91.1054 67.0955 90.7914 67.2464 90.1704 67.1564C89.1802 67.0141 88.5021 66.3764 88.2096 65.9364L86.056 66.1229C86.2274 66.3627 86.4159 66.5829 86.6089 66.7843C86.9631 67.1489 87.3549 67.4543 87.7644 67.7099C88.4915 68.1634 89.2737 68.4593 90.004 68.6434L90.5875 69.8207L92.4839 70.1294L92.3388 69.8302Z"
        fill="#E5E9EA"
      />
      <path
        d="M92.3388 69.8302L91.8885 68.9198C91.8885 68.9198 92.8758 69.1852 93.4838 68.4553C93.5097 68.4241 93.5296 68.3936 93.55 68.3631C93.7747 68.0298 93.7581 67.5417 93.4306 66.8855C93.433 66.8825 93.433 66.8825 93.433 66.8825C93.0835 66.1847 92.5701 65.6576 91.9843 65.2489C91.9346 65.2142 91.8795 65.1801 91.8298 65.1454L91.813 65.134C91.7579 65.1 91.7023 65.0659 91.6472 65.0319C91.5987 65.0026 91.5497 64.9727 91.4989 64.946C91.4767 64.9352 91.457 64.9214 91.4379 64.9125C91.399 64.8899 91.3601 64.8672 91.3225 64.8499C90.9966 64.6759 90.6615 64.5317 90.3319 64.4001C90.1707 64.3367 90.0065 64.2758 89.8436 64.2203C89.6091 64.137 89.3764 64.0591 89.1551 63.9854C89.0632 63.9571 88.9773 63.9282 88.8914 63.8999C88.2638 63.6983 87.8001 63.5396 87.4671 63.3613C87.2566 63.2499 87.093 63.1319 86.9714 62.9893C86.9051 62.9117 86.8489 62.8285 86.8034 62.7386C86.6272 62.3842 86.7665 62.1294 87.5555 62.2432C88.3951 62.3656 89.0301 62.7588 89.3997 63.2884L91.4374 63.0964C90.558 61.9844 89.2859 61.1824 87.712 60.7666L87.1261 59.5919L85.2278 59.315L85.8142 60.4898C85.0656 60.4714 84.5332 60.6214 84.2746 60.9441L84.2676 60.9526C84.0108 61.2862 84.0287 61.7925 84.3751 62.4886C85.1433 64.0352 86.6066 64.7214 88.0549 65.2185C89.404 65.6777 90.4995 65.876 90.8733 66.6264C91.1054 67.0955 90.7914 67.2464 90.1704 67.1564C89.1802 67.0141 88.5021 66.3764 88.2096 65.9364L86.056 66.1229C86.2274 66.3627 86.4159 66.5829 86.6089 66.7843C86.9631 67.1489 87.3549 67.4543 87.7644 67.7099C88.4915 68.1634 89.2737 68.4593 90.004 68.6434L90.5875 69.8207L92.4839 70.1294L92.3388 69.8302Z"
        fill="#F6F9F9"
      />
      <path
        d="M84.375 62.5C84.6751 63.0591 85.0957 63.5642 85.616 63.9632C86.1316 64.3682 86.7296 64.6727 87.3484 64.9194C87.9644 65.1761 88.608 65.3633 89.2453 65.5773C89.5627 65.6866 89.8806 65.8029 90.1783 65.962C90.472 66.1234 90.7385 66.349 90.8737 66.6384C90.7231 66.3548 90.4516 66.1459 90.1562 65.9998C89.8593 65.852 89.5424 65.7433 89.2238 65.6424C88.5848 65.4433 87.9377 65.2536 87.3134 65.0017C86.6926 64.7457 86.0837 64.4372 85.5694 64.0147C85.3135 63.8043 85.0769 63.5724 84.8756 63.3171C84.6777 63.0598 84.5127 62.7847 84.375 62.5Z"
        fill="white"
      />
      <path
        d="M90.5889 69.8426L89.6556 70.9233L89.0721 69.746L90.0054 68.6653L90.5889 69.8426Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M90.5889 69.8426L89.6556 70.9233L89.0721 69.746L90.0054 68.6653L90.5889 69.8426Z"
        fill="#102222"
      />
      <path
        d="M92.4848 70.1501L90.6976 69.7241L90.0049 68.6641L90.5884 69.8413L92.4848 70.1501Z"
        fill="white"
      />
      <path
        d="M87.7126 60.7797L87.0358 59.722L85.2285 59.3281L87.1268 59.605L87.7126 60.7797Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M91.4376 63.1074C91.2797 62.9077 91.1042 62.7215 90.9213 62.5417L90.6291 63.1833L91.4376 63.1074Z"
        fill="white"
      />
      <path
        d="M187.343 14.4771L188.354 15.2212L186.824 17.2874C186.642 17.5324 186.429 17.7534 186.181 17.947C186.095 18.0132 186.005 18.0758 185.912 18.1359C185.479 18.413 184.965 18.6182 184.372 18.7404C184.174 18.7817 183.966 18.8138 183.75 18.8357C183.535 18.8591 183.309 18.8724 183.075 18.8739C182.783 18.878 182.479 18.8662 182.161 18.8372C181.829 18.8072 181.493 18.7608 181.153 18.6972C180.896 18.6498 180.638 18.5933 180.378 18.5276C176.56 17.568 172.422 14.7282 169.934 11.3653C169.764 11.1361 169.602 10.9054 169.449 10.6717C169.229 10.3381 169.026 9.99989 168.842 9.65968C168.692 9.38059 168.557 9.10608 168.438 8.83615C168.335 8.60238 168.244 8.37269 168.164 8.14758C168.086 7.92298 168.017 7.70245 167.961 7.486L167.961 7.48498C167.791 6.84021 167.723 6.23568 167.75 5.68054C167.756 5.56086 167.765 5.4427 167.78 5.3276C167.843 4.82442 167.989 4.36758 168.215 3.96574C168.286 3.83943 168.365 3.71873 168.451 3.60312L169.898 1.64844L170.824 2.32937C170.957 2.3034 171.094 2.28098 171.234 2.26316C171.762 2.19593 172.342 2.1883 172.972 2.24483C173.337 2.27793 173.709 2.33141 174.085 2.40474C174.567 2.49845 175.057 2.62374 175.549 2.77959C178.869 3.82517 182.326 6.19697 184.659 9.02714C185.006 9.44731 185.327 9.87817 185.62 10.3151C185.864 10.6798 186.089 11.0501 186.29 11.4229C186.6 11.9984 186.843 12.5555 187.02 13.0878C187.181 13.5731 187.287 14.0366 187.343 14.4771Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.05"
        d="M186.823 17.2835C186.642 17.5284 186.428 17.7495 186.18 17.943C186.095 18.0092 186.004 18.0719 185.911 18.132C185.478 18.409 184.964 18.6143 184.372 18.7365C184.298 18.7518 184.222 18.7645 184.146 18.7772L187.34 14.4609C187.341 14.465 187.342 14.4691 187.342 14.4732L188.354 15.2172L186.823 17.2835Z"
        fill="#102222"
      />
      <path
        opacity="0.15"
        d="M169.896 1.64844L170.822 2.32937C170.955 2.30339 171.092 2.28098 171.233 2.26316C171.761 2.19593 172.341 2.18829 172.97 2.24482C173.336 2.27793 173.707 2.3314 174.083 2.40474C174.565 2.49845 175.055 2.62374 175.547 2.77958C176.011 2.92575 176.478 3.10044 176.945 3.29601L170.468 12.0483C170.283 11.8232 170.103 11.595 169.933 11.3648C169.763 11.1356 169.601 10.9049 169.448 10.6711C169.228 10.3376 169.025 9.99938 168.841 9.65917C168.691 9.38007 168.556 9.10556 168.437 8.83564C168.334 8.60187 168.243 8.37217 168.163 8.14706C168.085 7.92246 168.016 7.70194 167.96 7.48549L167.96 7.48447C167.79 6.8397 167.722 6.23517 167.749 5.68003C167.755 5.56035 167.764 5.44219 167.779 5.32709C167.842 4.8239 167.988 4.36706 168.214 3.96523C168.285 3.83892 168.364 3.71822 168.45 3.60261L169.896 1.64844Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M167.749 5.68055C167.755 5.56086 167.764 5.4427 167.779 5.3276C167.842 4.82442 167.988 4.36758 168.214 3.96574C168.285 3.83943 168.364 3.71873 168.45 3.60312L169.897 1.64844L170.823 2.32937C170.83 2.32784 170.836 2.32682 170.843 2.3258L171.07 2.66958L167.853 7.01694C167.763 6.54635 167.728 6.09919 167.749 5.68055Z"
        fill="white"
      />
      <path
        d="M187.925 9.2158C187.723 8.84299 187.499 8.47273 187.255 8.10807C186.963 7.6711 186.641 7.24023 186.294 6.82006C183.961 3.9899 180.504 1.6181 177.184 0.572515C176.692 0.416671 176.202 0.291384 175.719 0.197673C175.343 0.124334 174.972 0.0708564 174.606 0.0377521C173.977 -0.0187799 173.397 -0.0111388 172.869 0.0560884C172.261 0.132992 171.723 0.28782 171.262 0.512929C170.576 0.844482 170.055 1.32984 169.715 1.93896C169.489 2.34079 169.343 2.79764 169.28 3.30082C169.265 3.41592 169.256 3.53408 169.25 3.65376C169.223 4.2089 169.291 4.81343 169.461 5.45871L169.461 5.45973C169.518 5.67618 169.585 5.89671 169.664 6.1213C169.744 6.34692 169.835 6.57611 169.938 6.80988C170.057 7.0798 170.192 7.35431 170.343 7.63341C170.527 7.97413 170.729 8.3123 170.95 8.64538C171.102 8.87864 171.263 9.10935 171.434 9.33904C173.921 12.7019 178.06 15.5418 181.878 16.5013C182.138 16.5675 182.396 16.6235 182.652 16.6709C182.993 16.734 183.329 16.7804 183.661 16.8109C183.979 16.84 184.283 16.8517 184.575 16.8476C184.809 16.8456 185.034 16.8323 185.25 16.8094C185.466 16.7875 185.674 16.7554 185.872 16.7142C186.464 16.5919 186.979 16.3867 187.412 16.1096C187.505 16.0495 187.595 15.9869 187.681 15.9207C188.054 15.6299 188.353 15.2785 188.573 14.872C188.907 14.2583 189.06 13.5219 189.016 12.6887C188.986 12.1264 188.869 11.5214 188.654 10.8812C188.478 10.3485 188.235 9.79181 187.925 9.2158Z"
        fill="#F6F9F9"
      />
      <path
        opacity="0.15"
        d="M171.973 10.0271C171.786 9.80047 171.605 9.57128 171.434 9.33904C171.264 9.10986 171.102 8.87864 170.95 8.64538C170.729 8.31179 170.527 7.97362 170.343 7.63341C170.193 7.35431 170.057 7.07981 169.938 6.80988C169.835 6.57611 169.744 6.34642 169.664 6.12131C169.585 5.89671 169.518 5.67618 169.461 5.45973L169.461 5.45871C169.291 4.81394 169.223 4.20941 169.25 3.65376C169.256 3.53408 169.265 3.41592 169.28 3.30082C169.343 2.79764 169.489 2.34079 169.715 1.93896C170.056 1.32984 170.576 0.844482 171.262 0.512929C171.724 0.287311 172.261 0.132483 172.869 0.0560884C173.397 -0.0111388 173.977 -0.0187799 174.607 0.0377521C174.932 0.0672913 175.261 0.115167 175.595 0.176282L171.973 10.0271Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M169.352 4.97256C169.264 4.50859 169.231 4.06805 169.251 3.65552C169.257 3.53584 169.266 3.41768 169.281 3.30258C169.344 2.79939 169.49 2.34255 169.716 1.94072C169.997 1.43753 170.403 1.02042 170.922 0.703125L169.352 4.97256Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M174.49 12.5452C173.997 12.1312 173.526 11.6957 173.08 11.2435L177.025 0.515625C177.078 0.531923 177.131 0.546693 177.183 0.5635C177.684 0.720873 178.186 0.911861 178.688 1.12627L174.49 12.5452Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M179.357 15.6064C178.776 15.3487 178.198 15.0543 177.629 14.7299L181.97 2.92188C182.524 3.29519 183.062 3.69703 183.58 4.12127L179.357 15.6064Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M180.903 16.2213C180.588 16.1179 180.272 16.0023 179.956 15.876L184.107 4.58594C184.381 4.82633 184.65 5.0718 184.909 5.32493L180.903 16.2213Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M188.21 9.76562C188.282 9.91943 188.349 10.0712 188.412 10.222C188.433 10.2724 188.454 10.3223 188.474 10.3722C188.494 10.4236 188.514 10.4751 188.534 10.5265C188.576 10.6396 188.618 10.7521 188.655 10.8627C188.869 11.5028 188.987 12.1074 189.016 12.6701C189.061 13.5028 188.908 14.2398 188.574 14.8535C188.354 15.2599 188.054 15.6108 187.681 15.9021C187.596 15.9684 187.505 16.031 187.412 16.0911C186.979 16.3681 186.465 16.5734 185.873 16.6956C185.799 16.7109 185.723 16.7236 185.646 16.7364L188.21 9.76562Z"
        fill="#E5E9EA"
      />
      <path
        d="M185.67 8.98806C183.868 5.67611 179.702 2.73491 176.178 2.22613C176.029 2.20474 175.879 2.18691 175.732 2.17418C172.122 1.863 170.719 4.40948 172.598 7.86201C174.476 11.315 178.925 14.3662 182.534 14.6774C182.682 14.6902 182.826 14.6978 182.965 14.7008C186.254 14.7783 187.472 12.3 185.67 8.98806Z"
        fill="#E5E9EA"
      />
      <path
        d="M185.378 8.9651C185.32 8.85865 185.26 8.75221 185.195 8.64628C183.388 5.61341 179.563 2.97475 176.309 2.50415C176.28 2.50008 176.251 2.496 176.222 2.49193C176.108 2.47665 175.995 2.46392 175.883 2.45424C175.197 2.39517 174.595 2.44406 174.084 2.58615C172.871 2.9233 172.173 3.78605 172.097 4.97832C172.054 5.67758 172.224 6.49144 172.633 7.37965C172.71 7.54721 172.794 7.71681 172.888 7.88946C172.974 8.04836 173.066 8.20472 173.163 8.36158C173.367 8.68957 173.594 9.01246 173.84 9.3272C175.564 11.5314 178.262 13.3817 180.804 14.1029C181.168 14.2063 181.529 14.2862 181.882 14.3407C182.051 14.3672 182.218 14.3871 182.383 14.4013C182.524 14.4136 182.662 14.4207 182.795 14.4243C182.832 14.4253 182.87 14.4258 182.907 14.4253C183.801 14.4304 184.528 14.2333 185.068 13.8732C185.988 13.2605 186.364 12.1762 186.086 10.8164C185.968 10.2429 185.735 9.62056 185.378 8.9651Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M185.378 8.9651C185.32 8.85865 185.26 8.75221 185.195 8.64628C183.388 5.61341 179.563 2.97475 176.309 2.50415C176.28 2.50008 176.251 2.496 176.222 2.49193C176.108 2.47665 175.995 2.46392 175.883 2.45424C175.197 2.39517 174.595 2.44406 174.084 2.58615C172.871 2.9233 172.173 3.78605 172.097 4.97832C172.054 5.67758 172.224 6.49144 172.633 7.37965C172.71 7.54721 172.794 7.71681 172.888 7.88946C172.974 8.04836 173.066 8.20472 173.163 8.36158C173.367 8.68957 173.594 9.01246 173.84 9.3272C175.564 11.5314 178.262 13.3817 180.804 14.1029C181.168 14.2063 181.529 14.2862 181.882 14.3407C182.051 14.3672 182.218 14.3871 182.383 14.4013C182.524 14.4136 182.662 14.4207 182.795 14.4243C182.832 14.4253 182.87 14.4258 182.907 14.4253C183.801 14.4304 184.528 14.2333 185.068 13.8732C185.988 13.2605 186.364 12.1762 186.086 10.8164C185.968 10.2429 185.735 9.62056 185.378 8.9651Z"
        fill="#102222"
      />
      <path
        d="M184.628 9.4119C184.692 9.51783 184.752 9.62479 184.81 9.73123C185.167 10.3867 185.4 11.0085 185.517 11.582C185.679 12.3755 185.618 13.0743 185.356 13.6411C185.267 13.7221 185.171 13.798 185.067 13.8667C184.528 14.2263 183.801 14.4234 182.906 14.4188C182.869 14.4198 182.831 14.4188 182.794 14.4178C182.661 14.4142 182.523 14.4071 182.382 14.3949C182.217 14.3806 182.05 14.3607 181.882 14.3343C181.528 14.2798 181.166 14.1998 180.803 14.0964C178.261 13.3752 175.564 11.525 173.839 9.32074C173.592 9.00548 173.366 8.68259 173.162 8.35511C173.065 8.19876 172.974 8.04189 172.887 7.88299C172.793 7.71034 172.709 7.54074 172.632 7.37319C172.224 6.48497 172.053 5.67111 172.096 4.97185C172.119 4.60719 172.202 4.2736 172.338 3.97618C172.648 3.69606 173.043 3.48368 173.516 3.35177C174.026 3.20968 174.629 3.16079 175.315 3.21987C175.427 3.22955 175.54 3.24228 175.653 3.25756C175.683 3.26163 175.711 3.26571 175.741 3.26978C178.995 3.74037 182.821 6.37955 184.628 9.4119Z"
        fill="#E5E9EA"
      />
      <path
        d="M187.413 16.0999C184.655 17.644 181.056 16.408 178.372 15.0639C174.377 12.9061 170.769 9.55743 169.5 5.43314L169.503 5.4418C169.188 4.27908 169.133 3.00125 169.716 1.92969C169.106 2.99616 169.119 4.29028 169.424 5.46625L169.421 5.45912C170.378 8.86988 173.069 11.7393 176.112 13.8651C179.246 15.9165 183.841 18.0719 187.413 16.0999Z"
        fill="#F5F5F5"
      />
      <path
        d="M174.083 7.30639C174.786 8.49 175.922 9.04614 177.099 9.40622L176.667 9.47701L175.883 10.5363C176.057 10.7452 176.246 10.9351 176.438 11.1073C176.789 11.4185 177.171 11.6726 177.566 11.8794C178.267 12.2466 179.008 12.4645 179.692 12.5837L180.311 13.6273L182.079 13.7801L182.863 12.7207L182.708 12.4544L182.674 12.3968C182.749 12.3331 182.819 12.2578 182.883 12.1681C182.904 12.1376 182.921 12.108 182.937 12.079C183.018 11.9364 183.489 11.3589 183.667 11.1088C183.688 11.0782 183.705 11.0487 183.721 11.0197C183.905 10.6973 183.856 10.2486 183.509 9.66749C183.511 9.66443 183.511 9.66443 183.511 9.66443C183.141 9.04615 182.631 8.59644 182.064 8.26081C182.016 8.23229 181.963 8.20479 181.915 8.17627L181.899 8.16659C181.846 8.13909 181.792 8.11159 181.739 8.08409C181.692 8.06015 181.645 8.03672 181.596 8.01533C181.575 8.00667 181.556 7.99547 181.537 7.98885C181.5 7.97051 181.463 7.95269 181.427 7.93944C181.115 7.80193 180.796 7.69243 180.484 7.59363C180.344 7.55034 180.202 7.51011 180.061 7.47293L180.628 7.37616L181.413 6.31683C180.526 5.35425 179.299 4.70388 177.822 4.42988L177.201 3.38888L175.434 3.26562L174.649 4.32547L174.844 4.65142C174.775 4.70592 174.714 4.765 174.669 4.83172L174.662 4.83986C174.55 5.01099 174.015 5.70007 173.884 5.89105L173.878 5.8992C173.664 6.22464 173.716 6.68963 174.083 7.30639Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M183.63 11.1641C183.431 11.4365 183.014 11.953 182.938 12.0854C182.919 12.1159 182.902 12.145 182.883 12.175C182.82 12.2647 182.748 12.339 182.673 12.4047L182.229 11.6545C182.229 11.6545 183.115 11.8221 183.63 11.1641Z"
        fill="#102222"
      />
      <path
        opacity="0.2"
        d="M177.12 6.31029C176.934 5.99758 177.046 5.75465 177.778 5.80304C178.559 5.86008 179.171 6.17737 179.549 6.63828L181.412 6.32252L180.626 7.37931L180.061 7.47811C180.046 7.47302 180.034 7.47149 180.019 7.4664C179.798 7.40477 179.58 7.34926 179.372 7.2968C179.286 7.27796 179.203 7.25759 179.124 7.23569C178.532 7.09461 178.092 6.98155 177.774 6.83894C177.571 6.75134 177.415 6.65509 177.291 6.53031C177.225 6.46563 177.168 6.38974 177.12 6.31029Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M174.842 4.64735L174.649 4.32241L175.435 3.26562L176.052 4.30459C175.513 4.33311 175.1 4.44567 174.842 4.64735Z"
        fill="#102222"
      />
      <path
        opacity="0.1"
        d="M181.097 12.5665L182.865 12.7198L182.081 13.7796L180.311 13.6278L179.695 12.5843C179.008 12.4651 178.266 12.2471 177.567 11.8779C177.172 11.6721 176.789 11.4165 176.438 11.1073C176.247 10.9332 176.058 10.7473 175.884 10.5364L176.668 9.47656C176.842 9.6869 177.033 9.87636 177.222 10.0475C177.575 10.3597 177.956 10.6123 178.351 10.8181C179.051 11.1868 179.792 11.4053 180.479 11.5244L181.097 12.5665Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M179.694 12.5936C179.085 12.5468 178.479 12.3879 177.918 12.1414C177.359 11.8908 176.837 11.5511 176.438 11.1172C176.925 11.4671 177.438 11.7655 177.986 12.0069C178.258 12.1297 178.539 12.2341 178.824 12.3324C179.109 12.4291 179.4 12.5106 179.694 12.5936Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M173.878 5.9036L173.883 5.89799C174.014 5.70599 174.549 5.01742 174.662 4.84375C174.449 5.17072 174.498 5.63367 174.865 6.25043C175.683 7.62197 177.076 8.15163 178.445 8.51069C179.717 8.8402 180.741 8.94614 181.137 9.60975C181.384 10.0264 181.105 10.1888 180.529 10.1481C179.605 10.0849 178.936 9.54456 178.639 9.16004L177.1 9.41265L177.095 9.41113C175.917 9.04902 174.783 8.49083 174.081 7.31028C173.714 6.69352 173.666 6.23006 173.878 5.9036Z"
        fill="#102222"
      />
      <path
        d="M178.765 7.70577L179.549 6.64593L181.411 6.32812L180.627 7.38797L178.765 7.70577Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M178.765 7.70577L179.549 6.64593L181.411 6.32812L180.627 7.38797L178.765 7.70577Z"
        fill="#102222"
      />
      <path
        d="M175.433 3.26562L176.054 4.30663L174.843 4.65091L174.648 4.32547L175.433 3.26562Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.2"
        d="M175.433 3.26562L176.054 4.30663L174.843 4.65091L174.648 4.32547L175.433 3.26562Z"
        fill="#102222"
      />
      <path
        d="M182.708 12.4544L182.23 11.6471C182.23 11.6471 183.158 11.8228 183.667 11.1088C183.688 11.0782 183.705 11.0487 183.721 11.0197C183.905 10.6973 183.856 10.2491 183.509 9.66749C183.511 9.66443 183.511 9.66443 183.511 9.66443C183.14 9.04615 182.631 8.59644 182.064 8.26081C182.016 8.23229 181.963 8.20479 181.915 8.17627L181.898 8.16659C181.845 8.13909 181.792 8.11159 181.738 8.08409C181.692 8.06015 181.645 8.03672 181.596 8.01533C181.574 8.00667 181.556 7.99547 181.537 7.98885C181.5 7.97051 181.463 7.95269 181.427 7.93944C181.115 7.80193 180.796 7.69244 180.483 7.59363C180.33 7.54627 180.175 7.50145 180.022 7.46173C179.8 7.40163 179.58 7.34561 179.372 7.29315C179.286 7.2738 179.205 7.25291 179.123 7.23254C178.531 7.09045 178.093 6.97687 177.775 6.83529C177.573 6.74718 177.414 6.64991 177.292 6.52717C177.226 6.46045 177.168 6.38762 177.121 6.30817C176.934 5.99444 177.044 5.74998 177.779 5.79989C178.561 5.85438 179.173 6.17219 179.549 6.63463L181.412 6.31683C180.525 5.35425 179.298 4.70337 177.821 4.42988L177.2 3.38888L175.433 3.26562L176.054 4.30663C175.364 4.34177 174.883 4.51646 174.668 4.83172L174.662 4.83986C174.449 5.1648 174.5 5.62978 174.867 6.24654C175.681 7.61706 177.076 8.14724 178.444 8.50476C179.718 8.83428 180.74 8.94072 181.136 9.60587C181.382 10.0215 181.104 10.1824 180.526 10.1427C179.604 10.08 178.937 9.54017 178.636 9.15565L176.666 9.47702C176.841 9.68583 177.029 9.87579 177.221 10.0479C177.573 10.3586 177.955 10.6133 178.35 10.82C179.05 11.1872 179.791 11.4052 180.476 11.5244L181.095 12.5679L182.862 12.7207L182.708 12.4544Z"
        fill="#E5E9EA"
      />
      <path
        d="M182.708 12.4544L182.23 11.6471C182.23 11.6471 183.158 11.8228 183.667 11.1088C183.688 11.0782 183.705 11.0487 183.721 11.0197C183.905 10.6973 183.856 10.2491 183.509 9.66749C183.511 9.66443 183.511 9.66443 183.511 9.66443C183.14 9.04615 182.631 8.59644 182.064 8.26081C182.016 8.23229 181.963 8.20479 181.915 8.17627L181.898 8.16659C181.845 8.13909 181.792 8.11159 181.738 8.08409C181.692 8.06015 181.645 8.03672 181.596 8.01533C181.574 8.00667 181.556 7.99547 181.537 7.98885C181.5 7.97051 181.463 7.95269 181.427 7.93944C181.115 7.80193 180.796 7.69244 180.483 7.59363C180.33 7.54627 180.175 7.50145 180.022 7.46173C179.8 7.40163 179.58 7.34561 179.372 7.29315C179.286 7.2738 179.205 7.25291 179.123 7.23254C178.531 7.09045 178.093 6.97687 177.775 6.83529C177.573 6.74718 177.414 6.64991 177.292 6.52717C177.226 6.46045 177.168 6.38762 177.121 6.30817C176.934 5.99444 177.044 5.74998 177.779 5.79989C178.561 5.85438 179.173 6.17219 179.549 6.63463L181.412 6.31683C180.525 5.35425 179.298 4.70337 177.821 4.42988L177.2 3.38888L175.433 3.26562L176.054 4.30663C175.364 4.34177 174.883 4.51646 174.668 4.83172L174.662 4.83986C174.449 5.1648 174.5 5.62978 174.867 6.24654C175.681 7.61706 177.076 8.14724 178.444 8.50476C179.718 8.83428 180.74 8.94072 181.136 9.60587C181.382 10.0215 181.104 10.1824 180.526 10.1427C179.604 10.08 178.937 9.54017 178.636 9.15565L176.666 9.47702C176.841 9.68583 177.029 9.87579 177.221 10.0479C177.573 10.3586 177.955 10.6133 178.35 10.82C179.05 11.1872 179.791 11.4052 180.476 11.5244L181.095 12.5679L182.862 12.7207L182.708 12.4544Z"
        fill="#F6F9F9"
      />
      <path
        d="M174.867 6.25781C175.182 6.75132 175.605 7.18779 176.111 7.51934C176.614 7.85649 177.186 8.09536 177.772 8.27972C178.357 8.47326 178.963 8.6016 179.564 8.75388C179.864 8.83282 180.165 8.91787 180.45 9.04316C180.732 9.1715 180.993 9.36096 181.137 9.61765C180.979 9.36707 180.714 9.19391 180.432 9.07983C180.149 8.96422 179.849 8.88629 179.549 8.8155C178.947 8.67646 178.338 8.54659 177.745 8.35764C177.156 8.16513 176.574 7.92321 176.071 7.56976C175.821 7.39354 175.587 7.19695 175.384 6.97592C175.184 6.75183 175.013 6.50991 174.867 6.25781Z"
        fill="white"
      />
      <path
        d="M181.095 12.5982L180.311 13.6576L179.692 12.614L180.477 11.5547L181.095 12.5982Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M181.095 12.5982L180.311 13.6576L179.692 12.614L180.477 11.5547L181.095 12.5982Z"
        fill="#102222"
      />
      <path
        d="M182.863 12.712L181.188 12.4436L180.477 11.5156L181.095 12.5592L182.863 12.712Z"
        fill="white"
      />
      <path
        d="M177.821 4.42988L177.123 3.50296L175.433 3.26562L177.199 3.38888L177.821 4.42988Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M181.412 6.3286C181.252 6.15544 181.078 5.99603 180.897 5.84375L180.673 6.45491L181.412 6.3286Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M174.896 6.31515C175.264 6.91459 175.743 7.35055 176.283 7.68058L177.603 4.08953L177.2 3.41216L175.995 3.32812L175.79 3.88682L176.054 4.3294C175.894 4.33755 175.747 4.35385 175.61 4.37728L174.896 6.31515Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M183.38 9.4881C183.158 9.16623 182.896 8.89528 182.608 8.66406L181.161 12.5994L182.203 12.6895L182.444 12.0336L182.231 11.6735C182.231 11.6735 182.37 11.6995 182.57 11.6903L183.38 9.4881Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M181.898 8.18044C181.845 8.15294 181.791 8.12544 181.738 8.09793C181.691 8.074 181.644 8.05057 181.595 8.02918C181.574 8.02052 181.555 8.00931 181.536 8.00269C181.499 7.98436 181.462 7.96653 181.426 7.95329C181.114 7.81578 180.795 7.70628 180.483 7.60748C180.412 7.58558 180.342 7.56725 180.271 7.54688L179.793 8.84762C180.417 9.01518 180.894 9.21279 181.136 9.61971C181.382 10.0353 181.104 10.1962 180.526 10.1565C180.103 10.128 179.737 9.99659 179.433 9.82852L178.961 11.1109C179.471 11.3147 179.988 11.4532 180.476 11.5377L180.71 11.9314L182.056 8.27058C182.01 8.24359 181.96 8.21762 181.914 8.19012L181.898 8.18044Z"
        fill="white"
      />
      <path
        d="M125.793 42.1658L124.871 42.8439L126.266 44.727C126.431 44.9504 126.626 45.152 126.852 45.3278C126.93 45.3883 127.012 45.4455 127.097 45.5003C127.491 45.7529 127.96 45.9396 128.5 46.0511C128.681 46.0888 128.87 46.1178 129.067 46.1379C129.263 46.1593 129.469 46.1712 129.682 46.1731C129.948 46.1769 130.225 46.166 130.515 46.1398C130.818 46.1121 131.124 46.0702 131.434 46.0121C131.667 45.9687 131.903 45.9177 132.14 45.8572C135.619 44.9828 139.39 42.395 141.657 39.3307C141.813 39.122 141.959 38.9114 142.099 38.6989C142.299 38.3949 142.485 38.0871 142.652 37.7764C142.789 37.522 142.912 37.2718 143.02 37.0259C143.114 36.8129 143.197 36.6038 143.269 36.3984C143.341 36.1935 143.403 35.9929 143.455 35.7956L143.455 35.7947C143.61 35.2067 143.672 34.6559 143.648 34.1503C143.642 34.0412 143.633 33.9335 143.621 33.8287C143.563 33.3703 143.429 32.9538 143.224 32.5879C143.159 32.4726 143.087 32.363 143.009 32.2577L141.691 30.4766L140.846 31.0974C140.725 31.0736 140.601 31.0531 140.472 31.0369C139.992 30.9759 139.463 30.9688 138.889 31.0202C138.556 31.0503 138.218 31.0989 137.875 31.166C137.436 31.2513 136.99 31.3657 136.541 31.5077C133.516 32.4602 130.366 34.6216 128.239 37.2008C127.923 37.5839 127.63 37.9765 127.364 38.3744C127.142 38.707 126.937 39.0439 126.754 39.3836C126.471 39.9082 126.25 40.4157 126.088 40.9007C125.94 41.342 125.844 41.7646 125.793 42.1658Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.05"
        d="M126.266 44.7283C126.431 44.9518 126.626 45.1534 126.852 45.3292C126.93 45.3897 127.012 45.4469 127.097 45.5017C127.491 45.7542 127.96 45.941 128.5 46.0525C128.566 46.0663 128.635 46.0778 128.704 46.0897L125.794 42.1562C125.794 42.1601 125.793 42.1639 125.792 42.1672L124.87 42.8453L126.266 44.7283Z"
        fill="#102222"
      />
      <path
        opacity="0.15"
        d="M141.691 30.4766L140.847 31.0974C140.725 31.0736 140.601 31.0531 140.473 31.0369C139.992 30.9759 139.464 30.9688 138.89 31.0202C138.557 31.0503 138.219 31.0989 137.876 31.166C137.436 31.2513 136.99 31.3657 136.542 31.5077C136.119 31.6411 135.693 31.8003 135.269 31.9785L141.17 39.954C141.34 39.7486 141.502 39.5408 141.658 39.3307C141.813 39.122 141.96 38.9114 142.099 38.6989C142.3 38.3949 142.485 38.0871 142.652 37.7764C142.789 37.522 142.912 37.2718 143.021 37.0259C143.114 36.8129 143.197 36.6038 143.27 36.3984C143.342 36.1935 143.404 35.9929 143.455 35.7956L143.456 35.7947C143.61 35.2067 143.672 34.6559 143.648 34.1503C143.643 34.0412 143.634 33.9335 143.621 33.8287C143.563 33.3703 143.43 32.9538 143.224 32.5879C143.16 32.4726 143.087 32.363 143.009 32.2577L141.691 30.4766Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M143.648 34.1503C143.643 34.0412 143.633 33.9335 143.621 33.8287C143.563 33.3703 143.43 32.9538 143.224 32.5879C143.159 32.4726 143.087 32.363 143.009 32.2577L141.691 30.4766L140.846 31.0974C140.84 31.0965 140.834 31.0955 140.828 31.0941L140.621 31.4071L143.552 35.3687C143.635 34.9394 143.666 34.532 143.648 34.1503Z"
        fill="white"
      />
      <path
        d="M124.597 38.9075C124.401 39.4907 124.294 40.042 124.268 40.5547C124.227 41.3138 124.367 41.9851 124.671 42.5441C124.871 42.9143 125.144 43.2345 125.484 43.4994C125.562 43.5599 125.644 43.6171 125.729 43.6719C126.124 43.9244 126.592 44.1112 127.132 44.2227C127.313 44.2604 127.502 44.2899 127.699 44.3094C127.896 44.3309 128.101 44.3428 128.315 44.3442C128.581 44.348 128.858 44.3371 129.148 44.3109C129.45 44.2832 129.756 44.2413 130.067 44.1832C130.3 44.1398 130.536 44.0888 130.773 44.0283C134.252 43.154 138.023 40.5661 140.29 37.5018C140.445 37.2931 140.592 37.0825 140.731 36.87C140.932 36.5665 141.117 36.2582 141.285 35.9475C141.421 35.6931 141.545 35.4429 141.653 35.197C141.747 34.9841 141.83 34.7749 141.903 34.5695C141.974 34.3646 142.036 34.164 142.088 33.9667L142.088 33.9658C142.243 33.3783 142.305 32.827 142.281 32.3214C142.276 32.2123 142.267 32.1046 142.254 31.9998C142.196 31.5414 142.063 31.125 141.857 30.759C141.547 30.2039 141.072 29.7612 140.447 29.4596C140.027 29.2543 139.537 29.1132 138.983 29.0432C138.502 28.9822 137.973 28.975 137.4 29.0265C137.067 29.0565 136.728 29.1051 136.386 29.1723C135.946 29.2576 135.5 29.3719 135.052 29.5139C132.026 30.4664 128.876 32.6278 126.75 35.207C126.433 35.5901 126.141 35.9828 125.874 36.3806C125.652 36.7132 125.447 37.0506 125.263 37.3903C124.979 37.9149 124.758 38.4224 124.597 38.9075Z"
        fill="#F6F9F9"
      />
      <path
        opacity="0.15"
        d="M139.798 38.1289C139.968 37.9221 140.133 37.7134 140.289 37.5018C140.445 37.2931 140.592 37.0825 140.731 36.87C140.931 36.5665 141.117 36.2582 141.284 35.9475C141.421 35.6931 141.544 35.4429 141.652 35.197C141.746 34.9841 141.829 34.7749 141.902 34.5695C141.974 34.3646 142.036 34.164 142.087 33.9667L142.088 33.9658C142.242 33.3783 142.305 32.827 142.28 32.3214C142.275 32.2123 142.266 32.1046 142.253 31.9998C142.195 31.5414 142.062 31.125 141.856 30.759C141.546 30.2039 141.072 29.7612 140.447 29.4596C140.026 29.2543 139.536 29.1127 138.982 29.0432C138.502 28.9822 137.973 28.975 137.399 29.0265C137.103 29.0532 136.803 29.097 136.499 29.1528L139.798 38.1289Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M142.187 33.5312C142.267 33.1085 142.297 32.7073 142.279 32.3309C142.274 32.2218 142.265 32.1141 142.252 32.0093C142.194 31.5509 142.061 31.1344 141.855 30.7685C141.599 30.3101 141.229 29.9299 140.757 29.6406L142.187 33.5312Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M137.505 40.4148C137.954 40.0374 138.384 39.6405 138.79 39.2288L135.196 29.4531C135.148 29.4679 135.099 29.4817 135.051 29.497C134.595 29.6404 134.137 29.8143 133.68 30.0101L137.505 40.4148Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M133.07 43.1993C133.599 42.9644 134.126 42.6966 134.645 42.4007L130.688 31.6406C130.184 31.9808 129.693 32.3468 129.222 32.7337L133.07 43.1993Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M131.661 43.7357C131.948 43.6413 132.236 43.536 132.525 43.4207L128.742 33.1328C128.492 33.352 128.247 33.5759 128.011 33.8061L131.661 43.7357Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M125.002 37.8984C124.936 38.0385 124.876 38.1767 124.818 38.3139C124.799 38.3597 124.779 38.4054 124.762 38.4512C124.743 38.4983 124.725 38.545 124.707 38.5917C124.668 38.6947 124.63 38.7971 124.597 38.8981C124.401 39.4813 124.294 40.0326 124.268 40.5453C124.227 41.3044 124.367 41.9758 124.671 42.5347C124.871 42.9049 125.144 43.2251 125.484 43.4901C125.562 43.5506 125.644 43.6077 125.729 43.6625C126.124 43.9151 126.592 44.1019 127.132 44.2134C127.199 44.2272 127.269 44.2391 127.338 44.2505L125.002 37.8984Z"
        fill="#E5E9EA"
      />
      <path
        d="M127.317 37.1935C128.959 34.1754 132.756 31.4957 135.966 31.0316C136.102 31.012 136.239 30.9958 136.373 30.9844C139.663 30.7009 140.941 33.0209 139.229 36.1677C137.517 39.3139 133.464 42.0947 130.174 42.3782C130.04 42.3897 129.908 42.3968 129.781 42.3997C126.785 42.4702 125.675 40.2116 127.317 37.1935Z"
        fill="#E5E9EA"
      />
      <path
        d="M127.582 37.1505C127.635 37.0538 127.69 36.9566 127.748 36.8599C129.395 34.0962 132.881 31.6918 135.846 31.263C135.873 31.2592 135.899 31.2554 135.926 31.252C136.03 31.2382 136.133 31.2263 136.235 31.2177C136.859 31.1639 137.408 31.2082 137.874 31.3378C138.979 31.6451 139.615 32.4314 139.684 33.5178C139.724 34.1553 139.569 34.8967 139.197 35.7058C139.127 35.8583 139.05 36.0127 138.964 36.1704C138.885 36.3148 138.802 36.4577 138.713 36.6006C138.528 36.8994 138.321 37.1934 138.096 37.4807C136.525 39.4896 134.066 41.1754 131.75 41.8325C131.419 41.9269 131.09 41.9998 130.768 42.0493C130.614 42.0731 130.462 42.0917 130.311 42.1046C130.183 42.1156 130.057 42.1222 129.936 42.1256C129.902 42.1265 129.868 42.127 129.834 42.1265C129.019 42.1313 128.356 41.9516 127.865 41.6238C127.026 41.0654 126.684 40.0771 126.937 38.8382C127.045 38.3146 127.257 37.748 127.582 37.1505Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M127.582 37.1505C127.635 37.0538 127.69 36.9566 127.748 36.8599C129.395 34.0962 132.881 31.6918 135.846 31.263C135.873 31.2592 135.899 31.2554 135.926 31.252C136.03 31.2382 136.133 31.2263 136.235 31.2177C136.859 31.1639 137.408 31.2082 137.874 31.3378C138.979 31.6451 139.615 32.4314 139.684 33.5178C139.724 34.1553 139.569 34.8967 139.197 35.7058C139.127 35.8583 139.05 36.0127 138.964 36.1704C138.885 36.3148 138.802 36.4577 138.713 36.6006C138.528 36.8994 138.321 37.1934 138.096 37.4807C136.525 39.4896 134.066 41.1754 131.75 41.8325C131.419 41.9269 131.09 41.9998 130.768 42.0493C130.614 42.0731 130.462 42.0917 130.311 42.1046C130.183 42.1156 130.057 42.1222 129.936 42.1256C129.902 42.1265 129.868 42.127 129.834 42.1265C129.019 42.1313 128.356 41.9516 127.865 41.6238C127.026 41.0654 126.684 40.0771 126.937 38.8382C127.045 38.3146 127.257 37.748 127.582 37.1505Z"
        fill="#102222"
      />
      <path
        d="M128.266 37.5717C128.207 37.6685 128.152 37.7657 128.1 37.8624C127.775 38.4599 127.562 39.0265 127.455 39.5487C127.308 40.272 127.364 40.9086 127.602 41.4251C127.684 41.4985 127.771 41.5676 127.866 41.631C128.357 41.9588 129.02 42.1384 129.835 42.1337C129.869 42.1341 129.903 42.1337 129.937 42.1327C130.058 42.1294 130.184 42.1232 130.312 42.1118C130.463 42.0989 130.615 42.0808 130.769 42.0565C131.091 42.0069 131.42 41.934 131.751 41.8397C134.068 41.1826 136.526 39.4963 138.097 37.4879C138.322 37.2006 138.529 36.9066 138.714 36.6078C138.803 36.4653 138.886 36.3224 138.965 36.1775C139.051 36.0198 139.128 35.8654 139.197 35.713C139.57 34.9039 139.725 34.162 139.685 33.5249C139.664 33.1923 139.59 32.8888 139.465 32.6177C139.183 32.3623 138.824 32.1688 138.392 32.0487C137.927 31.9191 137.378 31.8748 136.753 31.9287C136.651 31.9373 136.548 31.9492 136.445 31.963C136.418 31.9668 136.392 31.9706 136.365 31.9744C133.399 32.4033 129.913 34.8081 128.266 37.5717Z"
        fill="#E5E9EA"
      />
      <path
        d="M125.729 43.6546C128.242 45.0617 131.52 43.9353 133.967 42.7107C137.608 40.7442 140.896 37.6932 142.052 33.9347L142.049 33.9423C142.336 32.8826 142.386 31.7185 141.855 30.7422C142.411 31.7142 142.399 32.8931 142.121 33.9647L142.124 33.958C141.252 37.0662 138.8 39.6812 136.027 41.6181C133.17 43.4878 128.982 45.4515 125.729 43.6546Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.13"
        d="M131.138 37.8672L131.728 38.7377L129.292 39.3419L128.701 38.4723L131.138 37.8672Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M130.788 37.3176C131.058 37.8174 131.951 37.9404 132.708 38.039C132.892 38.0604 133.059 38.0795 133.207 38.1C133.308 38.019 133.383 37.9318 133.434 37.8412C133.706 37.36 133.207 37.2976 132.118 37.1684C131.758 37.1222 131.367 37.0698 131.026 36.9745C131.137 36.7167 131.086 36.4137 130.197 36.448L130.788 37.3176Z"
        fill="#102222"
      />
      <path
        opacity="0.2"
        d="M136.467 32.0312L137.057 32.9008L136.505 33.8762L135.915 33.0057L136.467 32.0312Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M132.912 35.0632C133.132 34.6758 133.631 34.4686 134.223 34.4219C135.166 34.349 135.261 34.8016 135.142 35.148L135.246 35.3015C135.127 35.2829 134.984 35.2791 134.813 35.2919C134.365 35.3272 133.97 35.4554 133.71 35.6836C133.084 35.6088 132.665 35.4997 132.912 35.0632Z"
        fill="#102222"
      />
      <path
        opacity="0.13"
        d="M137.465 34.1416C137.523 34.2388 137.627 34.3084 137.738 34.3579C137.727 34.4146 137.714 34.4728 137.696 34.5338L138.287 35.4034C138.404 35.006 138.36 34.7043 138.204 34.4775L137.615 33.6094C137.615 33.6099 137.294 33.8543 137.465 34.1416Z"
        fill="#102222"
      />
      <path
        d="M132.117 37.1901C133.207 37.3193 133.706 37.3822 133.433 37.8634C133.382 37.954 133.307 38.0416 133.207 38.1222C133.944 38.2222 134.25 38.3328 134.024 38.7325C133.858 39.0265 133.446 39.2943 132.696 39.3529C131.896 39.4144 131.6 39.18 131.728 38.7659L129.292 39.3701C129.287 39.3939 129.285 39.4163 129.282 39.4397C129.19 39.4173 129.073 39.4068 128.925 39.4139L129.389 40.1187C129.403 40.142 129.418 40.1644 129.434 40.1863L129.441 40.1968L129.442 40.1963C129.57 40.3679 129.769 40.5022 130.037 40.5971C130.299 40.6909 130.629 40.7462 131.026 40.7624L131.017 40.7796L129.884 40.8677L130.474 41.7378L131.242 41.6782L132.281 41.5972H132.282L132.833 40.6223C134.46 40.3388 135.792 39.606 136.444 38.4543C136.741 37.9297 136.762 37.5647 136.61 37.3045C136.61 37.3045 136.611 37.3045 136.611 37.3045L136.603 37.2931C136.59 37.2712 136.575 37.2502 136.56 37.2302L136.06 36.5126C135.964 36.6427 135.926 36.7523 135.927 36.8442C135.701 36.7704 135.443 36.7184 135.173 36.6779C134.154 36.5331 133.151 36.5779 133.504 35.9551C133.557 35.8603 133.627 35.7769 133.711 35.7049C133.086 35.6296 132.666 35.5205 132.913 35.0841C133.132 34.6967 133.633 34.4889 134.224 34.4427C135.167 34.3698 135.262 34.8225 135.143 35.1689L135.247 35.3223L135.733 36.0394L138.288 35.4138L137.698 34.5442C137.742 34.3941 137.763 34.2564 137.764 34.133C137.431 33.9748 136.986 33.9047 136.505 33.8905L136.729 33.4959L135.267 33.0547L134.715 34.0296C133.065 34.3264 131.615 35.0798 130.95 36.2538C130.811 36.4992 130.736 36.7084 130.714 36.8876C131.107 37.0572 131.64 37.1287 132.117 37.1901Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.1"
        d="M132.117 37.1901C133.207 37.3193 133.706 37.3822 133.433 37.8634C133.382 37.954 133.307 38.0416 133.207 38.1222C133.944 38.2222 134.25 38.3328 134.024 38.7325C133.858 39.0265 133.446 39.2943 132.696 39.3529C131.896 39.4144 131.6 39.18 131.728 38.7659L129.292 39.3701C129.287 39.3939 129.285 39.4163 129.282 39.4397C129.19 39.4173 129.073 39.4068 128.925 39.4139L129.389 40.1187C129.403 40.142 129.418 40.1644 129.434 40.1863L129.441 40.1968L129.442 40.1963C129.57 40.3679 129.769 40.5022 130.037 40.5971C130.299 40.6909 130.629 40.7462 131.026 40.7624L131.017 40.7796L129.884 40.8677L130.474 41.7378L131.242 41.6782L132.281 41.5972H132.282L132.833 40.6223C134.46 40.3388 135.792 39.606 136.444 38.4543C136.741 37.9297 136.762 37.5647 136.61 37.3045C136.61 37.3045 136.611 37.3045 136.611 37.3045L136.603 37.2931C136.59 37.2712 136.575 37.2502 136.56 37.2302L136.06 36.5126C135.964 36.6427 135.926 36.7523 135.927 36.8442C135.701 36.7704 135.443 36.7184 135.173 36.6779C134.154 36.5331 133.151 36.5779 133.504 35.9551C133.557 35.8603 133.627 35.7769 133.711 35.7049C133.086 35.6296 132.666 35.5205 132.913 35.0841C133.132 34.6967 133.633 34.4889 134.224 34.4427C135.167 34.3698 135.262 34.8225 135.143 35.1689L135.247 35.3223L135.733 36.0394L138.288 35.4138L137.698 34.5442C137.742 34.3941 137.763 34.2564 137.764 34.133C137.431 33.9748 136.986 33.9047 136.505 33.8905L136.729 33.4959L135.267 33.0547L134.715 34.0296C133.065 34.3264 131.615 35.0798 130.95 36.2538C130.811 36.4992 130.736 36.7084 130.714 36.8876C131.107 37.0572 131.64 37.1287 132.117 37.1901Z"
        fill="#102222"
      />
      <g opacity="0.2">
        <path
          d="M132.832 40.6147C133.752 40.4055 134.662 40.0834 135.406 39.5221C135.775 39.2443 136.1 38.9103 136.34 38.5286C136.465 38.3404 136.571 38.1398 136.633 37.9292C136.7 37.721 136.708 37.4927 136.608 37.2969C136.734 37.4803 136.741 37.721 136.691 37.9397C136.637 38.1603 136.539 38.3704 136.42 38.5682C136.189 38.9698 135.853 39.3158 135.478 39.6079C135.091 39.8866 134.666 40.1182 134.216 40.2797C133.767 40.4427 133.301 40.5546 132.832 40.6147Z"
          fill="#102222"
        />
      </g>
      <path
        d="M130.713 36.8729C131.107 37.0425 131.64 37.114 132.117 37.1754C133.206 37.3046 133.706 37.3675 133.433 37.8482C133.382 37.9388 133.307 38.0265 133.207 38.107C132.981 38.2871 132.624 38.4277 132.104 38.4682C131.305 38.5301 131.01 38.2947 131.138 37.8806L128.701 38.4858C128.6 39.0195 128.789 39.4073 129.265 39.6375C129.55 39.7766 129.942 39.8576 130.435 39.8781L129.883 40.853L131.016 40.7653L131.689 40.7129L132.241 39.738C133.87 39.454 135.202 38.7212 135.853 37.5705C136.002 37.3079 136.081 37.0844 136.104 36.8953C136.196 36.16 135.443 35.9227 134.582 35.7936C134.278 35.7503 133.975 35.7231 133.71 35.6902C133.084 35.6149 132.665 35.5058 132.912 35.0694C133.132 34.682 133.631 34.4747 134.223 34.428C135.166 34.3551 135.261 34.8078 135.142 35.1542L137.696 34.5295C137.74 34.3794 137.762 34.2417 137.763 34.1183C137.767 33.6046 137.424 33.3059 136.915 33.1496C136.625 33.0595 136.279 33.0171 135.914 33.0057L136.466 32.0312L134.678 32.1647L134.123 33.1453C132.473 33.4412 131.023 34.1945 130.358 35.3695C129.88 36.2153 130.165 36.6384 130.713 36.8729Z"
        fill="#E5E9EA"
      />
      <path
        d="M130.713 36.8729C131.107 37.0425 131.64 37.114 132.117 37.1754C133.206 37.3046 133.706 37.3675 133.433 37.8482C133.382 37.9388 133.307 38.0265 133.207 38.107C132.981 38.2871 132.624 38.4277 132.104 38.4682C131.305 38.5301 131.01 38.2947 131.138 37.8806L128.701 38.4858C128.6 39.0195 128.789 39.4073 129.265 39.6375C129.55 39.7766 129.942 39.8576 130.435 39.8781L129.883 40.853L131.016 40.7653L131.689 40.7129L132.241 39.738C133.87 39.454 135.202 38.7212 135.853 37.5705C136.002 37.3079 136.081 37.0844 136.104 36.8953C136.196 36.16 135.443 35.9227 134.582 35.7936C134.278 35.7503 133.975 35.7231 133.71 35.6902C133.084 35.6149 132.665 35.5058 132.912 35.0694C133.132 34.682 133.631 34.4747 134.223 34.428C135.166 34.3551 135.261 34.8078 135.142 35.1542L137.696 34.5295C137.74 34.3794 137.762 34.2417 137.763 34.1183C137.767 33.6046 137.424 33.3059 136.915 33.1496C136.625 33.0595 136.279 33.0171 135.914 33.0057L136.466 32.0312L134.678 32.1647L134.123 33.1453C132.473 33.4412 131.023 34.1945 130.358 35.3695C129.88 36.2153 130.165 36.6384 130.713 36.8729Z"
        fill="#F6F9F9"
      />
      <path
        opacity="0.15"
        d="M135.632 36.0938L136.008 37.2597C136.058 37.1315 136.091 37.0139 136.104 36.9081C136.154 36.5121 135.957 36.2615 135.632 36.0938Z"
        fill="white"
      />
      <path
        d="M132.241 39.75L132.832 40.6205L132.28 41.5954L131.689 40.7249L132.241 39.75Z"
        fill="#E5E9EA"
      />
      <path
        opacity="0.13"
        d="M132.241 39.75L132.832 40.6205L132.28 41.5954L131.689 40.7249L132.241 39.75Z"
        fill="#102222"
      />
      <path
        d="M134.124 33.1719C134.124 33.1719 134.055 33.1866 133.934 33.2129C133.873 33.2257 133.799 33.2414 133.715 33.2638C133.632 33.2862 133.537 33.3067 133.435 33.3382C133.333 33.3682 133.221 33.3977 133.106 33.4382C133.048 33.4578 132.989 33.4773 132.928 33.4978C132.868 33.5202 132.807 33.5431 132.745 33.5664C132.682 33.5888 132.62 33.6145 132.557 33.6417C132.494 33.6689 132.43 33.6941 132.367 33.7236C132.241 33.7837 132.113 33.8432 131.99 33.9133C131.864 33.9771 131.745 34.0539 131.627 34.1258C131.512 34.2035 131.399 34.2788 131.297 34.3617C131.19 34.4393 131.099 34.527 131.006 34.6042C130.964 34.6461 130.923 34.6871 130.883 34.7267C130.863 34.7467 130.843 34.7657 130.823 34.7848C130.806 34.8053 130.788 34.8248 130.771 34.8444C130.702 34.9225 130.638 34.993 130.59 35.0612C130.539 35.1283 130.492 35.185 130.462 35.237C130.397 35.338 130.359 35.3957 130.359 35.3957C130.359 35.3957 130.395 35.337 130.456 35.2341C130.485 35.1812 130.529 35.1226 130.578 35.054C130.624 34.984 130.686 34.9106 130.754 34.831C130.771 34.811 130.788 34.7905 130.805 34.77C130.824 34.7505 130.844 34.7305 130.864 34.71C130.903 34.6695 130.944 34.6276 130.986 34.5842C131.077 34.5046 131.167 34.4141 131.275 34.3355C131.377 34.2511 131.491 34.1744 131.606 34.0953C131.725 34.0219 131.844 33.9438 131.971 33.8785C132.096 33.8089 132.225 33.7499 132.353 33.6898C132.416 33.6608 132.481 33.6355 132.545 33.6083C132.608 33.5812 132.671 33.5559 132.734 33.5335C132.797 33.5107 132.858 33.4883 132.919 33.4663C132.98 33.4468 133.04 33.4282 133.098 33.4096C133.215 33.371 133.327 33.3434 133.43 33.3153C133.532 33.2858 133.629 33.2681 133.713 33.2486C133.798 33.2291 133.872 33.2152 133.933 33.2048C134.054 33.1847 134.124 33.1719 134.124 33.1719Z"
        fill="white"
      />
      <path
        d="M131.69 40.7172L129.884 40.8577L130.436 39.8828L130.066 40.7419L131.69 40.7172Z"
        fill="white"
      />
      <path
        d="M135.914 33.0057L136.321 32.127L134.679 32.1647L136.466 32.0312L135.914 33.0057Z"
        fill="white"
      />
      <path
        d="M110.154 88.537L108.473 88.5304L108.472 91.9732C108.472 92.3811 108.525 92.7894 108.638 93.1951C108.677 93.3348 108.724 93.474 108.776 93.6128C109.021 94.256 109.41 94.8865 109.95 95.4905C110.13 95.6934 110.328 95.8934 110.543 96.0888C110.757 96.286 110.989 96.4799 111.239 96.6685C111.549 96.9054 111.886 97.1354 112.251 97.3576C112.632 97.5898 113.03 97.8076 113.447 98.011C113.76 98.1641 114.083 98.3095 114.416 98.4459C119.286 100.458 126.001 100.703 131.357 99.0695C131.723 98.9587 132.081 98.8389 132.431 98.7096C132.933 98.5267 133.422 98.3248 133.89 98.1051C134.274 97.9248 134.639 97.7373 134.982 97.5417C135.279 97.3725 135.56 97.1982 135.825 97.0192C136.089 96.8407 136.338 96.6578 136.571 96.4701L136.572 96.4695C137.269 95.9111 137.824 95.3153 138.238 94.6969C138.327 94.5632 138.411 94.4288 138.487 94.2936C138.82 93.7022 139.027 93.0941 139.105 92.4818C139.129 92.2896 139.14 92.0967 139.14 91.9039L139.143 88.649L137.603 88.643C137.481 88.5088 137.352 88.3759 137.215 88.2447C136.701 87.7522 136.083 87.2811 135.361 86.8402C134.941 86.5842 134.5 86.3459 134.037 86.1251C133.443 85.8415 132.816 85.5859 132.163 85.3609C127.759 83.8385 122.15 83.6332 117.385 84.817C116.677 84.9923 115.988 85.1995 115.325 85.4364C114.772 85.6342 114.235 85.8532 113.721 86.0937C112.929 86.4651 112.223 86.8708 111.609 87.3021C111.049 87.6938 110.565 88.1074 110.154 88.537Z"
        fill="#E2E8E7"
      />
      <path
        opacity="0.1"
        d="M108.472 91.9712C108.471 92.3791 108.525 92.7874 108.638 93.1931C108.677 93.3327 108.724 93.4719 108.776 93.6108C109.021 94.254 109.41 94.8845 109.95 95.4884C110.016 95.5633 110.088 95.6374 110.159 95.7115L110.166 88.5234C110.162 88.5274 110.158 88.5312 110.154 88.535L108.473 88.5284L108.472 91.9712Z"
        fill="#BCC7C6"
      />
      <path
        opacity="0.15"
        d="M139.143 88.6278L137.603 88.6218C137.481 88.4876 137.352 88.3547 137.215 88.2234C136.701 87.7309 136.083 87.2599 135.361 86.819C134.941 86.563 134.5 86.3247 134.037 86.1039C133.443 85.8203 132.816 85.5647 132.163 85.3397C131.547 85.1268 130.906 84.9425 130.249 84.7812L130.237 99.3571C130.616 99.2627 130.99 99.1605 131.357 99.0485C131.723 98.9376 132.08 98.8179 132.431 98.6886C132.933 98.5057 133.421 98.3037 133.89 98.0841C134.274 97.9038 134.639 97.7163 134.981 97.5207C135.279 97.3515 135.56 97.1771 135.825 96.9982C136.089 96.8197 136.338 96.6368 136.571 96.4491L136.572 96.4485C137.268 95.8901 137.824 95.2943 138.238 94.6759C138.327 94.5422 138.411 94.4078 138.487 94.2725C138.82 93.6811 139.027 93.073 139.104 92.4608C139.129 92.2685 139.14 92.0757 139.14 91.8829L139.143 88.6278Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M138.238 94.6987C138.327 94.5651 138.411 94.4306 138.487 94.2954C138.82 93.704 139.027 93.0959 139.105 92.4836C139.129 92.2914 139.14 92.0986 139.14 91.9057L139.143 88.6508L137.603 88.6449C137.597 88.6383 137.591 88.6316 137.585 88.625L137.066 88.8135L137.06 96.0531C137.533 95.6186 137.926 95.1652 138.238 94.6987Z"
        fill="white"
      />
      <path
        d="M113.724 82.4216C114.238 82.1811 114.774 81.9619 115.327 81.7643C115.99 81.5274 116.679 81.3203 117.388 81.1451C122.153 79.9613 127.762 80.1666 132.166 81.689C132.819 81.9138 133.446 82.1694 134.039 82.4532C134.502 82.6739 134.944 82.9124 135.364 83.1683C136.085 83.6092 136.703 84.0803 137.217 84.5728C137.81 85.1402 138.265 85.7351 138.582 86.3458C139.055 87.2488 139.228 88.1864 139.108 89.1124C139.031 89.7246 138.823 90.3327 138.491 90.9241C138.414 91.0594 138.33 91.1938 138.241 91.3275C137.827 91.9459 137.272 92.5417 136.575 93.1001L136.574 93.1007C136.341 93.2884 136.092 93.4715 135.828 93.6498C135.563 93.8287 135.282 94.0031 134.985 94.1723C134.642 94.3679 134.277 94.5554 133.893 94.7357C133.425 94.9555 132.937 95.1573 132.434 95.3402C132.084 95.4695 131.726 95.5892 131.36 95.7001C126.004 97.3335 119.289 97.0886 114.418 95.0765C114.086 94.9399 113.763 94.7947 113.45 94.6416C113.033 94.438 112.635 94.2204 112.254 93.9882C111.889 93.7658 111.552 93.536 111.241 93.2989C110.991 93.1102 110.759 92.9164 110.546 92.7192C110.331 92.5238 110.133 92.3236 109.953 92.1209C109.413 91.5169 109.024 90.8862 108.779 90.2432C108.726 90.1043 108.68 89.9651 108.641 89.8255C108.471 89.2151 108.429 88.599 108.516 87.9864C108.647 87.0607 109.069 86.1464 109.781 85.2857C110.261 84.7043 110.87 84.1478 111.611 83.6303C112.226 83.1985 112.931 82.7928 113.724 82.4216Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.1"
        d="M113.724 82.4216C114.238 82.1811 114.774 81.9619 115.327 81.7643C115.99 81.5274 116.679 81.3203 117.388 81.1451C122.153 79.9613 127.762 80.1666 132.166 81.689C132.819 81.9138 133.446 82.1694 134.039 82.4532C134.502 82.6739 134.944 82.9124 135.364 83.1683C136.085 83.6092 136.703 84.0803 137.217 84.5728C137.81 85.1402 138.265 85.7351 138.582 86.3458C139.055 87.2488 139.228 88.1864 139.108 89.1124C139.031 89.7246 138.823 90.3327 138.491 90.9241C138.414 91.0594 138.33 91.1938 138.241 91.3275C137.827 91.9459 137.272 92.5417 136.575 93.1001L136.574 93.1007C136.341 93.2884 136.092 93.4715 135.828 93.6498C135.563 93.8287 135.282 94.0031 134.985 94.1723C134.642 94.3679 134.277 94.5554 133.893 94.7357C133.425 94.9555 132.937 95.1573 132.434 95.3402C132.084 95.4695 131.726 95.5892 131.36 95.7001C126.004 97.3335 119.289 97.0886 114.418 95.0765C114.086 94.9399 113.763 94.7947 113.45 94.6416C113.033 94.438 112.635 94.2204 112.254 93.9882C111.889 93.7658 111.552 93.536 111.241 93.2989C110.991 93.1102 110.759 92.9164 110.546 92.7192C110.331 92.5238 110.133 92.3236 109.953 92.1209C109.413 91.5169 109.024 90.8862 108.779 90.2432C108.726 90.1043 108.68 89.9651 108.641 89.8255C108.471 89.2151 108.429 88.599 108.516 87.9864C108.647 87.0607 109.069 86.1464 109.781 85.2857C110.261 84.7043 110.87 84.1478 111.611 83.6303C112.226 83.1985 112.931 82.7928 113.724 82.4216Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M113.724 82.4216C114.238 82.1811 114.774 81.9619 115.327 81.7643C115.99 81.5274 116.679 81.3203 117.388 81.1451C122.153 79.9613 127.762 80.1666 132.166 81.689C132.819 81.9138 133.446 82.1694 134.039 82.4532C134.502 82.6739 134.944 82.9124 135.364 83.1683C136.085 83.6092 136.703 84.0803 137.217 84.5728C137.81 85.1402 138.265 85.7351 138.582 86.3458C139.055 87.2488 139.228 88.1864 139.108 89.1124C139.031 89.7246 138.823 90.3327 138.491 90.9241C138.414 91.0594 138.33 91.1938 138.241 91.3275C137.827 91.9459 137.272 92.5417 136.575 93.1001L136.574 93.1007C136.341 93.2884 136.092 93.4715 135.828 93.6498C135.563 93.8287 135.282 94.0031 134.985 94.1723C134.642 94.3679 134.277 94.5554 133.893 94.7357C133.425 94.9555 132.937 95.1573 132.434 95.3402C132.084 95.4695 131.726 95.5892 131.36 95.7001C126.004 97.3335 119.289 97.0886 114.418 95.0765C114.086 94.9399 113.763 94.7947 113.45 94.6416C113.033 94.438 112.635 94.2204 112.254 93.9882C111.889 93.7658 111.552 93.536 111.241 93.2989C110.991 93.1102 110.759 92.9164 110.546 92.7192C110.331 92.5238 110.133 92.3236 109.953 92.1209C109.413 91.5169 109.024 90.8862 108.779 90.2432C108.726 90.1043 108.68 89.9651 108.641 89.8255C108.471 89.2151 108.429 88.599 108.516 87.9864C108.647 87.0607 109.069 86.1464 109.781 85.2857C110.261 84.7043 110.87 84.1478 111.611 83.6303C112.226 83.1985 112.931 82.7928 113.724 82.4216Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M130.232 96.004C130.614 95.9091 130.991 95.8062 131.36 95.6934C131.726 95.5826 132.084 95.4629 132.434 95.3336C132.936 95.1507 133.425 94.9489 133.893 94.7291C134.277 94.5487 134.642 94.3613 134.985 94.1657C135.282 93.9965 135.563 93.8221 135.828 93.6432C136.092 93.4646 136.341 93.2817 136.574 93.0941L136.575 93.0935C137.272 92.535 137.827 91.9391 138.241 91.3208C138.33 91.1872 138.414 91.0527 138.491 90.9175C138.823 90.3261 139.03 89.718 139.108 89.1058C139.228 88.1797 139.055 87.2424 138.582 86.3392C138.265 85.7285 137.81 85.1333 137.217 84.5662C136.703 84.0736 136.085 83.6026 135.364 83.1617C134.991 82.9343 134.598 82.723 134.191 82.5234L130.232 96.004Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M137.082 92.6555C137.546 92.2265 137.933 91.7793 138.241 91.3193C138.33 91.1857 138.414 91.0512 138.49 90.916C138.823 90.3246 139.03 89.7165 139.107 89.1042C139.206 88.3395 139.102 87.5671 138.798 86.8125L137.082 92.6555Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M125.51 96.7127C126.371 96.6603 127.225 96.5679 128.065 96.4371L132.375 81.7554C132.305 81.7304 132.236 81.7044 132.166 81.6801C131.502 81.4508 130.809 81.2551 130.098 81.0859L125.51 96.7127Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M117.834 96.1359C118.664 96.3218 119.52 96.4663 120.391 96.5708L125.136 80.4108C124.242 80.3711 123.344 80.3739 122.448 80.4177L117.834 96.1359Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M115.695 95.5591C116.116 95.6989 116.548 95.827 116.989 95.943L121.525 80.4922C121.038 80.5321 120.553 80.5816 120.073 80.6467L115.695 95.5591Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M112.965 82.8047C112.764 82.9121 112.571 83.0221 112.384 83.134C112.321 83.1713 112.258 83.2084 112.197 83.2464C112.134 83.2855 112.072 83.3248 112.01 83.3643C111.874 83.4519 111.74 83.5399 111.612 83.6296C110.871 84.1474 110.262 84.7038 109.782 85.2851C109.07 86.1456 108.648 87.0599 108.517 87.9857C108.43 88.5982 108.472 89.2144 108.642 89.8249C108.681 89.9645 108.728 90.1037 108.78 90.2425C109.025 90.8858 109.414 91.5163 109.954 92.1202C110.021 92.1955 110.093 92.27 110.164 92.3444L112.965 82.8047Z"
        fill="#F3F5F6"
      />
      <path
        d="M116.332 83.9789C120.91 81.8536 127.735 82.0116 131.93 84.273C132.108 84.3689 132.283 84.4694 132.452 84.5731C136.582 87.1164 136.061 90.9731 131.288 93.1888C126.515 95.4044 119.298 95.1397 115.168 92.5964C115 92.4927 114.838 92.3857 114.686 92.278C111.087 89.74 111.753 86.1042 116.332 83.9789Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.4"
        d="M116.332 83.9789C120.91 81.8536 127.735 82.0116 131.93 84.273C132.108 84.3689 132.283 84.4694 132.452 84.5731C136.582 87.1164 136.061 90.9731 131.288 93.1888C126.515 95.4044 119.298 95.1397 115.168 92.5964C115 92.4927 114.838 92.3857 114.686 92.278C111.087 89.74 111.753 86.1042 116.332 83.9789Z"
        fill="#C1CFCE"
      />
      <path
        d="M116.664 84.1851C116.811 84.1168 116.961 84.0505 117.115 83.9877C121.477 82.1671 127.694 82.3786 131.568 84.4668C131.603 84.4858 131.637 84.5045 131.672 84.5236C131.807 84.5974 131.939 84.6737 132.067 84.7527C132.851 85.2355 133.46 85.7685 133.896 86.3283C134.932 87.6583 134.995 89.1424 134.126 90.4851C133.615 91.2719 132.783 92.0112 131.635 92.6406C131.42 92.7597 131.194 92.8745 130.955 92.9855C130.735 93.0874 130.513 93.1833 130.283 93.2739C129.803 93.4645 129.302 93.6308 128.785 93.7729C125.173 94.7691 120.796 94.608 117.488 93.3571C117.015 93.1786 116.563 92.9772 116.139 92.7534C115.937 92.6475 115.741 92.5355 115.552 92.4193C115.391 92.3202 115.237 92.2178 115.092 92.1157C115.05 92.0867 115.009 92.0575 114.97 92.0273C114.004 91.3195 113.379 90.5278 113.086 89.711C112.585 88.3184 113.045 86.853 114.428 85.612C115.011 85.0889 115.758 84.606 116.664 84.1851Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.5"
        d="M116.664 84.1851C116.811 84.1168 116.961 84.0505 117.115 83.9877C121.477 82.1671 127.694 82.3786 131.568 84.4668C131.603 84.4858 131.637 84.5045 131.672 84.5236C131.807 84.5974 131.939 84.6737 132.067 84.7527C132.851 85.2355 133.46 85.7685 133.896 86.3283C134.932 87.6583 134.995 89.1424 134.126 90.4851C133.615 91.2719 132.783 92.0112 131.635 92.6406C131.42 92.7597 131.194 92.8745 130.955 92.9855C130.735 93.0874 130.513 93.1833 130.283 93.2739C129.803 93.4645 129.302 93.6308 128.785 93.7729C125.173 94.7691 120.796 94.608 117.488 93.3571C117.015 93.1786 116.563 92.9772 116.139 92.7534C115.937 92.6475 115.741 92.5355 115.552 92.4193C115.391 92.3202 115.237 92.2178 115.092 92.1157C115.05 92.0867 115.009 92.0575 114.97 92.0273C114.004 91.3195 113.379 90.5278 113.086 89.711C112.585 88.3184 113.045 86.853 114.428 85.612C115.011 85.0889 115.758 84.606 116.664 84.1851Z"
        fill="#C1CFCE"
      />
      <path
        d="M117.11 85.2769C116.957 85.3396 116.807 85.4061 116.66 85.4743C115.753 85.8949 115.007 86.3779 114.424 86.9007C113.617 87.6248 113.126 88.4255 112.955 89.2433C112.987 89.4016 113.03 89.5597 113.086 89.7166C113.379 90.5336 114.005 91.3254 114.971 92.0329C115.01 92.0631 115.051 92.0923 115.092 92.1213C115.238 92.2234 115.392 92.3257 115.553 92.425C115.742 92.5411 115.938 92.6532 116.14 92.759C116.564 92.9828 117.015 93.1842 117.489 93.3627C120.797 94.6134 125.174 94.7745 128.785 93.7786C129.302 93.6364 129.803 93.4702 130.284 93.2795C130.513 93.189 130.736 93.093 130.955 92.9912C131.194 92.8801 131.421 92.7654 131.636 92.6462C132.783 92.0169 133.615 91.2775 134.126 90.4907C134.392 90.08 134.57 89.656 134.66 89.2273C134.55 88.6787 134.296 88.136 133.892 87.6169C133.456 87.057 132.846 86.5242 132.062 86.0412C131.934 85.9622 131.802 85.8861 131.668 85.8121C131.633 85.793 131.599 85.7745 131.564 85.7553C127.689 83.6677 121.472 83.4562 117.11 85.2769Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.25"
        d="M117.11 85.2769C116.957 85.3396 116.807 85.4061 116.66 85.4743C115.753 85.8949 115.007 86.3779 114.424 86.9007C113.617 87.6248 113.126 88.4255 112.955 89.2433C112.987 89.4016 113.03 89.5597 113.086 89.7166C113.379 90.5336 114.005 91.3254 114.971 92.0329C115.01 92.0631 115.051 92.0923 115.092 92.1213C115.238 92.2234 115.392 92.3257 115.553 92.425C115.742 92.5411 115.938 92.6532 116.14 92.759C116.564 92.9828 117.015 93.1842 117.489 93.3627C120.797 94.6134 125.174 94.7745 128.785 93.7786C129.302 93.6364 129.803 93.4702 130.284 93.2795C130.513 93.189 130.736 93.093 130.955 92.9912C131.194 92.8801 131.421 92.7654 131.636 92.6462C132.783 92.0169 133.615 91.2775 134.126 90.4907C134.392 90.08 134.57 89.656 134.66 89.2273C134.55 88.6787 134.296 88.136 133.892 87.6169C133.456 87.057 132.846 86.5242 132.062 86.0412C131.934 85.9622 131.802 85.8861 131.668 85.8121C131.633 85.793 131.599 85.7745 131.564 85.7553C127.689 83.6677 121.472 83.4562 117.11 85.2769Z"
        fill="#C1CFCE"
      />
      <path
        d="M108.779 90.2402C110.514 94.0994 115.369 95.6383 119.328 96.3335C125.345 97.1978 131.895 96.4733 136.547 93.0494L136.537 93.0558C137.802 92.0559 138.88 90.7257 139.107 89.1094C138.913 90.743 137.868 92.1236 136.602 93.1455L136.611 93.1404C132.863 96.0452 127.68 96.9859 122.714 96.8469C117.708 96.5538 111.047 95.2077 108.779 90.2402Z"
        fill="white"
      />
      <path
        d="M130.116 91.4075C128.416 92.1195 126.751 91.8125 125.198 91.261L125.607 91.6818L125.605 93.4465C125.251 93.5321 124.897 93.5859 124.553 93.6179C123.927 93.6719 123.314 93.6411 122.725 93.5489C121.678 93.3855 120.707 93.029 119.876 92.611L118.379 93.2397L116.356 91.995L116.357 90.2303L116.736 90.068L116.818 90.0335C116.789 89.9058 116.774 89.7686 116.777 89.6214C116.777 89.5716 116.783 89.5267 116.789 89.4818C116.815 89.2639 116.77 88.268 116.778 87.8567C116.779 87.8069 116.785 87.762 116.79 87.7171C116.849 87.2238 117.26 86.7809 118.097 86.4326C118.097 86.428 118.097 86.428 118.097 86.428C118.988 86.0581 119.894 85.9804 120.772 86.0722C120.846 86.0799 120.926 86.0928 121 86.1006L121.025 86.1032C121.104 86.1159 121.183 86.1288 121.263 86.1417C121.332 86.1536 121.402 86.1651 121.471 86.1816C121.5 86.1893 121.53 86.1923 121.555 86.1995C121.609 86.2096 121.664 86.2199 121.713 86.2342C122.159 86.3353 122.588 86.4709 123.003 86.6143C123.189 86.6795 123.373 86.7494 123.555 86.8219L123.022 86.2652L123.023 84.5005C124.744 84.172 126.582 84.4507 128.39 85.3344L129.887 84.7102L131.886 85.9864L131.884 87.7511L131.415 87.9467C131.446 88.0601 131.464 88.1719 131.46 88.2803L131.46 88.294C131.444 88.5679 131.47 89.7358 131.458 90.0452L131.458 90.0589C131.429 90.5776 131.003 91.0372 130.116 91.4075Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.15"
        d="M116.778 87.9375C116.774 88.3893 116.811 89.2772 116.788 89.4798C116.784 89.5278 116.779 89.5719 116.775 89.62C116.772 89.7665 116.789 89.9034 116.819 90.0344L117.895 89.5816C117.895 89.5816 116.808 89.0566 116.778 87.9375Z"
        fill="#C1CFCE"
      />
      <path
        opacity="0.45"
        d="M127.646 87.9321C128.096 87.744 128.17 87.3941 127.344 86.8621C126.458 86.3006 125.548 86.1547 124.774 86.3483L123.022 84.5234L123.024 86.2863L123.553 86.8428C123.573 86.8491 123.588 86.8575 123.608 86.8638C123.894 86.9739 124.173 87.0877 124.438 87.1973C124.546 87.2455 124.652 87.2898 124.754 87.3299C125.503 87.6495 126.067 87.8794 126.521 87.9785C126.81 88.0465 127.055 88.0673 127.287 88.0316C127.41 88.0159 127.532 87.9801 127.646 87.9321Z"
        fill="#AEAEAE"
      />
      <path
        opacity="0.2"
        d="M131.419 87.9663L131.885 87.7707L131.882 86.0078L130.39 86.6334C130.947 87.0939 131.302 87.5445 131.419 87.9663Z"
        fill="#C1CFCE"
      />
      <path
        opacity="0.15"
        d="M118.379 91.4784L116.356 90.2344L116.354 91.9991L118.38 93.2472L119.874 92.6157C120.708 93.0355 121.679 93.3916 122.725 93.5524C123.314 93.6462 123.929 93.6762 124.554 93.6241C124.898 93.5888 125.249 93.5393 125.605 93.452L125.606 91.6873C125.251 91.7747 124.895 91.8259 124.556 91.8596C123.927 91.9137 123.316 91.8817 122.727 91.788C121.68 91.6271 120.709 91.2708 119.875 90.8512L118.379 91.4784Z"
        fill="#747474"
      />
      <path
        opacity="0.2"
        d="M119.874 92.6406C120.567 93.0759 121.344 93.3881 122.145 93.5706C122.945 93.7462 123.777 93.7974 124.554 93.6491C123.75 93.6362 122.961 93.5488 122.18 93.3714C121.789 93.2862 121.403 93.1748 121.019 93.0535C120.635 92.9308 120.256 92.786 119.874 92.6406Z"
        fill="#C1CFCE"
      />
      <path
        opacity="0.35"
        d="M131.458 90.0652L131.457 90.055C131.47 89.7442 131.443 88.5771 131.46 88.3005C131.429 88.8218 131.007 89.2815 130.12 89.6517C128.147 90.4746 126.227 89.9333 124.469 89.2287C122.838 88.5686 121.653 87.8669 120.697 88.2639C120.1 88.5153 120.27 88.9121 120.922 89.3284C121.966 89.9972 123.117 89.9492 123.742 89.7726L125.196 91.2704L125.202 91.2726C126.758 91.8227 128.422 92.1261 130.118 91.4162C131.005 91.0462 131.427 90.5865 131.458 90.0652Z"
        fill="#AEAEAE"
      />
      <path
        d="M124.771 88.115L124.772 86.3503L123.023 84.5234L123.021 86.2881L124.771 88.115Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.15"
        d="M124.771 88.115L124.772 86.3503L123.023 84.5234L123.021 86.2881L124.771 88.115Z"
        fill="#ADADAD"
      />
      <path
        d="M131.886 85.9766L130.388 86.6009L131.416 87.9369L131.885 87.7412L131.886 85.9766Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.45"
        d="M131.886 85.9766L130.388 86.6009L131.416 87.9369L131.885 87.7412L131.886 85.9766Z"
        fill="#C1CFCE"
      />
      <path
        d="M116.736 90.0681L117.894 89.5807C117.894 89.5807 116.756 89.0302 116.778 87.8567C116.779 87.8069 116.785 87.762 116.791 87.7171C116.85 87.2238 117.26 86.781 118.097 86.4326C118.097 86.4281 118.097 86.4281 118.097 86.4281C118.988 86.0581 119.894 85.9805 120.772 86.0722C120.846 86.08 120.926 86.0929 121 86.1006L121.025 86.1032C121.104 86.1159 121.183 86.1288 121.263 86.1417C121.332 86.1536 121.401 86.1651 121.471 86.1816C121.5 86.1894 121.53 86.1924 121.555 86.1995C121.609 86.2096 121.664 86.22 121.713 86.2343C122.159 86.3353 122.588 86.4712 123.003 86.6144C123.206 86.6855 123.408 86.7611 123.605 86.8408C123.891 86.9524 124.172 87.0681 124.439 87.1778C124.547 87.2254 124.651 87.2679 124.754 87.3106C125.504 87.6298 126.066 87.8565 126.521 87.9586C126.808 88.025 127.056 88.0464 127.286 88.0115C127.41 87.9926 127.53 87.9598 127.645 87.9128C128.096 87.7239 128.172 87.373 127.342 86.8413C126.458 86.2768 125.547 86.1312 124.773 86.3274L123.023 84.5006C124.744 84.1721 126.582 84.4505 128.39 85.3342L129.887 84.7099L131.886 85.986L130.388 86.6104C131.103 87.1982 131.479 87.7688 131.46 88.2799L131.46 88.2935C131.43 88.8126 131.004 89.2719 130.117 89.6425C128.149 90.4672 126.226 89.9255 124.47 89.2197C122.838 88.5586 121.653 87.8579 120.696 88.2573C120.1 88.5083 120.272 88.9031 120.925 89.321C121.966 89.9884 123.115 89.9406 123.744 89.7658L125.606 91.6814C125.252 91.767 124.898 91.8208 124.555 91.853C123.929 91.907 123.315 91.8762 122.726 91.7841C121.68 91.6207 120.709 91.2642 119.878 90.8461L118.38 91.4749L116.357 90.2301L116.736 90.0681Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.35"
        d="M116.736 90.0681L117.894 89.5807C117.894 89.5807 116.756 89.0302 116.778 87.8567C116.779 87.8069 116.785 87.762 116.791 87.7171C116.85 87.2238 117.26 86.781 118.097 86.4326C118.097 86.4281 118.097 86.4281 118.097 86.4281C118.988 86.0581 119.894 85.9805 120.772 86.0722C120.846 86.08 120.926 86.0929 121 86.1006L121.025 86.1032C121.104 86.1159 121.183 86.1288 121.263 86.1417C121.332 86.1536 121.401 86.1651 121.471 86.1816C121.5 86.1894 121.53 86.1924 121.555 86.1995C121.609 86.2096 121.664 86.22 121.713 86.2343C122.159 86.3353 122.588 86.4712 123.003 86.6144C123.206 86.6855 123.408 86.7611 123.605 86.8408C123.891 86.9524 124.172 87.0681 124.439 87.1778C124.547 87.2254 124.651 87.2679 124.754 87.3106C125.504 87.6298 126.066 87.8565 126.521 87.9586C126.808 88.025 127.056 88.0464 127.286 88.0115C127.41 87.9926 127.53 87.9598 127.645 87.9128C128.096 87.7239 128.172 87.373 127.342 86.8413C126.458 86.2768 125.547 86.1312 124.773 86.3274L123.023 84.5006C124.744 84.1721 126.582 84.4505 128.39 85.3342L129.887 84.7099L131.886 85.986L130.388 86.6104C131.103 87.1982 131.479 87.7688 131.46 88.2799L131.46 88.2935C131.43 88.8126 131.004 89.2719 130.117 89.6425C128.149 90.4672 126.226 89.9255 124.47 89.2197C122.838 88.5586 121.653 87.8579 120.696 88.2573C120.1 88.5083 120.272 88.9031 120.925 89.321C121.966 89.9884 123.115 89.9406 123.744 89.7658L125.606 91.6814C125.252 91.767 124.898 91.8208 124.555 91.853C123.929 91.907 123.315 91.8762 122.726 91.7841C121.68 91.6207 120.709 91.2642 119.878 90.8461L118.38 91.4749L116.357 90.2301L116.736 90.0681Z"
        fill="white"
      />
      <path
        d="M130.117 89.6476C129.385 89.9276 128.583 90.0601 127.774 90.0124C126.964 89.9739 126.159 89.7751 125.381 89.506C124.598 89.2476 123.844 88.9027 123.076 88.5871C122.69 88.4328 122.299 88.2845 121.893 88.1923C121.488 88.1055 121.056 88.1016 120.696 88.2624C121.066 88.119 121.489 88.1437 121.883 88.2457C122.28 88.3472 122.664 88.5025 123.044 88.6658C123.802 88.996 124.561 89.3422 125.348 89.6112C126.135 89.874 126.954 90.0779 127.777 90.0984C128.187 90.1087 128.595 90.0833 128.99 90.0072C129.382 89.9262 129.759 89.8025 130.117 89.6476Z"
        fill="white"
      />
      <path
        d="M118.379 91.5116L118.378 93.2762L119.876 92.6475L119.877 90.8828L118.379 91.5116Z"
        fill="#F3F5F6"
      />
      <path
        opacity="0.35"
        d="M118.379 91.5116L118.378 93.2762L119.876 92.6475L119.877 90.8828L118.379 91.5116Z"
        fill="#C1CFCE"
      />
      <path
        d="M116.357 90.2422L118.373 91.2892L119.878 90.8582L118.38 91.487L116.357 90.2422Z"
        fill="white"
      />
      <path
        d="M128.39 85.3509L129.878 84.9095L131.886 86.0027L129.887 84.7266L128.39 85.3509Z"
        fill="white"
      />
      <path
        opacity="0.15"
        d="M123.023 84.5255C123.332 84.4663 123.647 84.4339 123.963 84.4141L123.717 85.2503L123.023 84.5255Z"
        fill="white"
      />
    </svg>
  );
};

import React from 'react';

export const CornerPanelIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16.767L5.14706 15.0764C5.0559 15.0223 5 14.9242 5 14.8183L5 4.45513C5 4.22267 5.25296 4.07854 5.45294 4.19704L8 5.70641M8 16.767L14.8324 13.4067C14.935 13.3562 15 13.2518 15 13.1375V2.74554C15 2.52338 14.7669 2.37829 14.5676 2.47633L8 5.70641M8 16.767V5.70641"
        stroke="#414042"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useMemo } from 'react';
import { UserBuildingPanel } from '@/models';
import ColorsIcon from '@/shared/icons/ColorsIcon';
import PickerPopover from '@/shared/elements/PickerPopover/PickerPopover';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import MaterialIcon from '@/shared/icons/MaterialIcon';
import { IntusLoader, IntusSelect, IntusTooltip } from '@/shared/elements';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { CornerPanelIcon } from '@/shared/icons/CornerPanelIcon';
import { StraightPanelIcon } from '@/shared/icons/StraightPanelIcon';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { calculateCommonArea } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { DockRow } from '@/shared/icons/DockRow';
import { NoColorIcon } from '@/shared/icons/NoColorIcon';
import { useUpdatePanelsMutation } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';

interface UpdatedUserBuildingPanelData extends UserBuildingPanel {
  isInitialCorner?: boolean;
  guid: string;
}

interface PanelFrameProps {
  selectedPanels: UpdatedUserBuildingPanelData[];
  isImperialUnits: boolean;
  areSettingsDisabled: boolean;
}

const MULTIPLE_COLORS_ID = 'multipleColors';
const MULTIPLE_MATERIALS_ID = 'multipleMaterials';

const PanelFrame: React.FC<PanelFrameProps> = ({
  selectedPanels,
  isImperialUnits,
  areSettingsDisabled,
}) => {
  const { id } = useParams();
  const { getFormattedValue, getAreaUnit } = useFrameProperties();
  const [updatePanels] = useUpdatePanelsMutation();
  const IGUData = useFetchIguQuery().data!;
  const configData = useFetchWindowConfigQuery().data!;
  const isAllPanelsSameType = selectedPanels.every(
    (panel) => panel.isCorner === selectedPanels[0].isCorner
  );
  const getPanelFrameName = () => {
    if (selectedPanels.length > 1) {
      return `Panels (${selectedPanels.length})`;
    }
    return 'Panel';
  };

  const getPanelIcon = () => {
    if (!isAllPanelsSameType) return null;
    return selectedPanels[0].isCorner ? (
      <CornerPanelIcon />
    ) : (
      <StraightPanelIcon />
    );
  };

  const getTooltipTitle = () => {
    if (!isAllPanelsSameType) return null;

    return selectedPanels[0].isCorner ? 'Corner Panel' : 'Straight Panel';
  };

  const getWingsWidth = (
    panel: UpdatedUserBuildingPanelData,
    wing: 'left' | 'right'
  ): number => {
    if (panel.isInitialCorner === undefined) return panel.width;

    if (wing === 'left' && panel.isInitialCorner)
      return panel.sideWidth ?? panel.width;

    if (wing === 'right' && panel.isInitialCorner) return panel.width;

    if (wing === 'left' && !panel.isInitialCorner)
      return panel.sideWidth ?? panel.width;

    if (wing === 'right' && !panel.isInitialCorner) return panel.width;

    return panel.width;
  };

  const getCornerMetrics = () => {
    return [
      {
        name: 'Right wing width',
        value: getFormattedValue(
          selectedPanels.map((panel) =>
            getWingsWidth(panel, 'right').toString()
          )
        ),
        isDisabled: false,
        isEditable: false,
      },
      {
        name: 'Left wing width',
        value: getFormattedValue(
          selectedPanels.map((panel) => getWingsWidth(panel, 'left').toString())
        ),
        isDisabled: false,
        isEditable: false,
      },
    ];
  };

  const getStraightMetrics = () => {
    return [
      {
        name: 'Width',
        value: getFormattedValue(
          selectedPanels.map((panel) => panel.width.toString())
        ),
        isDisabled: false,
        isEditable: false,
      },
    ];
  };

  const getPanelsMetrics = () => {
    if (selectedPanels.length > 1) return [];
    return selectedPanels[0].isCorner
      ? getCornerMetrics()
      : getStraightMetrics();
  };

  const getAvailableColors = (multipleColors?: boolean) => {
    const colorsData = configData.colors.map((color) => ({
      id: color.name,
      name: color.description,
      preview: (
        <div
          className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
          style={{
            backgroundColor: getHexByName(configData.colors, color.name),
          }}
        />
      ),
    }));

    multipleColors &&
      colorsData.push({
        id: MULTIPLE_COLORS_ID,
        name: 'Multiple colors',
        preview: (
          <div
            className={`w-6 h-6 box-border rounded-full`}
            style={{
              backgroundColor: '#fff',
            }}
          >
            <NoColorIcon />
          </div>
        ),
      });
    return colorsData;
  };

  const getAvailableMaterials = (multipleMaterials?: boolean) => {
    const materials = configData.panelMaterials.map((material) => ({
      name: material.description,
      id: material.name,
      description: material.additionalInfo,
      preview: (
        <img src={material.image} width={32} height={32} alt="material" />
      ),
    }));

    multipleMaterials &&
      materials.push({
        id: MULTIPLE_MATERIALS_ID,
        name: 'Multiple materials',
        description: 'Multiple materials',
        preview: (
          <div
            className={`w-6 h-6 box-border rounded-full`}
            style={{
              backgroundColor: '#fff',
            }}
          >
            <NoColorIcon />
          </div>
        ),
      });

    return materials;
  };

  const isPanelsHasDifferentOutsideColors = () =>
    !selectedPanels.every(
      (panel) =>
        panel.fillerOutsideColor === selectedPanels[0].fillerOutsideColor
    );

  const isPanelsHasDifferentInsideColors = () =>
    !selectedPanels.every(
      (panel) => panel.fillerInsideColor === selectedPanels[0].fillerInsideColor
    );

  const isPanelsHasDifferentMaterials = () =>
    !selectedPanels.every(
      (panel) => panel.fillerMaterial === selectedPanels[0].fillerMaterial
    );

  const showIGUSelect = () =>
    selectedPanels.some((panel) =>
      panel.fillerMaterial.toLowerCase().includes('glass')
    );

  const hasPanelDifferentGlassMaterial = !selectedPanels.every(
    (panel) => panel.fillerMaterial === selectedPanels[0].fillerMaterial
  );
  const hasPanelDifferentIgu = !selectedPanels.every(
    (panel) => panel.panelIguId === selectedPanels[0].panelIguId
  );

  const IGUOptions = useMemo(
    () =>
      IGUData.filter((val) =>
        selectedPanels[0].fillerMaterial === 'Glass'
          ? !val.name.includes('_EN')
          : val.name.includes('_EN')
      ).map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [selectedPanels]
  );

  const handleChangeInsideColor = (color: string) => {
    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelInnerColor: color,
      },
    });
  };
  const handleChangeOutsideColor = (color: string) => {
    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelOuterColor: color,
      },
    });
  };

  const handleChangeIgu = (iguId: number) => {
    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelIguId: iguId,
      },
    });
  };
  const handleChangeMaterial = (material: string) => {
    let newIguId;

    if (material.toLowerCase().includes('glass')) {
      newIguId = IGUData.filter((val) =>
        material === 'Glass'
          ? !val.name.includes('_EN')
          : val.name.includes('_EN')
      )[0]?.id;
    }

    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelMaterial: material,
        panelIguId: newIguId,
      },
    });
  };
  return (
    <IntusLoader loading={false}>
      <div className="text-xs">
        <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
          <div className="whitespace-nowrap text-ellipsis overflow-hidden w-[158px]">
            {getPanelFrameName()}
          </div>
          {selectedPanels.length === 1 && (
            <IntusTooltip
              title={getTooltipTitle()}
              placement="left"
              overlayStyle={{ marginLeft: '4px' }}
              zIndex={50}
            >
              <div className="flex items-center">{getPanelIcon()}</div>
            </IntusTooltip>
          )}
        </div>
        <div
          className={
            'flex flex-col border border-l-0 border-solid border-light-gray-20  overflow-y-auto text-dark-gray-100'
          }
        >
          <div
            className={
              'flex flex-col text-xs border border-l-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
            }
          >
            <MetricsHeader />
            <PropertyList
              alignValueLeft
              properties={[
                {
                  name: 'Height',
                  value: getFormattedValue(
                    selectedPanels.map((panel) => panel.height.toString())
                  ),
                  isDisabled: false,
                  isEditable: false,
                },
                ...getPanelsMetrics(),
                {
                  name: 'Area',
                  value: calculateCommonArea({
                    nodes: selectedPanels,
                    isImperialUnits,
                    calculateAreaFunction: (panel) =>
                      convertMillimetersToMeters(panel.width) *
                      convertMillimetersToMeters(panel.height),
                  }),

                  isDisabled: false,
                  isEditable: false,
                  units: getAreaUnit(),
                },
              ]}
            />
          </div>
        </div>
        <div className="text-dark-gray-100 pb-3 border-0 border-b border-solid border-light-gray-20 border-box">
          <div className="flex gap-1 mb-2 px-3 pt-3">
            <ColorsIcon />
            <div className="font-medium leading-5">COLORS</div>
          </div>
          <div className="font-light leading-5 mb-1 px-3">Outside color</div>
          <PickerPopover
            items={getAvailableColors(isPanelsHasDifferentOutsideColors())}
            onChange={handleChangeOutsideColor}
            initialValue={
              isPanelsHasDifferentOutsideColors()
                ? MULTIPLE_COLORS_ID
                : selectedPanels[0].fillerOutsideColor
            }
            disabled={areSettingsDisabled}
            contentHeight={184}
            contentWidth={210}
          />
          <div className="font-light leading-5 my-1 px-3">Inside color</div>
          <PickerPopover
            items={getAvailableColors(isPanelsHasDifferentInsideColors())}
            initialValue={
              isPanelsHasDifferentInsideColors()
                ? MULTIPLE_COLORS_ID
                : selectedPanels[0].fillerInsideColor
            }
            onChange={handleChangeInsideColor}
            disabled={areSettingsDisabled}
            contentHeight={184}
            contentWidth={210}
          />
        </div>
        <div className="flex flex-col py-3 gap-2 border-0 border-b border-solid border-light-gray-20 mb-1">
          <div className="flex gap-1 px-3">
            <MaterialIcon />
            <span className="font-medium leading-5">MATERIAL</span>
          </div>
          <PickerPopover
            items={getAvailableMaterials(isPanelsHasDifferentMaterials())}
            initialValue={
              isPanelsHasDifferentMaterials()
                ? MULTIPLE_MATERIALS_ID
                : selectedPanels[0].fillerMaterial
            }
            onChange={handleChangeMaterial}
            searchable={false}
            disabled={areSettingsDisabled}
            contentWidth={226}
          />
          {showIGUSelect() && (
            <div className="px-3">
              <div className="font-light leading-5 my-1">IGU configuration</div>
              <IntusSelect
                rootClassName="intus-select-fulltext w-full"
                dropdownStyle={{ borderRadius: 0! }}
                placement={'topRight'}
                dropdownAlign={{ offset: [-220, 40] }}
                options={IGUOptions}
                disabled={hasPanelDifferentGlassMaterial}
                placeholder="Select an IGU"
                showSearch
                value={
                  hasPanelDifferentGlassMaterial || hasPanelDifferentIgu
                    ? null
                    : selectedPanels[0].panelIguId
                }
                onChange={handleChangeIgu}
                filterOption={(input, option) =>
                  String(option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </div>
          )}
        </div>
        <div className="text-dark-gray-100 pb-3 border-0 border-b border-solid border-light-gray-20 border-box">
          <div className="flex gap-1 mb-2 px-3 pt-3">
            <DockRow />
            <div className="font-medium leading-5">WINDOWS</div>
          </div>
          <PropertyList
            alignValueLeft
            properties={[
              {
                name: 'Count',
                value: selectedPanels
                  .reduce((sum, panel) => {
                    return sum + panel.panelWindows.length;
                  }, 0)
                  .toString(),
                isDisabled: false,
                isEditable: false,
              },
            ]}
          />
        </div>
      </div>
    </IntusLoader>
  );
};

export default PanelFrame;

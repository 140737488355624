import React from 'react';

const UncoloredFacade = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 17H3" stroke="#414042" strokeLinecap="round" />
      <path
        d="M16.3001 17V5.8C16.3001 4.4798 16.3001 3.8204 15.8899 3.4102C15.4797 3 14.8203 3 13.5001 3H12.1001C10.7799 3 10.1205 3 9.71026 3.4102C9.37986 3.7399 9.31546 4.232 9.30286 5.1"
        stroke="#414042"
      />
      <path d="M6.64001 4.96191V16.7219" stroke="#414042" />
      <path d="M9.16003 4.96191V16.7219" stroke="#414042" />
      <path
        d="M12.1 16.9996V7.89961C12.1 6.57941 12.1 5.92001 11.6898 5.50981C11.2796 5.09961 10.6202 5.09961 9.29995 5.09961H6.49995C5.17975 5.09961 4.52035 5.09961 4.11015 5.50981C3.69995 5.92001 3.69995 6.57941 3.69995 7.89961V16.9996"
        stroke="#414042"
      />
      <path
        d="M3.28003 8.32129H11.68M11.68 11.2613H3.28003M11.68 14.2013H3.28003"
        stroke="#414042"
      />
    </svg>
  );
};

export default UncoloredFacade;

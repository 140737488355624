import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  IntusButton,
  IntusIconButton,
  IntusInput,
  IntusLoader,
  IntusTooltip,
} from '@/shared/elements';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setFacadeDesignerMode } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import AutoGridPlacementIcon from '@/shared/icons/AutoGridPlacementIcon';
import IntusPopover from '@/shared/elements/Popover/Popover';
import { Form, Radio } from 'antd';
import IntusRadio from '@/shared/elements/Radio/IntusRadio';
import { useParams } from 'react-router';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { CornerGridAlign, SelectedNode, UnitSystemTypes } from '@/models';
import {
  convertFtInchToMillimeters,
  convertMillimetersToFtInch,
} from '@/shared/helpers/distance';
import {
  addSpacesToThousands,
  generateInputChangeHandlerForMetric,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import { limitsValidator } from '@/shared/form/validators';
import { useGenerateGridLinesMutation } from '@/store/apis/projectsApi';
import { useWatch } from 'antd/es/form/Form';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import {
  DEFAULT_PANEL_WIDTH,
  MAX_PANEL_WIDTH,
  MIN_PANEL_WIDTH,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';

interface AutoGridPlacementPopoverProps {
  iconFillColor: string;
  facadeDesignerMode: FacadeDesignerModes;
  selectedWalls: SelectedNode[];
  disabled: boolean;
}

const AutoGridPlacementPopover: React.FC<AutoGridPlacementPopoverProps> = ({
  iconFillColor,
  facadeDesignerMode,
  selectedWalls,
  disabled,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [generateGridLines] = useGenerateGridLinesMutation();

  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const { facadesData } = useFacadeData();
  const { findDataForWall } = useFindNodeData();
  const buildingFacadesData = facadesData.map((facadeGroup) => ({
    wallGuids: facadeGroup.map((wall) => wall.guid),
  }));
  const isAutoGridLinePlacementMode =
    facadeDesignerMode === FacadeDesignerModes.AutoGridLinePlacement;

  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });

  const wallsData: WallSearchResults[] = useMemo(() => {
    return selectedWalls.map((wall) => findDataForWall(wall.guid)!);
  }, [selectedWalls]);

  const maxPanelCountValue: number = useMemo(() => {
    const availableWallWidths = wallsData.map((wallData) => {
      const wallWidth = Number(
        removeSpacesFromThousands(getWallWidthMetric([wallData]), false)
      );
      const totalCornerOffsets = wallData.gridLines.reduce((total, line) => {
        if (line.cornerAlign === CornerGridAlign.Left) {
          return total + line.offsetFromLeftEdge;
        }
        if (line.cornerAlign === CornerGridAlign.Right) {
          return total + (wallWidth - line.offsetFromLeftEdge);
        }
        return total;
      }, 0);

      return wallWidth - totalCornerOffsets;
    });

    const maxAvailableWidth = Math.max(...availableWallWidths);

    return Math.floor(maxAvailableWidth / MIN_PANEL_WIDTH);
  }, [selectedWalls]);

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useWatch('divideLengthBy', form);
  useWatch('panelCountValue', form);

  const handlePopoverOpenChange = (isOpen: boolean) => {
    setIsTooltipVisible(false);
    if (!isOpen) {
      dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));
      form.resetFields();
    }
  };

  const initialPanelCountValue = 2;
  const initialPanelWidthValue = addSpacesToThousands(
    isImperialUnits
      ? convertMillimetersToFtInch(DEFAULT_PANEL_WIDTH)
      : DEFAULT_PANEL_WIDTH.toString(),
    isImperialUnits
  );

  const panelCountValidationMessage = useMemo(() => {
    const panelCountValue = `${form.getFieldValue('panelCountValue') ?? initialPanelCountValue}`;

    return Number(removeSpacesFromThousands(panelCountValue, isImperialUnits)) >
      initialPanelCountValue
      ? `Max ${maxPanelCountValue}`
      : `Min ${initialPanelCountValue}`;
  }, [form.getFieldValue('panelCountValue')]);

  const tooltipTitle = (
    <span className="leading-4 font-medium text-[11px]">
      Auto grid line placement
    </span>
  );

  const popoverTitle = (
    <div className="font-medium leading-4 px-3 py-2 box-content">
      Auto grid line placement
    </div>
  );

  useEffect(() => {
    form.setFieldsValue({
      placementArea: 'selectedArea',
      divideLengthBy: 'panelWidth',
      panelWidthValue: initialPanelWidthValue,
      panelCountValue: initialPanelCountValue,
    });
  }, [facadeDesignerMode, form]);

  const onFinish = async () => {
    try {
      setIsLoading(true);
      await form.validateFields();
      const facades =
        form.getFieldValue('placementArea') === 'selectedArea'
          ? [{ wallGuids: selectedWalls.map((wall) => wall.guid) }]
          : buildingFacadesData;
      let panelWidth;
      let panelCount;

      if (form.getFieldValue('divideLengthBy') === 'panelWidth') {
        const truncatedPanelWidth = removeSpacesFromThousands(
          form.getFieldValue('panelWidthValue'),
          isImperialUnits
        );
        panelWidth = isImperialUnits
          ? Number(convertFtInchToMillimeters(truncatedPanelWidth))
          : Number(truncatedPanelWidth);
      } else {
        panelCount = Number(
          removeSpacesFromThousands(
            form.getFieldValue('panelCountValue'),
            isImperialUnits
          )
        );
      }
      await generateGridLines({
        projectId: Number(id),
        data: {
          facades,
          ...(panelWidth && { panelWidth }),
          ...(panelCount && { panelCount }),
        },
      });
      dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const checkValidationErrors = () => {
    form
      .validateFields()
      .then(() => setHasErrors(false))
      .catch(() => setHasErrors(true));
  };

  const handlePlacementAreaChange = () => {
    form.setFieldsValue({
      panelWidthValue: addSpacesToThousands(
        isImperialUnits
          ? convertMillimetersToFtInch(DEFAULT_PANEL_WIDTH)
          : DEFAULT_PANEL_WIDTH.toString(),
        isImperialUnits
      ),
      panelCountValue: initialPanelCountValue.toString(),
    });
  };

  const divRef = useRef<HTMLDivElement>(null);

  const popoverContent = (
    <IntusLoader loading={isLoading}>
      <Form form={form} onFinish={onFinish} onChange={checkValidationErrors}>
        <div className="min-h-[230px] min-w-[270px] w-60 text-xs" ref={divRef}>
          <div className="p-3">
            <div className="leading-5 mb-2">Choose the placement area</div>
            <Form.Item name="placementArea" noStyle>
              <Radio.Group className="flex flex-col gap-1 mb-3">
                <IntusRadio value="selectedArea">
                  <span className="leading-5 font-light text-xs">
                    Selected area
                  </span>
                </IntusRadio>
                <IntusRadio value="fullBuilding">
                  <span className="leading-5 font-light text-xs">
                    Full building
                  </span>
                </IntusRadio>
              </Radio.Group>
            </Form.Item>
            <div className="leading-5 mb-2">
              Divide each wall&apos;s length by
            </div>
            <div className="flex justify-between ">
              <div className="flex-1">
                <Form.Item name="divideLengthBy" noStyle>
                  <Radio.Group
                    onChange={handlePlacementAreaChange}
                    className="flex flex-col gap-2 py-0.5"
                  >
                    <IntusRadio value="panelWidth">
                      <span className="leading-5 font-light text-xs">
                        Panel width{' '}
                      </span>
                    </IntusRadio>
                    <IntusRadio value="panelCount">
                      <span className="leading-5 font-light text-xs">
                        Panel count
                      </span>
                    </IntusRadio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="flex-1">
                <div className="min-h-6  mb-1">
                  {form.getFieldValue('divideLengthBy') === 'panelWidth' && (
                    <Form.Item
                      name="panelWidthValue"
                      className="mb-0"
                      rules={[
                        limitsValidator({
                          min: MIN_PANEL_WIDTH,
                          max: MAX_PANEL_WIDTH,
                          isImperialUnits,
                          staticValue: false,
                          decimalPlaces: 2,
                        }),
                      ]}
                    >
                      <IntusInput
                        size="small"
                        autoComplete="off"
                        onChange={generateInputChangeHandlerForMetric({
                          name: 'panelWidthValue',
                          form,
                          isImperialUnits,
                          staticValue: false,
                        })}
                      />
                    </Form.Item>
                  )}
                </div>
                <div>
                  {form.getFieldValue('divideLengthBy') === 'panelCount' && (
                    <Form.Item
                      name="panelCountValue"
                      className="mb-0"
                      rules={[
                        limitsValidator({
                          min: initialPanelCountValue,
                          max: maxPanelCountValue,
                          isImperialUnits,
                          staticValue: true,
                          customValidationMessage: panelCountValidationMessage,
                        }),
                      ]}
                    >
                      <IntusInput
                        size="small"
                        autoComplete="off"
                        onChange={generateInputChangeHandlerForMetric({
                          name: 'panelCountValue',
                          form,
                          isImperialUnits,
                          staticValue: true,
                        })}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pb-3 flex justify-between gap-3">
            <IntusButton
              onClick={() =>
                dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection))
              }
              type="text"
              block
              id="facade-designer__autoGridLinePlacement-cancel-button"
            >
              Cancel
            </IntusButton>
            <IntusButton
              disabled={hasErrors}
              className="rounded-lg"
              htmlType="submit"
              block
              id="facade-designer__autoGridLinePlacement-button"
            >
              Place grids
            </IntusButton>
          </div>
        </div>
      </Form>
    </IntusLoader>
  );

  return (
    <IntusPopover
      placement="bottom"
      trigger={'click'}
      arrow={false}
      title={popoverTitle}
      content={popoverContent}
      open={isAutoGridLinePlacementMode}
      onOpenChange={handlePopoverOpenChange}
      destroyTooltipOnHide
    >
      <IntusTooltip
        open={isTooltipVisible}
        title={tooltipTitle}
        placement="right"
      >
        <IntusIconButton
          disabled={disabled}
          type="default"
          className={'mx-1'}
          onClick={() =>
            dispatch(
              setFacadeDesignerMode(FacadeDesignerModes.AutoGridLinePlacement)
            )
          }
          icon={<AutoGridPlacementIcon fill={iconFillColor} />}
          isActive={
            FacadeDesignerModes.AutoGridLinePlacement === facadeDesignerMode
          }
          id="facade-designer__autoGridLinePlacement-button"
          onMouseEnter={() => {
            selectedWalls.length > 0 &&
              !isAutoGridLinePlacementMode &&
              setIsTooltipVisible(true);
          }}
          onMouseLeave={() => {
            selectedWalls.length > 0 &&
              !isAutoGridLinePlacementMode &&
              setIsTooltipVisible(false);
          }}
        />
      </IntusTooltip>
    </IntusPopover>
  );
};

export default AutoGridPlacementPopover;

export const REPORT_WIDTH = 914;
export const REPORT_HEIGHT = 610;
export const REPORT_SIDEBAR_WIDTH = 215;

export enum ReportStatus {
  DEFAULT = 'default',
  PROCESSING_AI_GENERATION = 'processing_ai_generation',
  PROCESSING_DATA_COLLECTION = 'processing_data_collection',
  PROCESSING_CREATE_REPORT = 'processing_create_report',
  READY = 'ready',
  ERROR = 'error',
}

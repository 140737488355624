import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '@/store/hooks';
import { getGridPlacementAbsoluteOffset } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import FacadeDesignerGridLine from '@/components/FacadeDesigner/elements/FacadeDesignerGridLine';
import { inRange } from 'lodash';
import { UnitSystemTypes } from '@/models';
import { useFDElementValidation } from '@/components/FacadeDesigner/hooks/useFDElementValidation';
import { round } from 'mathjs';

interface FacadeDesignerGridPlacementProps {
  scale: number;
  wallWidth: number;
  wallHeight: number;
  wallData: WallSearchResults;
  xWallOffset: number;
  unitSystem: UnitSystemTypes;
}
const FacadeDesignerGridPlacement = ({
  scale,
  wallHeight,
  wallWidth,
  wallData,
  xWallOffset,
  unitSystem,
}: FacadeDesignerGridPlacementProps) => {
  const gridPlacementAbsoluteOffset = useAppSelector(
    getGridPlacementAbsoluteOffset
  );

  const offsetFromLeftEdge = useMemo(
    () =>
      gridPlacementAbsoluteOffset
        ? round(gridPlacementAbsoluteOffset - xWallOffset, 2)
        : -1,
    [gridPlacementAbsoluteOffset, xWallOffset]
  );

  const { anyWallHasPlacementError, validateGridPlacement } =
    useFDElementValidation(wallData, wallWidth, offsetFromLeftEdge, []);

  useEffect(() => {
    validateGridPlacement();
  }, [offsetFromLeftEdge]);

  return (
    <>
      {!!gridPlacementAbsoluteOffset &&
        inRange(
          gridPlacementAbsoluteOffset,
          xWallOffset,
          xWallOffset + wallWidth
        ) && (
          <FacadeDesignerGridLine
            hasError={anyWallHasPlacementError}
            gridPlacement={true}
            gridLine={{
              offsetFromLeftEdge,
              guid: '',
              cornerAlign: null,
            }}
            scale={scale}
            wallHeight={wallHeight}
            wallWidth={wallWidth}
            unitSystem={unitSystem}
            wallGUID={wallData.guid}
          />
        )}
    </>
  );
};

export default FacadeDesignerGridPlacement;

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  GENERATE_CAMERA_SNAPSHOTS,
  GENERATE_CAMERA_SNAPSHOTS_SUCCESS,
} from '@/core/event-names';
import { publish, subscribe, unsubscribe } from '@/core/events';
import { Background, Filter, HIDDEN_LAYER, Side } from '@/models/camera.model';
import { findObjectByGuid } from '@/shared/helpers/canvas';
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import {
  Box3,
  Box3Helper,
  Camera,
  Color,
  Mesh,
  Object3D,
  OrthographicCamera,
  Plane,
  PlaneGeometry,
  Ray,
  Vector3,
  WebGLRenderer,
} from 'three';

const CameraSnapshot = () => {
  const { scene, gl, size } = useThree();
  const aspect = window.innerWidth / window.innerHeight;
  const frustumSize = 10;

  const generateCameraSnapshots = ({
    filters,
    processId,
  }: {
    filters: Filter[];
    processId: string;
  }) => {
    if (filters.length) {
      const cameras = createCamerasByFilters(filters);
      const urls: string[] = [];

      cameras.forEach((camera, index) => {
        const renderTarget = findObjectByGuid(scene, filters[index].targetGUID);

        if (!renderTarget) return;

        const guides = filters[index].includeEnvironment || [];
        setLayerVisibility(
          [...guides, filters[index].targetGUID],
          HIDDEN_LAYER,
          true
        );

        const updatedCamera = updateCameraRelativeTarget(
          camera,
          renderTarget,
          filters[index].side,
          filters[index].position
        );
        const imageUrl = captureImage(
          gl,
          updatedCamera,
          filters[index].background
        );

        if (imageUrl) {
          urls.push(imageUrl);
        }

        setLayerVisibility(
          [...guides, filters[index].targetGUID],
          HIDDEN_LAYER,
          false
        );
      });

      publish(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, { urls, processId });
    }
  };

  useEffect(() => {
    subscribe(GENERATE_CAMERA_SNAPSHOTS, (evt) =>
      generateCameraSnapshots(evt.detail)
    );

    return () => {
      unsubscribe(GENERATE_CAMERA_SNAPSHOTS, (evt) =>
        generateCameraSnapshots(evt.detail)
      );
    };
  }, []);

  const setLayerVisibility = (
    guides: string[],
    layer: number,
    isEnabled: boolean
  ) => {
    guides.forEach((guid) => {
      const renderTarget = findObjectByGuid(scene, guid);
      if (renderTarget) {
        (renderTarget as Object3D).traverse((child) => {
          isEnabled ? child.layers.enable(layer) : child.layers.disable(layer);
        });
      }
    });
  };

  const updateCameraRelativeTarget = (
    camera: OrthographicCamera,
    target: Object3D,
    side: Side,
    position: Vector3 | undefined
  ) => {
    const box = new Box3().setFromObject(target);
    const center = box.getCenter(new Vector3());
    const size = box.getSize(new Vector3());
    const positionCamera = position ?? getPositionCamera(side, center, size);

    camera.position.set(
      positionCamera.x,
      positionCamera.y + 0.1,
      positionCamera.z
    );

    const vertices = [
      new Vector3(box.min.x, box.max.y, box.min.z),
      new Vector3(box.max.x, box.max.y, box.min.z),
      new Vector3(box.min.x, box.max.y, box.max.z),
      new Vector3(box.max.x, box.max.y, box.max.z),
      new Vector3(box.min.x, box.min.y, box.min.z),
      new Vector3(box.max.x, box.min.y, box.min.z),
      new Vector3(box.min.x, box.min.y, box.max.z),
      new Vector3(box.max.x, box.min.y, box.max.z),
    ];

    camera.lookAt(center);
    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();
    camera.matrixWorldInverse.copy(camera.matrixWorld).invert();

    const cameraPlaneGeometry = new PlaneGeometry(10, 5);
    const cameraPlane = new Mesh(cameraPlaneGeometry);

    cameraPlane.position.copy(camera.position);
    cameraPlane.quaternion.copy(camera.quaternion);

    const cameraPlaneNormal = new Vector3(0, 0, 1)
      .applyQuaternion(cameraPlane.quaternion)
      .normalize();
    const cameraPlanePosition = cameraPlane.position.clone();
    const physicalCameraPlane = new Plane().setFromNormalAndCoplanarPoint(
      cameraPlaneNormal,
      cameraPlanePosition
    );

    const intersectedPoints: Vector3[] = [];
    vertices.forEach((vertex) => {
      const origin = vertex;
      const direction = new Vector3(0, 0, 1);
      const rotatedDirection = direction.applyQuaternion(camera.quaternion);

      const ray = new Ray(origin, rotatedDirection);
      const intersectionPoint = new Vector3();

      ray.intersectPlane(physicalCameraPlane, intersectionPoint) &&
        intersectedPoints.push(intersectionPoint);
    });

    const convertedWorldPoints = intersectedPoints.map((point) =>
      point.clone().applyMatrix4(camera.matrixWorldInverse)
    );

    const maxY = Math.max(...convertedWorldPoints.map((point) => point.y));
    const minY = Math.min(...convertedWorldPoints.map((point) => point.y));
    const maxX = Math.max(...convertedWorldPoints.map((point) => point.x));
    const minX = Math.min(...convertedWorldPoints.map((point) => point.x));

    const widthFrustum = maxX - minX;
    const heightFrustum = maxY - minY;

    const { left, right, top, bottom } = calculateFrustum(
      widthFrustum,
      heightFrustum,
      side
    );

    camera.left = left;
    camera.right = right;
    camera.top = top;
    camera.bottom = bottom;

    camera.updateProjectionMatrix();

    return camera;
  };

  const calculateFrustum = (
    widthFrustum: number,
    heightFrustum: number,
    side: Side
  ) => {
    const halfWidth = widthFrustum / 2;
    const halfHeight = heightFrustum / 2;

    if (side === Side.TOP) {
      const visibleFrustum = Math.max(halfWidth, halfHeight);

      return {
        left: -visibleFrustum,
        right: visibleFrustum,
        top: visibleFrustum,
        bottom: -visibleFrustum,
      };
    }

    if (widthFrustum / heightFrustum > aspect) {
      return {
        left: -halfWidth,
        right: halfWidth,
        top: halfWidth / aspect,
        bottom: -halfWidth / aspect,
      };
    }

    return {
      left: -halfHeight * aspect,
      right: halfHeight * aspect,
      top: halfHeight,
      bottom: -halfHeight,
    };
  };

  const getPositionCamera = (
    side: Side,
    center: Vector3,
    size: Vector3
  ): Vector3 => {
    switch (side) {
      case Side.FRONT:
        return new Vector3(
          center.x + size.x / 2,
          center.y + size.y / 2,
          center.z + size.z / 2
        );
      case Side.LEFT:
        return new Vector3(
          center.x - size.x / 2,
          center.y + size.y / 2,
          center.z + size.z / 2
        );
      case Side.BACK:
        return new Vector3(
          center.x - size.x / 2,
          center.y + size.y / 2,
          center.z - size.z / 2
        );
      case Side.RIGHT:
        return new Vector3(
          center.x + size.x / 2,
          center.y + size.y / 2,
          center.z - size.z / 2
        );
      default:
        return center;
    }
  };

  const createCamerasByFilters = (filters: Filter[]): OrthographicCamera[] => {
    return filters.map(() => {
      const camera = new OrthographicCamera(
        (-frustumSize * aspect) / 2,
        (frustumSize * aspect) / 2,
        frustumSize / 2,
        -frustumSize / 2,
        0.001,
        20000
      );

      camera.layers.set(HIDDEN_LAYER);

      return camera;
    });
  };

  const captureImage = (
    renderer: WebGLRenderer,
    camera: Camera,
    background: Background | undefined
  ): string => {
    const originalClearColor = renderer.getClearColor(new Color());
    const originalAlpha = renderer.getClearAlpha();

    if (background) {
      renderer.setClearColor(background.color, background.alpha);
    }

    renderer.setSize(size.width, size.height);
    renderer.render(scene, camera);

    const dataURL = renderer.domElement.toDataURL('image/png');

    renderer.setClearColor(originalClearColor, originalAlpha);

    return dataURL;
  };

  return null;
};

export default CameraSnapshot;

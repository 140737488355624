import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CanvasCamera,
  ConstructionSiteDto,
  CreatePanelsDto,
  CreateUserBuildingData,
  EnvironmentObject,
  FetchProjectsResponse,
  ProjectCreateData,
  ProjectDeleteData,
  ProjectStructure,
  SavedCamera,
  SurroundingBuildingsDto,
  UpdatePanelsDto,
  UserBuildingBlockDto,
  UserBuildingCoordinatesObject,
  UserBuildingDto,
  UserBuildingPanel,
  UserBuildingPartDto,
  UserBuildingStoreyDto,
  UserBuildingWallDto,
  UserDataDto,
} from '@/models';
import { baseQuery } from '@/middlewares/http-interceptors';

const projectsApi = createApi({
  reducerPath: 'projects',
  keepUnusedDataFor: 10000,
  // TODO: Refactor 'PR' and 'Project'
  tagTypes: ['Project', 'PR', 'Panels'],
  baseQuery,
  endpoints: (build) => ({
    fetchProjects: build.query<FetchProjectsResponse, string>({
      providesTags: ['Project'],
      query: (searchValue: string) => ({
        url: `/projects?name=${searchValue}`,
        method: 'GET',
      }),
    }),
    createProject: build.mutation<ProjectStructure, ProjectCreateData>({
      query: (projectData) => ({
        url: '/projects',
        method: 'POST',
        body: projectData,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    fetchProject: build.query<ProjectStructure, string>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'GET',
      }),
      providesTags: (data) => {
        return [{ type: 'PR', id: data?.id }];
      },
    }),
    updateProject: build.mutation<
      ProjectStructure[],
      Partial<ProjectStructure>
    >({
      query: (projectData) => ({
        url: `/projects`,
        method: 'PUT',
        body: projectData,
      }),
      invalidatesTags: (result, error, { id }) =>
        error ? [] : ['Project', { type: 'PR', id }],
    }),
    deleteProject: build.mutation<ProjectStructure[], number>({
      query: (projectId: number) => ({
        url: `/projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),

    deleteConstruction: build.mutation<
      null,
      { projectId: number; data: ProjectDeleteData }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/delete`,
        method: 'put',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    createNewCamera: build.mutation<
      SavedCamera,
      //Project id required for proper camera storage in project
      { projectId: string; data: CanvasCamera }
    >({
      query: ({ data, projectId }) => ({
        url: `/projects/${projectId}/cameras`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateCameraProperties: build.mutation<
      null,
      //Project id required for proper camera storage in project
      { projectId: string; data: SavedCamera }
    >({
      query: ({ data }) => ({
        url: `/projects/cameras/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    createUserBuilding: build.mutation<
      UserBuildingCoordinatesObject,
      { projectId: string; data: CreateUserBuildingData }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/buildings`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingNode: build.mutation<
      null,
      {
        data: UserBuildingPartDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks/storeys/walls`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateProjectEnvironment: build.mutation<
      null,
      {
        data: UserDataDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/environment`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    restoreSurroundingBuildings: build.mutation<
      EnvironmentObject[],
      { surroundingBuildingsGuid: string }
    >({
      query: ({ surroundingBuildingsGuid }) => ({
        url: `/environment/surrounding/${surroundingBuildingsGuid}/restore`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateProjectConstructionSite: build.mutation<
      null,
      {
        data: ConstructionSiteDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/environment/constructionSite`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateProjectSurroundingBuildings: build.mutation<
      null,
      {
        data: SurroundingBuildingsDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/environment/surrounding`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingBlock: build.mutation<
      null,
      {
        data: UserBuildingBlockDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingStorey: build.mutation<
      null,
      {
        data: UserBuildingStoreyDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks/storeys`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingWall: build.mutation<
      null,
      {
        data: UserBuildingWallDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks/storeys/walls`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingData: build.mutation<
      null,
      {
        data: UserBuildingDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    generateGridLines: build.mutation<
      null,
      {
        projectId: number;
        data: {
          facades: {
            wallGuids: string[];
          }[];
          panelWidth?: number;
          panelCount?: number;
        };
      }
    >({
      query: ({ data, projectId }) => ({
        url: `/projects/${projectId}/panels/gridlines`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { projectId }) =>
        error ? [] : [{ type: 'PR', id: projectId }],
    }),
    getAllPanels: build.query<UserBuildingPanel[], string>({
      query: (id) => ({
        url: `/projects/${id}/panels`,
        method: 'GET',
      }),

      providesTags: ['Panels'],
    }),
    createPanels: build.mutation<
      null,
      {
        projectId: number;
        data: CreatePanelsDto;
      }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/panels`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
      onQueryStarted(arg, api): Promise<void> | void {
        api.queryFulfilled.then(() => {
          api.dispatch(
            projectsApi.util.invalidateTags([
              { type: 'Panels' },
              { type: 'PR', id: arg.projectId },
            ])
          );
        });
      },
    }),
    updatePanels: build.mutation<
      null,
      { projectId: string; data: UpdatePanelsDto }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/panels`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { projectId }) =>
        error ? [] : ['Project', 'Panels', { type: 'PR', id: projectId }],
    }),
    saveReport: build.mutation<
      null,
      {
        id: number;
        dateTime: string;
        data: FormData;
      }
    >({
      query: ({ id, data, dateTime }) => ({
        url: `/projects/${id}/reports/${dateTime}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    lazyUpdateProject: build.mutation<
      ProjectStructure[],
      Partial<ProjectStructure>
    >({
      query: (projectData) => ({
        url: `/projects`,
        method: 'PUT',
        body: projectData,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    duplicateProject: build.mutation<ProjectStructure, { projectId: number }>({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    undoBuildingDeletion: build.mutation<null, { projectId: number }>({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/delete/undo`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { projectId }) =>
        error ? [] : ['Project', { type: 'PR', id: projectId }],
    }),
  }),
});

export const {
  useFetchProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useDeleteConstructionMutation,
  useFetchProjectQuery,
  useCreateNewCameraMutation,
  useUpdateCameraPropertiesMutation,
  useCreateUserBuildingMutation,
  useUpdateUserBuildingNodeMutation,
  useUpdateProjectEnvironmentMutation,
  useUpdateProjectConstructionSiteMutation,
  useUpdateProjectSurroundingBuildingsMutation,
  useUpdateUserBuildingStoreyMutation,
  useUpdateUserBuildingWallMutation,
  useUpdateUserBuildingBlockMutation,
  useUpdateUserBuildingDataMutation,
  useRestoreSurroundingBuildingsMutation,
  useGenerateGridLinesMutation,
  useCreatePanelsMutation,
  useGetAllPanelsQuery,
  useUpdatePanelsMutation,
  useSaveReportMutation,
  useLazyFetchProjectQuery,
  useLazyUpdateProjectMutation,
  useDuplicateProjectMutation,
  useUndoBuildingDeletionMutation,
} = projectsApi;
export { projectsApi };

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Group, Layer, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import {
  FlatVector2,
  GridLineData,
  NodeType,
  SelectedNode,
  WindowPlacementData,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  FacadeDesignerPlacementType,
  getDragNode,
  getFacadeDesignerMode,
  getHoveredGridLine,
  getHoveredPlacedWindow,
  getHoveredWall,
  getMeasurementActiveWall,
  getModifiedWalls,
  getPlacementErrors,
  getSelectedGridlines,
  getSelectedPlacedWindows,
  resetPlacementErrors,
  resetSelectedGridLines,
  resetSelectedPlacedPanels,
  resetSelectedWindowFromLibrary,
  setDragNode,
  setGridPlacementAbsoluteOffset,
  setHoveredPlacedWindow,
  setSelectedGridLines,
  setSelectedPlacedWindow,
  setSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';

import FacadeDesignerWallView from '@/components/FacadeDesigner/elements/FacadeDesignerWallView';
import { getMinMaxCoordinatesAtVector3 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import { round } from 'mathjs';
import {
  convert2DPointsToDistanceInMeters,
  getWallHeight,
} from '@/shared/helpers/metrics';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import WallElevations from './elements/WallElevations';
import FacadeDesignerBackground from '@/components/FacadeDesigner/elements/FacadeDesignerBackground';

import { useGridLines } from '@/components/FacadeDesigner/hooks/useGridLines';
import { useParams } from 'react-router';
import GridLinesInformationMeasurements from './elements/GridLinesInformationMeasurements';
import WindowsInformationMeasurements from './elements/WindowsInformationMeasurements';
import { FACADE_VIEW } from '@/shared/constants';
import { useKey } from 'react-use';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import PanelViewReport from './elements/PanelViewReport';

interface FacadeDesignerContainerProps {
  buildingGUID: string;
  selectedWalls: SelectedNode[];
  placementHeight: number;
  placementWidth: number;
  isProjectLocked?: boolean;
  onKonvaInit?: (stage: Konva.Stage) => void;
  reportView?: boolean;
}

const FacadeDesignerContainer: React.FC<FacadeDesignerContainerProps> = ({
  buildingGUID,
  selectedWalls,
  placementHeight,
  placementWidth,
  isProjectLocked,
  onKonvaInit,
  reportView,
}) => {
  Konva.dragButtons = [2];
  const stageRef = useRef<Konva.Stage>(null!);
  const { id } = useParams();

  const windowsData = useFetchWindowsQuery(id!).data!;

  const dispatch = useAppDispatch();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const modifiedWalls = useAppSelector(getModifiedWalls);
  const { updateWallWindowPlacements, updateBuildingPlacements } =
    useUpdateUserBuildingData();
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const selectedPlacedGridLines = useAppSelector(getSelectedGridlines);
  const placementErrors = useAppSelector(getPlacementErrors);
  const dragNode = useAppSelector(getDragNode);
  const isGridLineDragActive =
    dragNode === FacadeDesignerPlacementType.GridLine;

  const hoveredWall = useAppSelector(getHoveredWall);
  const measurementActiveWall = useAppSelector(getMeasurementActiveWall);
  const hoveredWindow = useAppSelector(getHoveredPlacedWindow);
  const hoveredGridLine = useAppSelector(getHoveredGridLine);

  const timeoutForGrabRef = useRef<NodeJS.Timeout | null>(null);
  const grabbedWindowRef = useRef<WindowPlacementData | null>(null);
  const { findDataForWall } = useFindNodeData();
  const { generateCustomGrids } = useGridLines(buildingGUID);

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const allPanels = useGetAllPanelsQuery(id!).data ?? [];

  useEffect(() => {
    if (!isSelectionMode) {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    }
  }, [isSelectionMode]);

  const wallsData: WallSearchResults[] = useMemo(() => {
    return selectedWalls.map((wall) => findDataForWall(wall.guid)!);
  }, [selectedWalls]);

  const allPlacedWindows: WindowPlacementData[] = useMemo(() => {
    return wallsData.flatMap((wall) => wall.windowPlacements || []);
  }, [selectedWalls]);

  const minMaxWallsCoordinates = useMemo(
    () =>
      getMinMaxCoordinatesAtVector3(
        wallsData.map((wall) => convertFlatVector3ToVectors(wall.points)).flat()
      ),
    [wallsData]
  );

  const xDirection = wallsData[0].points[0][0] <= wallsData[0].points[1][0];
  const zDirection = wallsData[0].points[0][2] <= wallsData[0].points[1][2];

  const wallsTotalHeight = round(
    Number(
      convertMetersToMillimeters(
        (minMaxWallsCoordinates.max.y - minMaxWallsCoordinates.min.y) /
          multiplyRate
      )
    ),
    2
  );

  const wallsTotalWidth = round(
    Number(
      convertMetersToMillimeters(
        convert2DPointsToDistanceInMeters(
          [minMaxWallsCoordinates.max.x, minMaxWallsCoordinates.max.z],
          [minMaxWallsCoordinates.min.x, minMaxWallsCoordinates.min.z],
          multiplyRate
        )
      )
    ),
    2
  );

  const scaleX = (placementWidth * 0.68) / wallsTotalWidth;
  const scaleY = (placementHeight - 100) / wallsTotalHeight;
  const scale = Math.min(scaleX, scaleY);

  const { wheelHandler } = useKonvaHandlers();

  const [stageParams, setStageParams] = useState({
    scale,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    //  Should not be called when width/height updates by user. Else it
    setStageParams({
      scale,
      x: placementWidth / 2 - (wallsTotalWidth * scale) / 2,
      y: placementHeight / 2 - (wallsTotalHeight * scale) / 2,
    });
  }, [modifiedWalls]);

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    const scaleData = wheelHandler(event, {
      maxScale: 1,
      minScale: Math.min(0.01, scale),
    });

    scaleData && setStageParams(scaleData);
  };

  const handleGridPlacement = () => {
    generateCustomGrids({ wallsData, startPoint });
  };

  const handleSaveWindowPlacements = (
    placements: WindowPlacementData[],
    wallData: WallSearchResults
  ) => {
    updateWallWindowPlacements({
      buildingGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Building
      )!.guid,
      blockGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Block
      )!.guid,
      storeyGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Storey
      )!.guid,
      wallGUID: wallData.guid,
      windowPlacements: placements,
    });
  };

  const handleSaveWallPlacements = (
    newPlacements: {
      wallGUID: string;
      gridLines?: GridLineData[];
      windowPlacements?: WindowPlacementData[];
    }[]
  ) => {
    updateBuildingPlacements({
      buildingGUID,
      placementData: newPlacements,
    });
  };
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (
        facadeDesignerMode === FacadeDesignerModes.Selection &&
        !isProjectLocked &&
        (selectedPlacedWindows.length || selectedPlacedGridLines.length)
      ) {
        const newPlacements: {
          wallGUID: string;
          gridLines?: GridLineData[];
          windowPlacements?: WindowPlacementData[];
        }[] = modifiedWalls.map((wallGUID) => {
          const newGridLines = wallsData
            .find((data) => data.guid === wallGUID)
            ?.gridLines.filter(
              (line) =>
                line.cornerAlign ||
                !selectedPlacedGridLines.some(
                  (selectedGridLine) => selectedGridLine.guid === line.guid
                )
            );
          const newWindowPlacements = wallsData
            .find((data) => data.guid === wallGUID)
            ?.windowPlacements.filter(
              (window) =>
                !selectedPlacedWindows.some(
                  (selectedWindow) => selectedWindow.guid === window.guid
                )
            );
          return {
            wallGUID,
            windowPlacements: newWindowPlacements,
            gridLines: newGridLines,
          };
        });

        handleSaveWallPlacements(newPlacements);

        dispatch(setSelectedPlacedWindow([]));
        dispatch(setHoveredPlacedWindow(null));
        dispatch(resetSelectedGridLines());
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [selectedPlacedWindows, wallsData, selectedPlacedGridLines]);

  useEffect(() => {
    stageRef.current && onKonvaInit && onKonvaInit(stageRef.current);
  }, [stageRef.current]);

  const startPoint = {
    x: minMaxWallsCoordinates[xDirection ? 'max' : 'min'].x,
    z: minMaxWallsCoordinates[zDirection ? 'max' : 'min'].z,
  };

  const distanceToRightEdge = useMemo(() => {
    return Math.max(
      ...wallsData.map((wall) => {
        const wallBottomRightPoint: FlatVector2 = [
          wall.points[3][0],
          wall.points[3][2],
        ];
        return round(
          Number(
            convertMetersToMillimeters(
              convert2DPointsToDistanceInMeters(
                wallBottomRightPoint,
                [startPoint.x, startPoint.z],
                multiplyRate
              )
            )
          ),
          2
        );
      })
    );
  }, [wallsData, startPoint, multiplyRate]);

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    }
  };

  const generateElevations = useCallback(() => {
    const uniqueWallsByY = wallsData.reduce(
      (acc, wall) =>
        acc.some((w) => w.points[0][1] === wall.points[0][1])
          ? acc
          : [...acc, wall],
      [] as WallSearchResults[]
    );

    return uniqueWallsByY.map((wall) => {
      const wallHeight = Number(
        convertMetersToMillimeters(getWallHeight(wall.points, multiplyRate))
      );
      const wallCoordinates = getMinMaxCoordinatesAtVector3(
        convertFlatVector3ToVectors(wall.points)
      );
      const wallOffsetY = round(
        Number(
          convertMetersToMillimeters(
            (wallCoordinates.max.y - minMaxWallsCoordinates.max.y) /
              multiplyRate
          )
        ),
        2
      );
      return (
        <WallElevations
          key={wall.guid}
          offsetY={wallOffsetY}
          distanceToRightEdge={distanceToRightEdge}
          wallHeight={wallHeight}
          scale={stageParams.scale}
          selectedWalls={selectedWalls}
          parentStoreyGuid={
            wall.parentNodes.find((p) => p.type === NodeType.Storey)!.guid
          }
          parentBlockGuid={
            wall.parentNodes.find((p) => p.type === NodeType.Block)!.guid
          }
        />
      );
    });
  }, [
    wallsData,
    minMaxWallsCoordinates,
    stageParams.scale,
    distanceToRightEdge,
  ]);

  const activateDrag = (
    type: FacadeDesignerPlacementType,
    callback: () => void
  ) => {
    timeoutForGrabRef.current = setTimeout(() => {
      dispatch(setDragNode(type));
      callback();
      stageRef.current.container().style.cursor = 'grabbing';
    }, 300);
  };

  const handleStartWindowDrag = () => {
    activateDrag(FacadeDesignerPlacementType.Window, () => {
      dispatch(resetSelectedGridLines());
      dispatch(setSelectedPlacedWindow([hoveredWindow!]));
      grabbedWindowRef.current = hoveredWindow;
      const windowFromLibrary = windowsData.find(
        (window) => hoveredWindow!.windowId === window.id
      );

      windowFromLibrary &&
        dispatch(setSelectedWindowFromLibrary(windowFromLibrary));
      dispatch(
        setGridPlacementAbsoluteOffset(hoveredWindow!.offsetFromLeftEdge)
      );
    });

    return;
  };

  const handleStartGridLineDrag = () => {
    activateDrag(FacadeDesignerPlacementType.GridLine, () => {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(setGridPlacementAbsoluteOffset(hoveredGridLine!.absoluteOffset));
    });

    return;
  };

  const handleDragStart = (e: KonvaEventObject<PointerEvent>) => {
    if (!isSelectionMode || e.evt.shiftKey || measurementActiveWall) return;

    if (hoveredWindow) {
      handleStartWindowDrag();
    }

    if (hoveredGridLine) {
      handleStartGridLineDrag();
    }
  };

  const handleGridLineDragEnd = () => {
    const newGridLines = generateCustomGrids({
      wallsData: wallsData.map((wallData) => ({
        ...wallData,
        gridLines: wallData.gridLines.filter(
          (gridLine) =>
            !selectedPlacedGridLines.some((line) => gridLine.guid === line.guid)
        ),
      })),
      startPoint,
    });
    const mainSelectedGridLine = newGridLines?.find(
      (line) => line.wallGUID === hoveredWall
    );
    mainSelectedGridLine
      ? dispatch(setSelectedGridLines([mainSelectedGridLine]))
      : dispatch(setSelectedGridLines(newGridLines));
    handleDragReset();
  };

  const handleWindowDragEnd = (newOffset: number) => {
    if (!grabbedWindowRef.current || !hoveredWall) {
      return handleDragReset();
    }

    const wall = wallsData.find((wall) =>
      wall.windowPlacements.find(
        (window) => window.guid === grabbedWindowRef.current?.guid
      )
    );

    const hoveredWallPlacement = wallsData
      .find((wall) => wall.guid === hoveredWall)
      ?.windowPlacements.filter(
        (window) => window.guid !== grabbedWindowRef.current?.guid
      );

    if (!hoveredWallPlacement) return;
    const newWindowData = {
      windowId: grabbedWindowRef.current.windowId,
      guid: grabbedWindowRef.current.guid,
      offsetFromLeftEdge: newOffset,
    };
    handleSaveWallPlacements([
      {
        wallGUID: hoveredWall,
        windowPlacements: [...hoveredWallPlacement, newWindowData],
      },
      {
        wallGUID: wall?.guid ?? '',
        windowPlacements: wall?.windowPlacements.filter(
          (window) => window.guid !== grabbedWindowRef.current?.guid
        ),
      },
    ]);
    dispatch(setSelectedPlacedWindow([newWindowData]));
    handleDragReset();
  };

  const handleDragEnd = () => {
    if (!isSelectionMode) return;
    if (Object.values(placementErrors).some((error) => error.state)) {
      handleDragReset();
      return;
    }
    if (isGridLineDragActive) {
      handleGridLineDragEnd();
    }

    handleDragReset();
  };

  const handleDragReset = () => {
    timeoutForGrabRef.current && clearTimeout(timeoutForGrabRef.current);
    timeoutForGrabRef.current = null;
    grabbedWindowRef.current = null;

    dragNode && dispatch(setDragNode(null));
    dispatch(resetPlacementErrors());

    dispatch(resetSelectedWindowFromLibrary());

    if (hoveredGridLine || hoveredWindow) {
      stageRef.current.container().style.cursor = 'pointer';
    }
  };

  useKey('Escape', () => handleDragReset(), { event: 'keydown' }, [dragNode]);

  return (
    <Stage
      onContextMenu={(e) => e.evt.preventDefault()}
      width={placementWidth}
      height={placementHeight}
      scaleX={stageParams.scale}
      scaleY={stageParams.scale}
      onClick={handleClick}
      x={stageParams.x}
      y={stageParams.y}
      draggable
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
      ref={stageRef}
      onPointerDown={handleDragStart}
      onPointerUp={handleDragEnd}
    >
      <Layer>
        <FacadeDesignerBackground
          scale={scale}
          totalHeight={wallsTotalHeight}
          totalWidth={wallsTotalWidth}
          unitSystem={unitSystem}
        />

        <Group id={FACADE_VIEW}>
          {wallsData.map((wallData) => (
            <FacadeDesignerWallView
              reportView={reportView}
              facadeDesignerMode={facadeDesignerMode}
              startPoint={startPoint}
              scale={stageParams.scale}
              wallData={wallData}
              key={wallData.guid}
              minMaxWallsCoordinates={minMaxWallsCoordinates}
              onAddWindow={(data) => handleSaveWindowPlacements(data, wallData)}
              onMeasurementUpdate={(data) =>
                handleSaveWindowPlacements(data, wallData)
              }
              onGridPlacement={handleGridPlacement}
              handleWindowDragEnd={handleWindowDragEnd}
            />
          ))}
        </Group>
        {generateElevations()}
        <GridLinesInformationMeasurements
          wallsData={wallsData}
          scale={stageParams.scale}
          wallsTotalHeight={wallsTotalHeight}
          wallsTotalWidth={wallsTotalWidth}
          startPoint={startPoint}
        />
        {allPlacedWindows?.length > 0 && (
          <WindowsInformationMeasurements
            wallsData={wallsData}
            scale={stageParams.scale}
            startPoint={startPoint}
            wallsTotalWidth={wallsTotalWidth}
          />
        )}
      </Layer>
      {reportView && (
        <Layer>
          {allPanels.map((panel) => (
            <PanelViewReport
              key={panel.id}
              panelInfo={panel}
              scale={stageParams.scale}
            />
          ))}
        </Layer>
      )}
    </Stage>
  );
};

export default FacadeDesignerContainer;

import { Route } from 'react-router-dom';
import ProtectedRoute from '@/core/ProtectedRoute';
import React from 'react';
import BluesScreenRedirect from '@/routes/bluescreen/BluesScreenRedirect';

export const BlueScreenRoutes = [
  <Route
    key={'/bluescreen/:id'}
    path={'/bluescreen/:id'}
    element={<ProtectedRoute component={BluesScreenRedirect} />}
  />,
];

import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import IsolateSelectionIcon from '../icons/IsolateSelectionIcon';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { useAppSelector } from '@/store/hooks';
import { getSelectedNodes } from '@/store/slices/canvasBuildingSlice';

const IsolateSelection = () => {
  const {
    isIsolateModeEnabled,
    handleIsolateAction,
    allowedTypesForIsolation,
    handleDisableIsolateMode,
  } = useIsolationHandlers();

  const selectedNodes = useAppSelector(getSelectedNodes);

  const hasObjectsForIsolation = Object.values(selectedNodes).some((node) =>
    allowedTypesForIsolation.includes(node.type)
  );

  const onClick = () => {
    isIsolateModeEnabled
      ? handleDisableIsolateMode()
      : handleIsolateAction(Object.values(selectedNodes));
  };

  const getTooltipText = () => {
    let tooltipText: string;
    let tooltipSubText: string | undefined = 'Ctrl/Cmd + I';

    if (!isIsolateModeEnabled) {
      tooltipText = hasObjectsForIsolation
        ? 'Isolate selection'
        : 'Select objects to isolate';

      tooltipSubText = hasObjectsForIsolation ? 'Ctrl/Cmd + I' : undefined;
    } else {
      tooltipText = 'End isolation';
    }
    return { tooltipText, tooltipSubText };
  };

  const { tooltipText, tooltipSubText } = getTooltipText();

  return (
    <ToolbarButton
      id="toolbar-button-isolate-selection"
      icon={<IsolateSelectionIcon />}
      tooltipText={tooltipText}
      tooltipSubText={tooltipSubText}
      disabled={!hasObjectsForIsolation && !isIsolateModeEnabled}
      isActive={isIsolateModeEnabled}
      onClick={onClick}
    />
  );
};

export default React.memo(IsolateSelection);

export const subscribe = (
  eventName: string,
  listener: (evt: CustomEvent) => void,
  opts?: AddEventListenerOptions
) => {
  document.addEventListener(eventName, listener as never, opts);
};

export const unsubscribe = (
  eventName: string,
  listener: (evt: CustomEvent) => void
) => {
  document.removeEventListener(eventName, listener as never);
};

export const subscribeOnce = (
  eventName: string,
  listener: (evt: CustomEvent) => void
) => {
  subscribe(eventName, listener, { once: true });
};

export const publish = <T>(eventName: string, data?: T): void => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

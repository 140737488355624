import React, { useRef, useState } from 'react';
import {
  ContextAction,
  ContextMenu,
  IntusButton,
  IntusIconButton,
} from '@/shared/elements';
import { useOutsideMouseDown } from '@/shared/hooks';
import { MoreIcon } from '@/shared/icons';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import {
  useDeleteWindowMutation,
  useDuplicateExistingWindowMutation,
} from '@/store/apis/windowApi';
import { useParams } from 'react-router';
import WindowModal from '@/components/WindowCreator/WindowModal';
import { ContextActionTypes } from '@/shared/elements/ContextMenu/ContextMenu';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getSelectedWindowFromLibrary,
  resetSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';

const WindowContextMenu = ({
  disabled,
  windowData,
  windowUsageAmount,
}: {
  disabled: boolean;
  windowData: SavedWindow;
  windowUsageAmount: number;
}) => {
  const dispatch = useAppDispatch();
  const selectedWindow = useAppSelector(getSelectedWindowFromLibrary);
  const [showWindowTypeEditor, setShowWindowTypeEditor] = useState(false);
  const [deleteWindow] = useDeleteWindowMutation();
  const [duplicateWindow] = useDuplicateExistingWindowMutation();
  const [xYPosition, setXyPosition] = useState({ x: 0, y: 0 });
  const [openDeleteWindowModal, setOpenDeleteWindowModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null!);
  useOutsideMouseDown(ref, () => setOpenDeleteWindowModal(false));
  const { id } = useParams();

  const deleteWindowHandler = () => {
    deleteWindow({
      windowId: windowData.id,
    });
    setOpenDeleteWindowModal(false);
    selectedWindow?.id === windowData.id &&
      dispatch(resetSelectedWindowFromLibrary());
  };

  const contextMenuActions: ContextAction[] = [
    {
      label: 'Duplicate',
      action: () => duplicateWindow({ windowId: windowData.id }),
    },
    {
      label: 'Edit',
      action: () => setShowWindowTypeEditor(true),
      divider: true,
    },
    {
      label: 'Delete',
      type: ContextActionTypes.Danger,
      action: () => setOpenDeleteWindowModal(true),
    },
  ];

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (disabled) return;
    const yPosition =
      e.pageY + 5 + 160 > window.innerHeight
        ? window.innerHeight - 160 - 5
        : e.pageY + 5;
    setXyPosition({ x: e.pageX + 5, y: yPosition });
  };

  return (
    <>
      <ContextMenu actions={contextMenuActions} openMenuByLeftClick>
        <IntusIconButton
          simplified
          size={'small'}
          onClick={handleClick}
          disabled={disabled}
          icon={<MoreIcon />}
          id="windowContextMenu__more-button"
        />
      </ContextMenu>
      {openDeleteWindowModal && (
        <div
          ref={ref}
          className={`z-40 bg-white context-menu__wrapper font-normal text-xs w-[230px] h-40 fixed`}
          style={{
            top: xYPosition.y,
            left: xYPosition.x,
          }}
        >
          <div className="h-8 px-3 py-2 text-[11px] leading-4 font-medium box-border border-solid border-b border-light-gray-10">
            Window type deletion
          </div>
          <div className="p-3 font-light text-xs leading-5">
            The window type deletion from the library will cause deletion of{' '}
            <span className="font-normal">{windowUsageAmount}</span> window
            instance(-s) from the model
          </div>
          <div className="flex justify-end px-3 gap-3">
            <IntusButton
              className="w-[61px]"
              type="text"
              onClick={() => setOpenDeleteWindowModal(false)}
              id="windowContextMenu__cancel-button"
            >
              Cancel
            </IntusButton>
            <IntusButton
              id="windowContextMenu__delete-button"
              danger
              className="w-28"
              onClick={deleteWindowHandler}
            >
              Delete
            </IntusButton>
          </div>
        </div>
      )}
      {showWindowTypeEditor && (
        // We need it in order to prevent rendering WindowModal, isOpen prop doen't prevent rendering
        <WindowModal
          isOpen={showWindowTypeEditor}
          setIsOpen={setShowWindowTypeEditor}
          projectId={id!}
          windowData={windowData}
        />
      )}
    </>
  );
};

export default WindowContextMenu;

import React, { useEffect, useMemo, useState } from 'react';
import { NodeType } from '@/models';
import {
  displaySelectionBoxArea,
  getCursorCoordinatesOnOrthographicSystem,
  hideSelectionBoxArea,
  isRightClick,
} from '@/shared/helpers';
import { useThree } from '@react-three/fiber';
import { SelectionBox } from 'three-stdlib';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { SelectionHelper } from '@/shared/components';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useAppSelector } from '@/store/hooks';
import { getSelectedNodesByType } from '@/store/slices/canvasBuildingSlice';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';

const CanvasSelectionActionHandlers = ({ mapUUID }: { mapUUID?: string }) => {
  const { scene, camera, gl, raycaster } = useThree();
  const selectionBox = useMemo(() => new SelectionBox(camera, scene), []);
  const [isCursorClicked, setIsCursorClicked] = useState(false);
  const { addNodesToSelectedNodes } = useSelectedNodes();
  const { getNodeData } = useFindNodeData();

  const selectedEnvironment = useAppSelector(
    getSelectedNodesByType(NodeType.Environment)
  );

  // We need this helper, as it displays selection area... yes, i know...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const helper = useMemo(
    () => new SelectionHelper(selectionBox, gl, 'selectBox'),
    []
  );

  const pointerDownEvent = (event: PointerEvent) => {
    if (!isRightClick(event)) {
      displaySelectionBoxArea();
      setIsCursorClicked(true);

      const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
        event,
        gl
      );

      selectionBox.startPoint.set(
        cursorCoordinates.x,
        cursorCoordinates.y,
        0.5
      );
    } else {
      hideSelectionBoxArea();
    }
  };

  const pointerMoveEvent = (event: PointerEvent) => {
    if (!isRightClick(event)) {
      const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
        event,
        gl
      );
      selectionBox.endPoint.set(cursorCoordinates.x, cursorCoordinates.y, 0.5);
    }
  };
  const pointerUpEvent = (event: PointerEvent) => {
    hideSelectionBoxArea();
    if (!isRightClick(event) && isCursorClicked) {
      const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
        event,
        gl
      );

      selectionBox.endPoint.set(cursorCoordinates.x, cursorCoordinates.y, 0.5);
      raycaster.setFromCamera(cursorCoordinates, camera);

      // check if we're clicking, or dragging selection area
      if (
        Math.abs(selectionBox.startPoint.x - selectionBox.endPoint.x) > 0.01 &&
        Math.abs(selectionBox.startPoint.y - selectionBox.endPoint.y) > 0.01
      ) {
        const environmentNodes = selectionBox
          .select()
          .filter(
            (item) =>
              item.uuid !== mapUUID &&
              item.type === 'Mesh' &&
              item.userData.guid
          )
          .map((mesh) => ({
            type: mesh.userData.nodeType,
            guid: mesh.userData.guid,
          }));
        const selectedNodes = [...environmentNodes];

        const constructionElementsInSelectionBox = selectionBox
          .select()
          .filter((item) => item.userData.originalBuildingBlock);

        const buildingBlocks = new Set();

        constructionElementsInSelectionBox &&
          constructionElementsInSelectionBox.forEach((constructionElement) => {
            const blockGUID =
              constructionElement.userData.originalBuildingBlock.guid;

            if (!buildingBlocks.has(blockGUID)) {
              buildingBlocks.add(blockGUID);
              selectedNodes.push({
                type: NodeType.Block,
                guid: blockGUID,
              });
            } else return;
          });
        const filteredNodesFromSelectionBox = selectedNodes.filter((node) => {
          const nodeData = getNodeData({
            guid: node.guid,
            nodeType: node.type,
          });

          return !nodeData?.userData?.isLocked && !nodeData?.isParentLocked;
        });

        addNodesToSelectedNodes(filteredNodesFromSelectionBox);
      }
    }
    setIsCursorClicked(false);
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    canvas?.addEventListener('pointerdown', pointerDownEvent);
    canvas?.addEventListener('pointermove', pointerMoveEvent);
    canvas?.addEventListener('pointerup', pointerUpEvent);
    return () => {
      canvas?.removeEventListener('pointerdown', pointerDownEvent);
      canvas?.removeEventListener('pointermove', pointerMoveEvent);
      canvas?.removeEventListener('pointerup', pointerUpEvent);
    };
  }, [mapUUID, selectedEnvironment, isCursorClicked]);

  return <></>;
};
export default CanvasSelectionActionHandlers;

import React, { useEffect, useState } from 'react';
import { Group } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';
import PlacedWindowStates from '@/components/FacadeDesigner/elements/PlacedWindowStates';
import { UnitSystemTypes, WindowPlacementData } from '@/models';
import { KonvaEventObject } from 'konva/lib/Node';
import {
  FacadeDesignerPlacementType,
  getDragNode,
  getSelectedGridlines,
  setHoveredPlacedWindow,
  setMeasurementActiveWall,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { isLeftClick } from '@/shared/helpers';
import {
  FlatVector2Axis,
  MeasurementElementType,
  SavedWindow,
} from '@/components/WindowCreator/models';
import { FacadeDesignerModes } from '@/models/shared.model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  useCustomElementPlacement,
  useFacadeDesignerSelection,
} from '@/components/FacadeDesigner/hooks';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';

interface FacadeDesignerWindowProps {
  savedWindowData: SavedWindow;
  placedWindowData: WindowPlacementData;
  hoveredPlacedWindow: WindowPlacementData | null;
  scale: number;
  unitSystem: UnitSystemTypes;
  isSelected: boolean;
  wallHeight: number;
  facadeDesignerMode: FacadeDesignerModes;
  isWindowPlacementProcessing: boolean;
  wallData: WallSearchResults;

  onMeasurementChange: (points: FlatVector2Axis[]) => void;
  onMeasurementEscape: () => void;
  onMeasurementSubmit: () => void;
}

const FacadeDesignerWindow = ({
  savedWindowData,
  placedWindowData,
  scale,
  wallHeight,
  unitSystem,
  isSelected,
  facadeDesignerMode,
  hoveredPlacedWindow,
  wallData,
  isWindowPlacementProcessing,
  onMeasurementChange,
  onMeasurementEscape,
  onMeasurementSubmit,
}: FacadeDesignerWindowProps) => {
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const dispatch = useAppDispatch();
  const { handleSelectWindow } = useFacadeDesignerSelection();
  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const selectedGridLines = useAppSelector(getSelectedGridlines);
  const dragNode = useAppSelector(getDragNode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const handleWindowMouseEnter = (
    e: KonvaEventObject<MouseEvent>,
    placedWindowData: WindowPlacementData
  ) => {
    if (!isSelectionMode) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'pointer';
    dispatch(setHoveredPlacedWindow(placedWindowData));
  };

  const { handleWindowMovePlacement } = useCustomElementPlacement({
    wallData,
    yPosition: wallHeight / 2,
  });

  const handleWindowMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'default';
    dispatch(setHoveredPlacedWindow(null));
  };

  useEffect(() => {
    if (isSelected) {
      setMeasurementPoints(
        handleWindowMovePlacement(
          placedWindowData.offsetFromLeftEdge,
          savedWindowData
        )
      );
    } else {
      setMeasurementPoints([]);
    }
  }, [isSelected, savedWindowData, placedWindowData]);

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    setMeasurementPoints(points);
    onMeasurementChange(points);
  };

  const handleMeasurementEscape = () => {
    setMeasurementPoints([]);
    onMeasurementEscape();
  };

  const selectWindow = (
    e: KonvaEventObject<MouseEvent>,
    data: WindowPlacementData
  ) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    handleSelectWindow({
      data,
      multiSelect: e.evt.shiftKey,
    });
  };

  const handleMeasurementSubmit = () => {
    setMeasurementPoints([]);
    onMeasurementSubmit();
  };

  const handleStatusChange = (isActive: boolean) => {
    if (!wallData?.guid) return;
    dispatch(setMeasurementActiveWall(isActive ? wallData?.guid : null));
  };

  return (
    <Group
      onMouseEnter={(e) =>
        !dragNode && handleWindowMouseEnter(e, placedWindowData)
      }
      onMouseLeave={(e) => !dragNode && handleWindowMouseLeave(e)}
      onClick={(e) => selectWindow(e, placedWindowData)}
      opacity={
        isSelected && dragNode === FacadeDesignerPlacementType.Window ? 0 : 1
      }
    >
      <WindowView
        data={savedWindowData}
        scale={scale}
        offsetX={placedWindowData.offsetFromLeftEdge}
        offsetY={wallHeight - savedWindowData.distanceToFloor}
        units={unitSystem}
      />
      {!isWindowPlacementProcessing && (
        <PlacedWindowStates
          data={savedWindowData}
          isHovered={placedWindowData.guid === hoveredPlacedWindow?.guid}
          isSelected={isSelected}
          offsetX={placedWindowData.offsetFromLeftEdge}
          offsetY={wallHeight - savedWindowData.distanceToFloor}
        />
      )}
      {!!measurementPoints?.length &&
        !selectedGridLines?.length &&
        !projectData.locked &&
        !dragNode && (
          <MultiMeasurementLine
            multiPoints={measurementPoints}
            scale={scale}
            units={unitSystem}
            type={MeasurementElementType.WindowDistance}
            onChange={handleMeasurementChange}
            onEscape={handleMeasurementEscape}
            onSubmit={handleMeasurementSubmit}
            onActiveStatusChange={handleStatusChange}
          />
        )}
    </Group>
  );
};

export default FacadeDesignerWindow;

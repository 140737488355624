import React from 'react';
import { ProjectStructure } from '@/models';
import ProjectCanvasBuilding from '@/routes/dashboard/projects/project/Environment/ProjectCanvasBuilding';
import { useAppSelector } from '@/store/hooks';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';

const ProjectCanvasBuildings = ({
  project,
  isAllEnvironmentSelected,
  isEnvironmentLocked,
}: {
  project: ProjectStructure;
  isAllEnvironmentSelected?: boolean;
  isEnvironmentLocked?: boolean;
}) => {
  const isCurrentNodeSelected = useAppSelector(
    getIsNodeSelected(project.environment.surroundingBuildings.guid)
  );

  const canvasBuildings = () =>
    project.environment.surroundingBuildings.surroundings.map((envBuilding) => (
      <ProjectCanvasBuilding
        key={envBuilding.guid}
        envBuilding={envBuilding}
        mapBoundingBox={project.boundingBox}
        isParentSelected={isAllEnvironmentSelected || isCurrentNodeSelected}
        isParentLocked={
          isEnvironmentLocked ||
          project.environment.surroundingBuildings.userData?.isLocked
        }
      />
    ));
  if (project.environment.surroundingBuildings.userData?.isHidden) return null;
  return <>{canvasBuildings()}</>;
};

export default ProjectCanvasBuildings;

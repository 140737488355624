import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import TargetIcon from '@/components/Toolbar/icons/TargetIcon';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { CanvasCameraCenterMode, CanvasCameraPolarAngles } from '@/models';
import {
  getCameraPolarAngle,
  setCameraCenterPosition,
} from '@/store/slices/canvasCamerasSlice';

const MoveToTheSite = () => {
  const dispatch = useAppDispatch();

  //TODO: delete this after fix it IGIG-135
  const isFlatView =
    useAppSelector(getCameraPolarAngle) === CanvasCameraPolarAngles.topView;

  const onClick = () => {
    dispatch(setCameraCenterPosition(CanvasCameraCenterMode.site));
  };

  const tooltipText = !isFlatView
    ? 'Move to the site'
    : 'Switch to 3D mode to activate Move to the site tool';

  return (
    <ToolbarButton
      id="toolbar-button-move-to-the-site"
      icon={<TargetIcon />}
      tooltipText={tooltipText}
      onClick={onClick}
      disabled={isFlatView}
    />
  );
};

export default MoveToTheSite;

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';

const blueScreenApi = createApi({
  reducerPath: 'bluescreen',
  tagTypes: ['DraftProject'],
  baseQuery,
  endpoints: (build) => ({
    fetchProjectByDraftId: build.query<string, string>({
      providesTags: ['DraftProject'],
      query: (draftId) => ({
        url: `/bluescreen/${draftId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchProjectByDraftIdQuery } = blueScreenApi;

export { blueScreenApi };

import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import React from 'react';
import { useParams } from 'react-router';
import { IntusLoader } from '@/shared/elements';
import Report from '@/components/Report/Report';

const ReportPage = () => {
  const { id } = useParams();
  const { isFetching } = useFetchProjectQuery(id!, {
    refetchOnMountOrArgChange: true,
  });

  const arePanelsFetching = useGetAllPanelsQuery(id!).isFetching;
  const areWindowsFetching = useFetchWindowsQuery(id!).isFetching;
  const isConfigFetching = useFetchWindowConfigQuery().isFetching;

  const isProjectLoading =
    isFetching || areWindowsFetching || arePanelsFetching || isConfigFetching;

  return (
    <>
      {!isProjectLoading && <Report generatedImages={[]} />}
      <IntusLoader loading={isProjectLoading} />
    </>
  );
};

export default ReportPage;

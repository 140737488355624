import React, { useEffect, useMemo, useState } from 'react';
import { IntusModal } from '@/shared/elements';
import { QuoteStatus } from '@/models/quote.model';
import {
  QuoteFooter,
  QuoteHeader,
  QuoteErrorContent,
  QuoteRequestContent,
  QuoteReadyContent,
  QuoteProcessingContent,
} from '@/routes/dashboard/projects/quote/components';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getQuoteRequestStatusReport,
  setQuoteRequestStatus,
} from '@/store/slices/quoteSlice';
import { useUnmount } from 'react-use';

interface QuoteModalContentProps {
  setIsOpen: (isOpen: boolean) => void;
}

const QuoteModalContent: React.FC<QuoteModalContentProps> = ({ setIsOpen }) => {
  const dispatch = useAppDispatch();

  const status = useAppSelector(getQuoteRequestStatusReport);
  const isProcessing = status === QuoteStatus.PROCESSING_EXPORT;

  const [percent, setPercent] = useState(0);

  //mock percent data
  useEffect(() => {
    if (status !== QuoteStatus.PROCESSING_EXPORT) return;
    setTimeout(() => {
      setPercent(10);
    }, 100);
    setTimeout(() => {
      setPercent(20);
    }, 500);
    setTimeout(() => {
      setPercent(26);
    }, 700);
    setTimeout(() => {
      setPercent(33);
    }, 1000);
    setTimeout(() => {
      setPercent(52);
    }, 2000);
    setTimeout(() => {
      setPercent(71);
    }, 2500);
    setTimeout(() => {
      setPercent(83);
    }, 2600);
    setTimeout(() => {
      setPercent(97);
    }, 2800);
  }, [status]);

  const modals = useMemo(() => {
    switch (status) {
      case QuoteStatus.PROCESSING_EXPORT:
        return <QuoteProcessingContent percent={percent} />;
      case QuoteStatus.ERROR:
        return <QuoteErrorContent />;
      case QuoteStatus.READY:
        return <QuoteReadyContent setIsOpen={setIsOpen} />;
      case QuoteStatus.DEFAULT:
      default:
        return <QuoteRequestContent />;
    }
  }, [status, percent]);

  useUnmount(() => {
    dispatch(setQuoteRequestStatus(QuoteStatus.DEFAULT));
  });

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IntusModal
        centered
        open
        footer={null}
        styles={{ body: { padding: 0 } }}
        onCancel={() => handleCancel()}
        maskClosable={!isProcessing}
        closable={!isProcessing}
        keyboard={!isProcessing}
        width={580}
      >
        <div className="flex flex-col items-center pt-[60px]">
          <QuoteHeader status={status} />
          {modals}
          <QuoteFooter status={status} />
        </div>
      </IntusModal>
    </>
  );
};

export default QuoteModalContent;

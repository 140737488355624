import React from 'react';
import { UnitSystemTypes, UserBuildingPanel } from '@/models';
import IntusTable from '@/shared/elements/IntusTable/IntusTable';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { addSpacesToThousands } from '@/shared/helpers/format-data';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';

const PanelMetricsTable = ({
  panelData,
  panelCount,
}: {
  panelData: UserBuildingPanel;
  panelCount: {
    [key: number]: number;
  };
}) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const {
    calculatePanelArea,
    calculateNonGlazedAreaInPanel,
    getAreaUnit,
    getUnit,
  } = useFrameProperties();

  const dataSource = [
    {
      ...(panelData.isCorner && {
        leftWidth: (
          <div className="text-end">
            {addSpacesToThousands(
              isImperialUnits
                ? convertMillimetersToFtInch(panelData.sideWidth)
                : panelData.sideWidth!.toString(),
              isImperialUnits
            )}
          </div>
        ),
      }),
      key: `panel-metrics-${panelData.id}`,
      width: (
        <div className="text-end">
          {addSpacesToThousands(
            isImperialUnits
              ? convertMillimetersToFtInch(panelData.width)
              : panelData.width.toString(),
            isImperialUnits
          )}
        </div>
      ),
      height: (
        <div className="text-end">
          {addSpacesToThousands(
            isImperialUnits
              ? convertMillimetersToFtInch(panelData.height)
              : panelData.height.toString(),
            isImperialUnits
          )}
        </div>
      ),
      totalArea: (
        <div className="text-end">{calculatePanelArea(panelData)}</div>
      ),
      glazedArea: (
        <div className="text-end">
          {panelData.isCorner
            ? panelData.fullCornerGlazedArea
            : panelData.glazedArea}
        </div>
      ),
      nonGlazedArea: (
        <div className="text-end">
          {calculateNonGlazedAreaInPanel(panelData)}
        </div>
      ),
      count: <div className="text-end">{panelCount[panelData.id]}</div>,
    },
  ];

  const columns = [
    {
      key: `panel-width-column-${panelData.id}`,
      title: (
        <div className="text-end">
          {panelData.isCorner ? (
            <div>
              Right wing <br /> width
            </div>
          ) : (
            <div>
              Width{' '}
              <span className="text-[8px] text-dark-gray-60">
                ({getUnit()})
              </span>
            </div>
          )}
        </div>
      ),
      dataIndex: 'width',
      width: panelData.isCorner ? '82px' : '79px',
    },
    {
      key: `panel-height-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Height{' '}
          <span className="text-[8px] text-dark-gray-60">({getUnit()})</span>
        </div>
      ),
      dataIndex: 'height',
      width: panelData.isCorner ? '82px' : '79px',
    },
    {
      key: `panel-total-area-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Total area{' '}
          <span className="text-[8px] text-dark-gray-60">{getAreaUnit()}</span>
        </div>
      ),
      dataIndex: 'totalArea',
      width: panelData.isCorner ? '82px' : '79px',
    },
    {
      key: `panel-glazed-area-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Glazed area{' '}
          <span className="text-[8px] text-dark-gray-60">{getAreaUnit()}</span>
        </div>
      ),
      dataIndex: 'glazedArea',
      width: panelData.isCorner ? '82px' : '79px',
    },
    {
      key: `panel-non-glazed-area-column-${panelData.id}`,
      title: (
        <div className="text-end">
          <div className="whitespace-nowrap">Non-glazed area</div>
          <div className="text-dark-gray-60">{getAreaUnit()}</div>
        </div>
      ),
      dataIndex: 'nonGlazedArea',
      width: panelData.isCorner ? '82px' : '79px',
    },
    {
      key: `panel-count-column-${panelData.id}`,
      title: <div className="text-end">Count</div>,
      dataIndex: 'count',
    },
  ];

  if (panelData.isCorner) {
    columns.unshift({
      key: `panel-left-width-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Left wing <br />
          width
        </div>
      ),
      dataIndex: 'leftWidth',
      width: '82px',
    });
  }

  return (
    <IntusTable
      dataSource={dataSource}
      columns={columns}
      bordered={false}
      pagination={false}
    />
  );
};

export default PanelMetricsTable;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import AddCameraIcon from '@/components/Toolbar/icons/AddCameraIcon';
import { publish, subscribe, unsubscribe } from '@/core/events';
import {
  CHANGE_SAVE_CAMERA_STATE,
  OPEN_CAMERAS_POPOVER,
} from '@/core/event-names';

import {
  getFormattedDateTime,
  getformattedMomentDate,
} from '@/shared/helpers/camera';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

const AddCamera = () => {
  const { id } = useParams();
  const savedCameras = useFetchProjectQuery(id!).data!.cameras;
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    subscribe(CHANGE_SAVE_CAMERA_STATE, (evt) => {
      setIsLoading(evt.detail);
    });

    return () => {
      unsubscribe(CHANGE_SAVE_CAMERA_STATE, (evt) => {
        setIsLoading(evt.detail);
      });
    };
  }, []);

  const addCamera = () => {
    const formattedDate = getformattedMomentDate();
    publish(
      OPEN_CAMERAS_POPOVER,
      getFormattedDateTime(formattedDate, savedCameras)
    );
    setTooltipVisible(false);
  };

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <ToolbarButton
      id="toolbar-button-add-camera"
      disabled={isLoading}
      icon={<AddCameraIcon />}
      tooltipText={tooltipVisible ? 'Save camera position' : ''}
      onClick={addCamera}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default AddCamera;

import { FormInstance } from 'antd';
import {
  convertFtInchToMillimeters,
  convertMillimetersToFtInch,
} from './distance';
import { round } from 'mathjs';

export function addSpacesToThousands(
  numberStr: string,
  isImperialUnits: boolean
) {
  return isImperialUnits
    ? numberStr.replace(/(\d)(?=(\d{3})+(?='))/g, '$1 ')
    : numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const convertInputValueToMm = (
  val: string,
  isImperialUnits: boolean
): number => {
  const truncatedValue = removeSpacesFromThousands(val, isImperialUnits);
  const convertedValue = isImperialUnits
    ? round(Number(convertFtInchToMillimeters(truncatedValue)), 2)
    : round(Number(truncatedValue), 2);
  return isNaN(convertedValue) ? 0 : convertedValue;
};

export function removeSpacesFromThousands(
  value: string,
  isImperialUnits: boolean
) {
  return isImperialUnits
    ? value.replace(/\s+(?=.*')/g, '')
    : value.replace(/\s/g, '');
}

export function getAreaUnit(isImperialUnits: boolean) {
  return isImperialUnits ? 'ft²' : 'm²';
}

export function limitValue(value: number, min: number, max: number): number {
  return round(Math.min(Math.max(value, min), max), 2);
}

export function sanitizeInputMetricValue(
  value: string,
  isImperialUnits: boolean,
  digitsAmount: number = 2,
  staticValue: boolean = false
) {
  if (staticValue) {
    return value.replace(/\D/g, '');
  }
  const sanitizedValue = isImperialUnits
    ? value
        .replace(/[‘’]/g, "'")
        .replace(/[“”]/g, '"')
        .replace(/[^\d\s/'"-.]/g, '')
    : value.replace(/(?!^-)[^\d.]/g, '');

  return sanitizedValue.replace(
    /\.(\d*)/,
    (match, digitsAfterDot) => `.${digitsAfterDot.slice(0, digitsAmount)}`
  );
}

export const formatLimitValue = ({
  limit,
  isImperialUnits,
  staticValue,
  decimalPlaces = 0,
}: {
  limit: number;
  isImperialUnits: boolean;
  staticValue: boolean;
  decimalPlaces?: number;
}): string => {
  const formattedLimit =
    isImperialUnits && !staticValue
      ? convertMillimetersToFtInch(limit)
          .toString()
          .replace(/"/g, '\u2033')
          .replace(/'/g, '\u2032')
      : // Use Unicode because with style direction: rtl; regular double and single quotes are not displayed correctly
        round(limit, decimalPlaces).toString();

  return addSpacesToThousands(formattedLimit, isImperialUnits);
};

export const getNextCharacter = (c: string) => {
  return String.fromCharCode(c.charCodeAt(0) + 1);
};

export const getAlphabetIndex = (i: number) =>
  String.fromCharCode('A'.charCodeAt(0) + i);

export const generateInputChangeHandlerForMetric = ({
  name,
  form,
  isImperialUnits,
  staticValue,
}: {
  name: string;
  form: FormInstance;
  isImperialUnits: boolean;
  staticValue: boolean;
}) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
      removeSpacesFromThousands(event.target.value, isImperialUnits),
      isImperialUnits,
      2,
      staticValue
    );
    form.setFieldsValue({
      [name]: addSpacesToThousands(
        sanitizedValueWithoutSpaces,
        isImperialUnits
      ),
    });
  };
};

export const compareNumbersWithPrecision = (
  value1: number,
  value2: number,
  precision: number
) => {
  return (
    Math.round(parseFloat(value1.toString()) / precision) * precision ===
    Math.round(parseFloat(value2.toString()) / precision) * precision
  );
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0]!.match(/:(.*?);/)![1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

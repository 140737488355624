import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';
import { IGU } from '@/models/webcalc';

const webCalcApi = createApi({
  reducerPath: 'webcalc',
  tagTypes: ['IGU'],
  baseQuery,
  endpoints: (build) => ({
    fetchIgu: build.query<IGU[], void>({
      providesTags: ['IGU'],
      query: () => ({
        url: `/webcalc/igu`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchIguQuery } = webCalcApi;

export { webCalcApi };

import React from 'react';
import { RenderData } from '../BuildingRenders';

const BuildingRenderItem = ({ renderData }: { renderData: RenderData }) => {
  return (
    <div className="overflow-hidden flex items-center">
      <div className="relative inline-block">
        <img
          src={renderData.imageUrl}
          className="w-full"
          alt={renderData.renderDateTime}
        />
        <div className="absolute bottom-0 left-0 bg-[#F9F9F9B2] py-0.5 px-1.5 text-[10px] backdrop-blur-sm leading-4 font-light text-dark-gray-100">
          {renderData.renderDateTime}
        </div>
      </div>
    </div>
  );
};

export default BuildingRenderItem;

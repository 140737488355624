import { QuoteStatus } from '@/models/quote.model';
import React from 'react';

export const QuoteHeader = ({ status }: { status: QuoteStatus }) => {
  switch (status) {
    case QuoteStatus.PROCESSING_EXPORT:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Project data export in progress...
        </h3>
      );
    case QuoteStatus.READY:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Quote is requested
        </h3>
      );
    case QuoteStatus.ERROR:
      return (
        <p className="flex flex-col items-center gap-4 m-0 mb-10">
          <span className="font-medium text-base leading-6 m-0 text-dark-gray-100">
            Quote request error
          </span>
          <span className="text-xs text-normal leading-5 m-0 text-dark-gray-100">
            Error text
          </span>
        </p>
      );
    default:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Request a quote
        </h3>
      );
  }
};

import React from 'react';
import { CustomModalProps } from '@/models/shared.model';
import QuoteModalContent from '@/routes/dashboard/projects/quote/QuoteModalContent';
import { useParams } from 'react-router';

interface ProjectQuoteModal extends CustomModalProps {}

const ProjectQuoteModal: React.FC<ProjectQuoteModal> = ({
  isOpen,
  setIsOpen,
}) => {
  const { id } = useParams();

  if (!id || !isOpen) return <></>;

  return (
    <>
      <QuoteModalContent setIsOpen={setIsOpen} />
    </>
  );
};

export default ProjectQuoteModal;

import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models/konva-model';
import { isHorizontal } from '@/components/WindowCreator/helpers/direction.helpers';

// given in millimeters
const MIN_HORIZONTAL_FRAME_SIZE = 450;
const MIN_VERTICAL_FRAME_SIZE = 600;
const MAX_HORIZONTAL_FRAME_SIZE = 100000;
const MAX_VERTICAL_FRAME_SIZE = 5000;
const MIN_HEIGHT_FROM_FLOOR = 0;
const MAX_HEIGHT_FROM_FLOOR = 2000;

export const getMeasurementLimits = (
  points: FlatVector2Axis,
  type: MeasurementElementType
): { min: number; max: number } => {
  switch (type) {
    case MeasurementElementType.Offset:
      return { min: MIN_HEIGHT_FROM_FLOOR, max: MAX_HEIGHT_FROM_FLOOR };

    case MeasurementElementType.Frame:
      if (isHorizontal(points)) {
        return {
          min: MIN_HORIZONTAL_FRAME_SIZE,
          max: MAX_HORIZONTAL_FRAME_SIZE,
        };
      } else
        return { min: MIN_VERTICAL_FRAME_SIZE, max: MAX_VERTICAL_FRAME_SIZE };

    default:
      return { min: 0, max: Infinity };
  }
};

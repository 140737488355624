import React from 'react';
import PanelView from '@/components/FacadeDesigner/elements/PanelView';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import ErrorPanel from '@/components/FacadeDesigner/elements/ErrorPanel';
import { UnitSystemTypes } from '@/models';
import FacadeDesignerWallPanelRectId from './FacadeDesignerWallPanelRectId';
import { useUnmount } from 'react-use';
import { useAppDispatch } from '@/store/hooks';
import { removeFromSelectedPlacedPanels } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useParams } from 'react-router';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';

interface FacadeDesignerWallPlacementViewProps {
  scale: number;
  wallData: WallSearchResults;
  wallHeight: number;
  unitSystem: UnitSystemTypes;
  reportView?: boolean;
}

const FacadeDesignerWallPanelsView: React.FC<
  FacadeDesignerWallPlacementViewProps
> = ({ scale, wallData, wallHeight, unitSystem, reportView }) => {
  const dispatch = useAppDispatch();

  useUnmount(() => {
    wallData.wallPanels.forEach((panel) =>
      dispatch(removeFromSelectedPlacedPanels(panel.guid))
    );
  });

  const { id } = useParams();
  const allPanels = useGetAllPanelsQuery(id!).data;
  return (
    <>
      {wallData.wallPanels.map((panel, i) => {
        const panelData = allPanels?.find(
          (panelData) => panelData.id === panel.panelId
        );
        if (!panelData) return null;
        return (
          <PanelView
            panelPlacementData={panel}
            key={`${panel.panelId}_${i}`}
            wallGUID={wallData.guid}
            scale={scale}
            offsetX={panel.offsetFromLeftEdge}
            panelInfo={panelData}
            panelWidth={
              panelData.isCorner && !panel.isInitialCorner
                ? panelData.sideWidth ?? 0
                : panelData.width
            }
          />
        );
      })}
      {reportView &&
        wallData.wallPanels.map((panel, index) => (
          <FacadeDesignerWallPanelRectId
            key={index}
            panelData={panel}
            scale={scale}
          />
        ))}

      {wallData.wallPanelErrors.map((error, i) => (
        <ErrorPanel
          errorData={error}
          scale={scale}
          wallHeight={wallHeight}
          key={`${wallData.guid}_panel_error_${i}`}
          unitSystem={unitSystem}
        />
      ))}
    </>
  );
};

export default FacadeDesignerWallPanelsView;

import {
  IntusButton,
  IntusDropdown,
  IntusIconButton,
  IntusLoader,
  IntusModal,
  IntusSwitcher,
} from '@/shared/elements';
import React, { useEffect, useState } from 'react';
import {
  ArrowLeftIcon,
  CreateMullionIcon,
  CursorIcon,
  MirrorWindowIcon,
  RotateIcon,
  VectorDownIcon,
} from '@/shared/icons';
import { CustomModalProps, WindowCreatorModes } from '@/models/shared.model';
import { WindowCreatorContainer } from './WindowCreatorContainer';
import { CustomError, FlatVector2, UnitSystemTypes } from '@/models';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import {
  FlatVector2Axis,
  MeasurementUpdateData,
  SavedWindow,
  View,
  WindowCreatorFormData,
} from '@/components/WindowCreator/models/konva-model';
import { Form } from 'antd';
import {
  addMullion,
  generateDefaultWindow,
  generateMirroredWindow,
  handleDeleteMullion,
  updateWindowCreatorFormData,
} from '@/components/WindowCreator/helpers';
import {
  useFetchWindowConfigQuery,
  useSetWindowMutation,
  useUpdateWindowMutation,
} from '@/store/apis/windowApi';
import { useWatch } from 'antd/lib/form/Form';
import {
  clearSelectRelatedData,
  getMultiMeasurementActiveStatus,
  getSomeWindowHaveValidationErrors,
  getWindowCreatorMode,
  resetMullionArea,
  resetWindowCreatorSlice,
  setWindowCreatorMode,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import WindowName from './externalElements/WindowName/WindowName';
import { setFormErrorFromServer } from '@/shared/form/error-handlers';
import { subscribe, unsubscribe } from '@/core/events';
import { CREATE_MULLION } from '@/core/event-names';
import { MullionData, OperationType } from '@/models/window-configurator.model';
import { MenuProps } from 'antd/lib';
import {
  WINDOW_CREATOR_STAGE_ID,
  WINDOW_CREATOR_VIEW,
} from '@/shared/constants';
import Konva from 'konva';
import { sleep } from '@/shared/helpers/sleep';
import { compact } from 'lodash';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import { STAGE_WIDTH } from './constants';

interface WindowModalProps extends CustomModalProps {
  projectId: string;
  windowData?: SavedWindow;
}
const WindowModal = ({
  isOpen,
  setIsOpen,
  projectId,
  windowData,
}: WindowModalProps) => {
  const dispatch = useAppDispatch();

  const mode = useAppSelector(getWindowCreatorMode);
  const isSomeWindowHaveErrors = useAppSelector(
    getSomeWindowHaveValidationErrors
  );
  const iguData = useFetchIguQuery().data!;
  const config = useFetchWindowConfigQuery().data!;
  const projectData = useFetchProjectQuery(projectId).data!;
  const initialMeasurementSystem: UnitSystemTypes = projectData.unitSystem;
  const isMultiMeasurementActive = useAppSelector(
    getMultiMeasurementActiveStatus
  );

  const { updateBuildingPlacements } = useUpdateUserBuildingData();

  const [form] = Form.useForm<WindowCreatorFormData>();
  const [createWindow] = useSetWindowMutation();
  const [updateWindow] = useUpdateWindowMutation();
  const [konvaStage, setKonvaStage] = useState<Konva.Stage>(null!);
  const [isLoading, setIsLoading] = useState(false);

  useWatch(['units'], form);
  useWatch(['points'], form);
  useWatch(['mullions'], form);
  useWatch(['innerWindows'], form);
  useWatch(['innerColor'], form);
  useWatch(['outerColor'], form);
  useWatch(['view'], form);

  const formInitialValues: WindowCreatorFormData = {
    name: config.baseWindow.name || 'New window type',
    units: initialMeasurementSystem,
    innerWindows: [
      {
        points: generateDefaultWindow(
          config.baseWindow.width,
          config.baseWindow.height,
          config.baseWindow.distanceToFloor
        ),
        operationType: OperationType.Fixed,
        iguId: iguData[0]?.id || null,
      },
    ],
    image: '',
    points: generateDefaultWindow(
      config.baseWindow.width,
      config.baseWindow.height,
      config.baseWindow.distanceToFloor
    ),
    view: View.Outside,
    distanceToFloor: config.baseWindow.distanceToFloor,
    innerColor: config.baseWindow.innerColor,
    outerColor: config.baseWindow.outerColor,
    mullions: [],
  };

  useEffect(() => {
    if (windowData) {
      form.setFieldsValue({
        ...windowData,
        units: initialMeasurementSystem,
        view: View.Outside,
      });
    }
    dispatch(clearSelectRelatedData());
  }, []);

  const handleUnitsChange = (units: UnitSystemTypes) => {
    form.setFieldValue('units', units);
  };

  const handleDeleteMullionSubmit = (data: MullionData) => {
    handleDeleteMullion(data, form, iguData[0]?.id);
  };

  const handleMeasurementSubmit = (data: MeasurementUpdateData) => {
    dispatch(clearSelectRelatedData());
    dispatch(resetMullionArea());
    updateWindowCreatorFormData({
      form,
      updateData: data,
    });
  };

  const handleStageInit = (stage: Konva.Stage) => setKonvaStage(stage);

  const handleCreateMullion = ({
    detail,
  }: {
    detail: { axis: FlatVector2Axis; windowPoints: FlatVector2[] };
  }) => {
    addMullion(detail, form, iguData[0]?.id || null);
  };

  // TODO: Refactor. Move somewhere to unify logic (Form/store/subscribers to one place)
  useEffect(() => {
    subscribe(CREATE_MULLION, handleCreateMullion);
    return () => {
      unsubscribe(CREATE_MULLION, handleCreateMullion);
    };
  }, []);

  const handleModeChange = (mode: WindowCreatorModes) => {
    dispatch(setWindowCreatorMode(mode));
  };

  const clearPanelsWithUpdatedWindow = (windowId: number) => {
    const buildingWithWallsWithUpdatedWindow = projectData?.buildings?.map(
      (building) => {
        return {
          buildingGUID: building.guid,
          walls: compact(
            building.blocks.flatMap((block) =>
              block.storeys.flatMap((storey) =>
                storey.walls.filter((wall) =>
                  wall.windowPlacements.find(
                    (window) => window.windowId === windowId
                  )
                )
              )
            )
          ),
        };
      }
    );
    buildingWithWallsWithUpdatedWindow?.forEach((wallsWithUpdatedWindow) =>
      updateBuildingPlacements({
        buildingGUID: wallsWithUpdatedWindow.buildingGUID,
        placementData: wallsWithUpdatedWindow.walls.map((wall) => ({
          wallGUID: wall.guid,
          wallPanels: [],
          windowPlacements: wall.windowPlacements,
          gridLines: wall.gridLines,
        })),
        triggerBEUpdate: true,
      })
    );
  };

  // Save added temporarily to check validation. We have a different task for save, where it will be handled properly
  const handleWindowSubmit = async (actionType: 'create' | 'edit') => {
    try {
      setIsLoading(true);
      if (form.getFieldValue('view') === View.Inside) {
        form.setFieldValue('view', View.Outside);
        const mirroredWindow = generateMirroredWindow(
          form.getFieldsValue(true),
          false
        );
        form.setFieldsValue(mirroredWindow);
        await sleep(100);
      }
      const image = (
        (await konvaStage
          .find(`#${WINDOW_CREATOR_VIEW}`)[0]
          .toImage()) as HTMLImageElement
      ).src;
      await form.validateFields();

      if (actionType === 'create') {
        await createWindow({
          projectId,
          data: { ...form.getFieldsValue(true), image },
        }).unwrap();
      } else {
        clearPanelsWithUpdatedWindow(windowData!.id);
        await updateWindow({
          data: { ...form.getFieldsValue(true), id: windowData!.id, image },
        }).unwrap();
      }
      setIsOpen(false);
    } catch (err) {
      setFormErrorFromServer(form, err as CustomError);
    } finally {
      setIsLoading(false);
    }
  };

  const getIconFillColor = (expectedMode: WindowCreatorModes): string => {
    return expectedMode === mode ? '#fff' : '#414042';
  };

  useEffect(() => {
    return () => {
      dispatch(resetWindowCreatorSlice());
    };
  }, []);

  const menuItems: MenuProps['items'] = [
    {
      label: <span className="text-xs font-normal">Create new type</span>,
      key: 'createNewType',
      onClick: () => handleWindowSubmit('create'),
    },
    {
      label: <span className="text-xs font-normal">Update existing type</span>,
      key: 'updateNewType',
      onClick: () => handleWindowSubmit('edit'),
    },
  ];

  const handleMirrorWindow = () => {
    const mirroredWindow = generateMirroredWindow(form.getFieldsValue(true));
    form.setFieldsValue(mirroredWindow);
  };

  const handleSwitchView = () => {
    form.setFieldValue(
      'view',
      form.getFieldValue('view') === View.Outside ? View.Inside : View.Outside
    );
    const mirroredWindow = generateMirroredWindow(
      form.getFieldsValue(true),
      false
    );
    form.setFieldsValue(mirroredWindow);
  };

  const handleModalContainerClick = (e: React.MouseEvent) => {
    const targetElement = e.target as HTMLElement;
    const konvaIsParentElement = targetElement.closest(
      `#${WINDOW_CREATOR_STAGE_ID}`
    );
    if (!konvaIsParentElement) {
      dispatch(clearSelectRelatedData());
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();

      if (!isMultiMeasurementActive) {
        dispatch(setWindowCreatorMode(WindowCreatorModes.Selection));
      }

      if (mode === WindowCreatorModes.Selection) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mode, isMultiMeasurementActive]);

  return (
    <IntusModal
      styles={{ body: { padding: 0 } }}
      footer={null}
      open={isOpen}
      centered
      width={STAGE_WIDTH}
      closable={false}
      keyboard={false}
      onCancel={() => dispatch(clearSelectRelatedData())}
    >
      <IntusLoader loading={isLoading}>
        <Form
          form={form}
          initialValues={windowData ? undefined : formInitialValues}
          onClick={handleModalContainerClick}
        >
          <div className={`bg-light-gray-10 relative`}>
            <div className="max-h-8 flex items-center absolute top-4 left-6 pointer-events-auto z-10">
              <IntusIconButton
                simplified
                size="small"
                transparent
                icon={<ArrowLeftIcon />}
                onClick={() => setIsOpen(false)}
                id="windowModal__back-button"
              />
              <WindowName form={form} />
            </div>
            <div className="flex justify-center absolute top-3 left-0 right-0 ml-auto mr-auto w-fit z-10">
              <IntusIconButton
                id="windowModal__selection-mode-button"
                type="default"
                className={'mx-1'}
                onClick={() => handleModeChange(WindowCreatorModes.Selection)}
                icon={
                  <CursorIcon
                    fill={getIconFillColor(WindowCreatorModes.Selection)}
                  />
                }
                isActive={WindowCreatorModes.Selection === mode}
              />
              <IntusIconButton
                id="windowModal__mullion-creator-mode-button"
                type="default"
                className={'mx-1'}
                onClick={() =>
                  handleModeChange(WindowCreatorModes.MullionCreator)
                }
                icon={
                  <CreateMullionIcon
                    fill={getIconFillColor(WindowCreatorModes.MullionCreator)}
                  />
                }
                isActive={WindowCreatorModes.MullionCreator === mode}
              />
              <IntusIconButton
                id="windowModal__mirror-button"
                type="default"
                className="mx-1"
                icon={<MirrorWindowIcon />}
                onClick={handleMirrorWindow}
              />
            </div>
            <div className="absolute top-3 right-6 z-10">
              {windowData ? (
                <IntusDropdown
                  customMenuProps={{
                    items: menuItems,
                  }}
                  placement="bottomRight"
                  disabled={isSomeWindowHaveErrors}
                >
                  <IntusButton
                    className=" ml-auto text-white px-2 w-[72px]"
                    id="windowModal__save-button"
                  >
                    <div className="flex gap-2 items-center">
                      Save
                      <VectorDownIcon stroke={'#FFFFFF'} />
                    </div>
                  </IntusButton>
                </IntusDropdown>
              ) : (
                <IntusButton
                  id="windowModal__save-button"
                  className="ml-auto text-white px-5"
                  onClick={() => handleWindowSubmit('create')}
                  disabled={isSomeWindowHaveErrors}
                >
                  Save
                </IntusButton>
              )}
            </div>

            <div className="relative overflow-hidden">
              <Form.Item name="innerWindows" className="m-0">
                <Form.Item name="mullions" className="m-0">
                  <Form.Item name="points" className="m-0">
                    <WindowCreatorContainer
                      onKonvaInit={handleStageInit}
                      form={form}
                      mode={mode}
                      onMeasurementSubmit={handleMeasurementSubmit}
                      onDeleteMullionSubmit={handleDeleteMullionSubmit}
                      data={form.getFieldsValue(true)}
                    />
                  </Form.Item>
                </Form.Item>
              </Form.Item>
            </div>
            <div className="bg-light-gray-20 rounded-lg absolute left-0 right-0 ml-auto mr-auto w-fit bottom-6">
              <Form.Item className="m-0" name="units">
                <IntusSwitcher
                  onChange={(val) => handleUnitsChange(val as UnitSystemTypes)}
                  data={[
                    { name: 'ft inch', value: UnitSystemTypes.Imperial },
                    { name: 'mm', value: UnitSystemTypes.Metric },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="flex items-center absolute right-6 bottom-6 z-10">
              <span className="font-light mr-2">{`${form.getFieldValue('view')} view`}</span>
              <Form.Item className="m-0" name="view">
                <IntusIconButton
                  type="default"
                  icon={<RotateIcon />}
                  onClick={handleSwitchView}
                  id="windowModal__switch-view-button"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </IntusLoader>
    </IntusModal>
  );
};

export default WindowModal;

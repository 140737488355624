import React from 'react';

export const QuoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 7C9.67157 7 9 7.67157 9 8.5V13.5C9 14.3284 9.67157 15 10.5 15H13.5C14.3284 15 15 14.3284 15 13.5V8.5C15 7.67157 14.3284 7 13.5 7H10.5ZM10.5 8C10.2239 8 10 8.22386 10 8.5V9.5C10 9.77614 10.2239 10 10.5 10H13.5C13.7761 10 14 9.77614 14 9.5V8.5C14 8.22386 13.7761 8 13.5 8H10.5ZM10.5 11H11C11.2761 11 11.5 11.2239 11.5 11.5C11.5 11.7761 11.2761 12 11 12H10.5C10.2239 12 10 11.7761 10 11.5C10 11.2239 10.2239 11 10.5 11ZM13.5 11H13C12.7239 11 12.5 11.2239 12.5 11.5C12.5 11.7761 12.7239 12 13 12H13.5C13.7761 12 14 11.7761 14 11.5C14 11.2239 13.7761 11 13.5 11ZM10.5 13H11C11.2761 13 11.5 13.2239 11.5 13.5C11.5 13.7761 11.2761 14 11 14H10.5C10.2239 14 10 13.7761 10 13.5C10 13.2239 10.2239 13 10.5 13ZM13.5 13H13C12.7239 13 12.5 13.2239 12.5 13.5C12.5 13.7761 12.7239 14 13 14H13.5C13.7761 14 14 13.7761 14 13.5C14 13.2239 13.7761 13 13.5 13Z"
        fill="#65BD51"
      />
      <path
        d="M6.93887 4.33372H8.15117C8.37144 4.33372 8.55 4.51228 8.55 4.73255C8.55 4.95282 8.37144 5.13139 8.15117 5.13139H6.47515H6.47512L6.4722 5.1314L6.46928 5.13139H6.46925L5.97233 5.13139C5.97231 5.13139 5.97229 5.13139 5.97228 5.13139C5.63879 5.13215 5.38427 5.35666 5.38333 5.61618C5.38425 5.87582 5.63883 6.10039 5.97223 6.10115H7.02776H7.02781C7.85942 6.10198 8.54899 6.66776 8.54998 7.38357V7.38371C8.54897 8.0996 7.85933 8.66549 7.02781 8.66629H7.02776H6.93887V9.08333C6.93887 9.34106 6.72993 9.55 6.4722 9.55C6.21446 9.55 6.00553 9.34106 6.00553 9.08333V8.66629H4.84898C4.6287 8.66629 4.45013 8.48772 4.45013 8.26744C4.45013 8.04716 4.6287 7.86858 4.84898 7.86858L7.02765 7.86859M6.93887 4.33372L7.02776 7.86858M6.93887 4.33372V3.91667C6.93887 3.65893 6.72993 3.45 6.4722 3.45C6.21446 3.45 6.00553 3.65894 6.00553 3.91667V4.33372H5.97213L5.97208 4.33372C5.14056 4.33452 4.451 4.90038 4.45 5.61627L4.45 5.61641C4.45099 6.33223 5.14055 6.89801 5.97217 6.89882H5.97222H7.02774C7.36114 6.89958 7.61572 7.12415 7.61665 7.38378M6.93887 4.33372L7.61665 7.38378M7.02765 7.86859L7.02776 7.86858M7.02765 7.86859C7.02768 7.86859 7.02772 7.86858 7.02776 7.86858M7.02765 7.86859L7.61665 7.38378M7.02776 7.86858C7.36122 7.86779 7.61571 7.64329 7.61665 7.38378"
        fill="#65BD51"
        stroke="#65BD51"
        strokeWidth="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58303 11.9976C6.24115 11.9976 5.90649 11.9673 5.58166 11.9092C4.44287 11.7057 3.42504 11.1613 2.64164 10.3876C1.62778 9.39793 1 8.02705 1 6.51241V6.49931V6.48757C1 4.97294 1.62754 3.60229 2.64109 2.61293C3.64841 1.61684 5.04382 1 6.58549 1H6.59882H6.61074C8.15241 1 9.54751 1.61654 10.5545 2.61233C11.5684 3.60199 12.1962 4.97294 12.1962 6.48759V6.50069V6.51242C12.1962 6.53615 12.1961 6.55984 12.1958 6.5835H11.1957C11.196 6.55985 11.1962 6.53615 11.1962 6.51242V6.50069V6.48759C11.1962 5.25602 10.6871 4.13917 9.85602 3.32792L9.85139 3.32341L9.85141 3.32339C9.02582 2.50702 7.88114 2 6.61074 2H6.59882H6.58549C5.31521 2 4.17019 2.50723 3.34422 3.32399L3.33962 3.32854L3.33961 3.32852C2.50892 4.13939 2 5.2559 2 6.48757V6.49931V6.51241C2 7.74398 2.50908 8.86076 3.34016 9.67202L3.34433 9.67612C4.16965 10.4912 5.31325 10.9974 6.58236 10.9976H6.58303L6.59674 10.9976L6.77066 10.9973C7.35437 10.9777 7.90942 10.8508 8.4165 10.6365V11.7053C8.17445 11.787 7.92486 11.8527 7.66904 11.9011C7.32642 11.966 6.97261 12 6.61074 12H6.60644H6.59813H6.59741L6.59813 11.9976L6.59741 11.9976L6.58915 11.9976L6.58303 11.9976Z"
        fill="#65BD51"
      />
    </svg>
  );
};

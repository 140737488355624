import { PanelPlacementData } from '@/models';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import Konva from 'konva';
import React, { useEffect, useRef, useState } from 'react';
import { Group, Rect, Text } from 'react-konva';
import { useParams } from 'react-router';

interface FacadeDesignerWallPanelIdProps {
  panelData: PanelPlacementData;
  scale: number;
}

const FacadeDesignerWallPanelRectId: React.FC<
  FacadeDesignerWallPanelIdProps
> = ({ scale, panelData }) => {
  const { id } = useParams();
  const [textWidth, setTextWidth] = useState(0);
  const panelInfo = useGetAllPanelsQuery(id!).data?.find(
    (panel) => panel?.id === panelData.panelId
  );

  const textRef = useRef<Konva.Text>(null);

  useEffect(() => {
    if (textRef.current) {
      const measuredWidth = textRef.current.width();
      setTextWidth(measuredWidth);
    }
  }, [panelInfo]);

  if (!panelInfo) return null;

  return (
    // I don't use there Html component because it will not be shown in snapshot
    <Group
      x={panelData.offsetFromLeftEdge}
      y={0}
      scaleX={1 / scale}
      scaleY={1 / scale}
    >
      <Rect width={textWidth + 2} height={11} fill="#ECEEEE" />
      <Text
        ref={textRef}
        text={`P${panelInfo.id}`}
        fontSize={8}
        fill="#676569"
        align="center"
        verticalAlign="middle"
        x={1}
        y={2}
      />
    </Group>
  );
};

export default FacadeDesignerWallPanelRectId;

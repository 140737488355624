import { Html } from 'react-konva-utils';

import React, { useMemo } from 'react';
import { Group } from 'react-konva';
import { compact } from 'lodash';

interface MeasurementErrorProps {
  scale: number;
  //TODO: Can be combined later. Experiencing rerender issues with Measurement component and extracting field errors
  formErrors: string[];
  customError?: string;
}
const KonvaErrorList = ({
  scale,
  customError,
  formErrors,
}: MeasurementErrorProps) => {
  const errorList = useMemo(() => {
    return compact(Array.from(new Set([...formErrors, customError])));
  }, [customError, formErrors]);
  return (
    <Group>
      <Html
        divProps={{
          style: {
            position: 'absolute',
            top: `${60}px`,
            left: `0`,
            scale: 1 / scale,
            display: 'flex',
            justifyContent: 'center',
            transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
            width: `100%`,
          },
        }}
      >
        {
          <div className="leading-5 py-0.5 px-2 text-white bg-red rounded flex flex-col text-center">
            {errorList.map((err, idx) => (
              <span key={idx}>{err}</span>
            ))}
          </div>
        }
      </Html>
    </Group>
  );
};

export default KonvaErrorList;

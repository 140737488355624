import { useNavigate, useParams } from 'react-router';

import { IntusLoader } from '@/shared/elements';
import React, { useEffect } from 'react';
import { useFetchProjectByDraftIdQuery } from '@/store/apis/blueScreenApi';

const BluesScreenRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useFetchProjectByDraftIdQuery(id!);

  useEffect(() => {
    if (data.isError) {
      navigate('/', { replace: true });
    }
    if (data.isSuccess) {
      navigate(`/dashboard/projects/${data.currentData}`);
    }
  }, [data]);

  return <IntusLoader loading />;
};

export default BluesScreenRedirect;

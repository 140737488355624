import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import UncoloredFacade from '../icons/UncoloredFacade';
const TextureView = () => {
  return (
    <ToolbarButton
      id="toolbar-button-texture-view"
      icon={<UncoloredFacade />}
      tooltipText={'Switch to Uncolored facades'}
      disabled
    />
  );
};

export default TextureView;

import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import {
  CustomUserData,
  DrawModes,
  FlatVector3,
  NodeType,
  SelectedNode,
  SurroundingBuilding,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { flattenDeep } from 'lodash';

export interface SurroundingBuildingSearchResults
  extends SurroundingBuilding,
    SearchResults {}

export interface SearchResults {
  name: string;
  guid: string;
  userData?: CustomUserData;
  nodeType: NodeType;
  //TODO: Refactor parent nodes from array to dictionary
  parentNodes: SelectedNode[];
  childNodes: SelectedNode[];
  isParentLocked?: boolean;
}

export interface StoreySearchResults
  extends SearchResults,
    UserBuildingStorey {}

export interface BuildingSearchResults
  extends SearchResults,
    UserBuildingCoordinatesObject {
  roofPoints: FlatVector3[];
  drawMode: DrawModes;
  groundFloorPoints: FlatVector3[];
}

export interface BlockSearchResults extends SearchResults, UserBuildingBlock {
  roofPoints: FlatVector3[];
  storeysCount: number;
  groundFloorPoints: FlatVector3[];
}

export interface WallSearchResults extends SearchResults, UserBuildingWall {}

export const useFindNodeData = () => {
  const { id } = useParams();
  const { data } = useFetchProjectQuery(id!);

  const findDataForWall = (guid: string): WallSearchResults | null => {
    const building = data?.buildings?.find((building) =>
      building.blocks.some((block) =>
        block.storeys.some((storey) =>
          storey.walls.some((wall) => wall.guid === guid)
        )
      )
    );
    if (!building) return null;

    const block = building.blocks.find((block) =>
      block.storeys.find((storey) =>
        storey.walls.find((wall) => wall.guid === guid)
      )
    );

    if (!block) return null;

    const storey = block?.storeys.find((storey) =>
      storey.walls.find((wall) => wall.guid === guid)
    );

    if (!storey) return null;

    const wall = storey?.walls.find((wall) => wall.guid === guid);

    if (!wall) return null;

    return {
      ...wall,
      nodeType: NodeType.Wall,
      parentNodes: [
        {
          type: NodeType.Storey,
          guid: storey.guid,
          userData: storey.userData,
        },
        {
          type: NodeType.Building,
          guid: building.guid,
          userData: building.userData,
        },
        {
          type: NodeType.Block,
          guid: block.guid,
          userData: block.userData,
        },
      ],
      childNodes: [],
    };
  };

  const findDataForBlock = (guid: string): BlockSearchResults | null => {
    const building = data?.buildings?.find((building) =>
      building.blocks.some((block) => block.guid === guid)
    );
    if (!building) return null;

    const block = building.blocks.find((block) => block.guid === guid);

    if (!block) return null;

    const childNodes: SelectedNode[] = flattenDeep(
      block.storeys.map((storey) => [
        {
          type: NodeType.Storey,
          guid: storey.guid,
          userData: storey.userData,
        },
        ...storey.walls.map((wall) => ({
          type: NodeType.Wall,
          guid: wall.guid,
          userData: wall.userData,
        })),
      ])
    );

    return {
      ...block,
      nodeType: NodeType.Block,
      parentNodes: [
        {
          type: NodeType.Building,
          guid: building.guid,
          userData: building.userData,
        },
      ],
      childNodes: childNodes,
      storeysCount: block.storeys.length,
      roofPoints: block.storeys[block.storeys.length - 1].ceiling.points,
      groundFloorPoints: block.storeys[0].floor.points,
      isParentLocked: building.userData?.isLocked,
    };
  };

  const findDataForStorey = (guid: string): StoreySearchResults | null => {
    const building = data?.buildings?.find((building) =>
      building.blocks.some((block) =>
        block.storeys.some((storey) => storey.guid === guid)
      )
    );
    if (!building) return null;

    const block = building.blocks.find((block) =>
      block.storeys.find((storey) => storey.guid === guid)
    );

    if (!block) return null;

    const storey = block?.storeys.find((storey) => storey.guid === guid);

    if (!storey) return null;

    return {
      ...storey,
      nodeType: NodeType.Storey,
      parentNodes: [
        {
          type: NodeType.Building,
          guid: building.guid,
          userData: building.userData,
        },
        {
          type: NodeType.Block,
          guid: block.guid,
          userData: block.userData,
        },
      ],
      childNodes: storey.walls.map((wall) => ({
        type: NodeType.Wall,
        guid: wall.guid,
      })),
    };
  };

  const findDataForBuilding = (guid: string): BuildingSearchResults | null => {
    const building = data?.buildings?.find(
      (building) => building.guid === guid
    );
    if (!building) return null;

    const childNodes: SelectedNode[] = flattenDeep(
      building.blocks.map((block) => [
        { type: NodeType.Block, guid: block.guid, userData: block.userData },
        ...block.storeys.map((storey) => [
          {
            type: NodeType.Storey,
            guid: storey.guid,
            userData: storey.userData,
          },
          ...storey.walls.map((wall) => ({
            type: NodeType.Wall,
            guid: wall.guid,
            userData: wall.userData,
          })),
        ]),
      ])
    );

    return {
      ...building,
      nodeType: NodeType.Building,
      centerLineCoordinates: building.centerLineCoordinates,
      parentNodes: [],
      childNodes,
      roofPoints:
        building.blocks[0].storeys[building.blocks[0].storeys.length - 1]
          .ceiling.points,
      groundFloorPoints: building.blocks[0].storeys[0].floor.points,
    };
  };

  const findDataForSurroundingBuilding = (
    guid: string
  ): SurroundingBuildingSearchResults | null => {
    if (!data) return null;

    if (data.environment.surroundingBuildings.guid === guid) {
      return {
        guid: data.environment.surroundingBuildings.guid,
        nodeType: NodeType.SurroundingBuilding,
        name: data.environment.surroundingBuildings.name,
        childNodes: data.environment.surroundingBuildings.surroundings.map(
          (sb) => ({
            id: sb.id,
            type: NodeType.SurroundingBuilding,
            guid: sb.guid,
          })
        ),
        parentNodes: [
          {
            type: NodeType.Environment,
            guid: data.environment.guid,
          },
        ],
        userData: data.environment.surroundingBuildings.userData,
      };
    } else {
      const surroundingBuilding =
        data.environment.surroundingBuildings.surroundings.find(
          (b) => b.guid === guid
        );
      if (!surroundingBuilding) return null;
      return {
        guid,
        nodeType: NodeType.SurroundingBuilding,
        childNodes: [],
        parentNodes: [
          {
            guid: data.environment.surroundingBuildings.guid,
            type: NodeType.SurroundingBuilding,
          },
          {
            type: NodeType.Environment,
            guid: data.environment.guid,
          },
        ],
        userData: surroundingBuilding.userData,
        postCode: surroundingBuilding.postCode,
        state: surroundingBuilding.state,
        city: surroundingBuilding.city,
        street: surroundingBuilding.street,
        houseNumber: surroundingBuilding.houseNumber,
        building: surroundingBuilding.building,
        name: surroundingBuilding.name,
        isParentLocked:
          data.environment.surroundingBuildings.userData?.isLocked ||
          data.environment.userData?.isLocked,
      };
    }
  };

  const findDataForEnvironment = (guid: string): SearchResults | null => {
    if (!data) return null;

    if (data.environment.guid === guid) {
      return {
        guid,
        name: data.environment.name,
        nodeType: NodeType.Environment,
        childNodes: [
          {
            type: NodeType.SurroundingBuilding,
            guid: data.environment.surroundingBuildings.guid,
          },
          ...data.environment.surroundingBuildings.surroundings.map((sb) => ({
            type: NodeType.SurroundingBuilding,
            guid: sb.guid,
          })),
          {
            type: NodeType.ConstructionSite,
            guid: data.environment.constructionSite.guid,
          },
        ],
        parentNodes: [],
        userData: data.environment.userData,
        isParentLocked: data.environment.userData?.isLocked,
      };
    }
    return null;
  };

  const findDataForConstructionSite = (guid: string): SearchResults | null => {
    if (!data) return null;

    if (data.environment.constructionSite.guid === guid) {
      return {
        ...data.environment.constructionSite,
        nodeType: NodeType.ConstructionSite,
        childNodes: [],
        parentNodes: [
          {
            type: NodeType.Environment,
            guid: data.environment.guid,
          },
        ],
        userData: data.environment.constructionSite.userData,
        isParentLocked: data.environment.userData?.isLocked,
      };
    }
    return null;
  };

  const getNodeData = ({
    guid,
    nodeType,
  }: {
    guid: string;
    nodeType: NodeType;
  }) => {
    switch (nodeType) {
      case NodeType.Wall: {
        return findDataForWall(guid);
      }
      case NodeType.Storey: {
        return findDataForStorey(guid);
      }
      case NodeType.Block: {
        return findDataForBlock(guid);
      }
      case NodeType.Building: {
        return findDataForBuilding(guid);
      }
      case NodeType.SurroundingBuilding: {
        return findDataForSurroundingBuilding(guid);
      }
      case NodeType.Environment: {
        return findDataForEnvironment(guid);
      }
      case NodeType.ConstructionSite: {
        return findDataForConstructionSite(guid);
      }
      default: {
        return null;
      }
    }
  };
  return {
    getNodeData,
    findDataForBlock,
    findDataForBuilding,
    findDataForEnvironment,
    findDataForStorey,
    findDataForSurroundingBuilding,
    findDataForConstructionSite,
    findDataForWall,
  };
};

import React from 'react';
import { Navigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { BlueScreenRoutes, InternalRoutes } from '@/core/Routes';

const Layout = () => {
  return (
    <>
      <div className="flex-1 h-full max-h-[calc(100%-50px)]">
        <Routes>
          {InternalRoutes}
          {BlueScreenRoutes}
          <Route
            path="*"
            element={<Navigate to={'/dashboard/projects'} replace />}
          />
        </Routes>
      </div>
    </>
  );
};
export default Layout;

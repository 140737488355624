import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Header from '@/core/Header';

const ProtectedRoute = ({
  hasHeader,
  component,
  ...args
}: {
  hasHeader?: boolean;
  component: () => JSX.Element;
}) => {
  const Component = withAuthenticationRequired(component, args);
  const HeaderComponent = withAuthenticationRequired(Header, args);
  return (
    <>
      {hasHeader && <HeaderComponent />}
      <Component />
    </>
  );
};

export default ProtectedRoute;

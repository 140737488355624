import { convertFtInchToMillimeters } from '@/shared/helpers/distance';

export const convertInputValueToMillimeters = (
  value: string,
  isNumeric: boolean
) => {
  let result;

  if (isNumeric) {
    result = value
      ?.toString()
      .match(/^-?[0-9]*\.?[0-9]*$/)
      ?.join('');
  } else {
    result = convertFtInchToMillimeters(value).toString();
  }

  return !result ? '0' : result;
};

export const directionalInputFontSize = 12;

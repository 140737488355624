import React from 'react';
import { useParams } from 'react-router';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import useReportCalculations from '@/shared/hooks/useReportCalculations';

const PanelProperties = ({ snapshots }: { snapshots: string[] }) => {
  const { id } = useParams();
  const { getAreaUnit } = useFrameProperties();
  const { data: panelsData } = useGetAllPanelsQuery(id!);
  const { getFacadesAreaMetricForBlocks } = useFrameProperties();

  const {
    countPanelsInBuildings,
    countAmountOfWindowsInBuilding,
    countKindOfWindows,
    countPanelizedAreaData,
  } = useReportCalculations();
  const panelTypeCounts = countPanelsInBuildings(panelsData ?? []);
  const projectData = useFetchProjectQuery(id!).data!;
  const windowsData = useFetchWindowsQuery(id!).data!;
  const windowsTypeCounts = countKindOfWindows(windowsData);
  const panelizedAreaData = countPanelizedAreaData(panelsData!);

  return (
    <div className="h-full w-full flex relative flex-col ">
      <div className="px-6 py-9 box-border grid grid-cols-2 grid-rows-2 relative gap-3 bg-[#ECEEEE] flex-1 overflow-hidden">
        {snapshots.map((snapshot, index) => {
          return (
            <div
              key={index}
              className="overflow-hidden flex justify-center items-center w-full h-full"
            >
              <img
                src={snapshot}
                alt={`Snapshot ${index}`}
                className="max-w-full max-h-full"
              />
            </div>
          );
        })}
        <div className="absolute bottom-0 left-0 bg-[#F9F9F9] px-2 py-1 font-light leading-4 text-[10px] text-dark-gray-100">
          Areas marked in red are out of scope and not included into quote
        </div>
      </div>
      <div className="flex mt-auto text-[10px] px-5 leading-4 gap-5">
        <div className="flex-1 py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Panel metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Panel count</div>
              <div>{panelTypeCounts.panelsCount}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Straight panels</div>
              <div>{panelTypeCounts.straight}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Corner panels</div>
              <div>{panelTypeCounts.corner}</div>
            </div>
          </div>
        </div>
        <div className="flex-1 py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Panels area metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Facades area</div>
              <div className="flex gap-1">
                <div>
                  {getFacadesAreaMetricForBlocks(
                    projectData.buildings![0].blocks
                  )}
                </div>
                <div className="font-light text-dark-gray-60 w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Panelized area</div>
              <div className="flex gap-1">
                <div className="flex gap-1 whitespace-nowrap">
                  {panelizedAreaData.panelizedArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {panelizedAreaData.panelizedAreaUsage}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-2">
              <div className="font-light whitespace-nowrap">
                Non-panelized area
              </div>
              <div className="flex gap-1">
                <div className="inline-flex gap-1 whitespace-nowrap">
                  {panelizedAreaData.nonPanelizedArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {panelizedAreaData.nonPanelizedAreaUsage}{' '}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-px h-full bg-light-gray-20" />
        <div className="flex-1 py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Window metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Windows count</div>
              <div>{countAmountOfWindowsInBuilding()}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Operable windows</div>
              <div>{windowsTypeCounts.operable}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Non-operable windows</div>
              <div>{windowsTypeCounts.fixed}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelProperties;

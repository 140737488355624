import React from 'react';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import logo from '@/images/logo-small.svg';
import ReportWrapper from '../ReportWrapper';

const ReportCover = ({
  backgroundImageUrl,
}: {
  backgroundImageUrl: string | null;
}) => {
  const { id } = useParams();

  const projectData = useFetchProjectQuery(id!).data!;
  return (
    <ReportWrapper>
      <div
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
        }}
        className="bg-top h-full flex flex-col justify-between text-white w-full"
      >
        <div className="p-6 flex gap-3">
          <img src={logo} alt="logo" className="w-[26px] h-[26px]" />
          <div className="flex flex-col gap-1">
            <div className="text-[8px] leading-[8px]">powered by</div>
            <div className="text-sm leading-[14px] ">WEB DESIGNER</div>
          </div>
        </div>
        <div
          className="h-[152px] box-border p-6"
          style={{
            background: '#41404266',
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="font-medium text-[11px] leading-4 text-[#FFFFFFBF]">
              # {id}
            </div>
            <div className="text-2xl font-medium leading-9">
              {projectData.name}
            </div>
            <div className="text-xs leading-[18px] text-[#FFFFFFBF]">
              {projectData.address}
            </div>
          </div>
        </div>
      </div>
    </ReportWrapper>
  );
};

export default ReportCover;

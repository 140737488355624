import React, { useEffect, useRef } from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import { CompassIcon } from '@/components/Toolbar/icons/CompassIcon';
import { getCameraDirection } from '@/store/slices/canvasCamerasSlice';
import { useAppSelector } from '@/store/hooks';
import { approximatelyEqual } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { publish } from '@/core/events';
import { ROTATE_CAMERA_NORTH } from '@/core/event-names';

const Compass = () => {
  const compassRef = useRef<HTMLDivElement>(null);
  const cameraDirection = useAppSelector(getCameraDirection);

  useEffect(() => {
    if (compassRef.current) {
      const degrees = cameraDirection.theta * (180 / Math.PI);
      compassRef.current.style.transform = `rotate(${degrees - 180}deg)`;
    }
  }, [cameraDirection.theta]);

  const handleClick = () => {
    publish(ROTATE_CAMERA_NORTH);
  };

  return (
    <ToolbarButton
      onClick={handleClick}
      id="toolbar-button-compass"
      icon={
        <div ref={compassRef}>
          <CompassIcon
            northOriented={approximatelyEqual(cameraDirection.theta, -Math.PI)}
          />
        </div>
      }
      tooltipText={'North Up'}
    />
  );
};

export default Compass;

import { convertSquareMetersToSquareFeet } from '@/shared/helpers/distance';
import { addSpacesToThousands } from '@/shared/helpers/format-data';

export function getCommonValue(values: string[], commonValue: string = '-') {
  return values.every((value) => value && value === values[0])
    ? values[0]
    : commonValue;
}

export const calculateCommonMetric = <T>({
  nodes,
  calculateMetricFunction,
}: {
  nodes: T[];
  calculateMetricFunction: (item: T) => string;
}) => {
  const metrics = nodes.map(calculateMetricFunction);
  return getCommonValue(metrics);
};

export const calculateCommonArea = <T>({
  nodes,
  calculateAreaFunction,
  isImperialUnits,
}: {
  nodes: T[];
  calculateAreaFunction: (item: T) => number;
  isImperialUnits: boolean;
}) => {
  const totalArea = nodes
    .reduce((acc, item) => {
      return acc + calculateAreaFunction(item);
    }, 0)
    .toFixed(2);

  return formatAreaValue(Number(totalArea), isImperialUnits);
};

export const formatAreaValue = (
  valueInMeters: number,
  isImperialUnits: boolean
) => {
  return isImperialUnits
    ? addSpacesToThousands(
        convertSquareMetersToSquareFeet(valueInMeters),
        isImperialUnits
      )
    : addSpacesToThousands(valueInMeters.toString(), isImperialUnits);
};

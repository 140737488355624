import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { getWindowWidth } from '@/shared/helpers';
import { useParams } from 'react-router';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getPlacementErrors,
  setPlacementError,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { inRange } from '@/shared/helpers/distance';
import { CornerGridAlign, WindowPlacementData } from '@/models';
import { SavedWindow } from '@/components/WindowCreator/models';
import {
  GRID_LINE_CROSS_CORNER,
  GRID_LINE_CROSS_WINDOW,
  WINDOW_CROSS_CORNER,
  WINDOW_CROSS_GRID_LINE,
  WINDOW_CROSS_WINDOW,
} from '@/shared/form/validators';
import { round } from 'mathjs';

export const useFDElementValidation = (
  wallData: WallSearchResults,
  wallWidth: number,
  elementOffset: number,
  ignoredWindowsZones?: WindowPlacementData[]
) => {
  const { id } = useParams();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const placementErrors = useAppSelector(getPlacementErrors);
  const wallError = placementErrors[wallData.guid];
  const dispatch = useAppDispatch();

  const windowOccupiedZones: [number, number][] = wallData.windowPlacements
    .filter((window) =>
      (ignoredWindowsZones ?? [])?.every(
        (i_w) => i_w.windowId !== window.windowId
      )
    )
    .map((placement) => {
      const windowData = windowsData.find(
        (data) => data.id === placement.windowId
      )!;
      return [
        placement.offsetFromLeftEdge,
        round(placement.offsetFromLeftEdge + getWindowWidth(windowData), 2),
      ];
    });

  const validateWindowPlacement = (windowData: SavedWindow) => {
    if (
      // Checking if any window intersects placed window dimensions
      windowOccupiedZones.some(
        (occZone) =>
          inRange(
            occZone[0],
            elementOffset,
            elementOffset + getWindowWidth(windowData),
            false
          ) ||
          inRange(
            occZone[1],
            elementOffset,
            elementOffset + getWindowWidth(windowData),
            false
          )
      ) ||
      // Window overlaps itself or
      // Window overlaps another one completely
      windowOccupiedZones.some(
        (occZone) =>
          elementOffset >= occZone[0] &&
          round(elementOffset + getWindowWidth(windowData), 2) <= occZone[1]
      )
    ) {
      wallError?.message !== WINDOW_CROSS_WINDOW &&
        dispatch(
          setPlacementError({
            key: wallData.guid,
            state: true,
            message: WINDOW_CROSS_WINDOW,
          })
        );
    } else if (
      // Checking if any grid line is within placed window dimensions
      wallData.gridLines.some((gridLine) =>
        inRange(
          gridLine.offsetFromLeftEdge,
          elementOffset,
          elementOffset + getWindowWidth(windowData),
          false
        )
      )
    ) {
      wallError?.message !== WINDOW_CROSS_GRID_LINE &&
        dispatch(
          setPlacementError({
            key: wallData.guid,
            state: true,
            message: WINDOW_CROSS_GRID_LINE,
          })
        );
    } else if (
      // Checking if window touched corners
      wallData.gridLines
        .filter((gridLine) => gridLine.cornerAlign)
        .some(
          (gridLine) =>
            inRange(
              elementOffset,
              gridLine.cornerAlign === CornerGridAlign.Left ? 0 : wallWidth,
              gridLine.offsetFromLeftEdge,
              false
            ) ||
            inRange(
              elementOffset + getWindowWidth(windowData),
              gridLine.cornerAlign === CornerGridAlign.Left ? 0 : wallWidth,
              gridLine.offsetFromLeftEdge,
              false
            )
        )
    ) {
      wallError?.message !== WINDOW_CROSS_CORNER &&
        dispatch(
          setPlacementError({
            key: wallData.guid,
            state: true,
            message: WINDOW_CROSS_CORNER,
          })
        );
    } else {
      wallError?.state &&
        dispatch(setPlacementError({ key: wallData.guid, state: false }));
    }
  };

  const validateGridPlacement = () => {
    const isOverlapWindow = windowOccupiedZones.some((occZone) =>
      inRange(elementOffset, occZone[0], occZone[1], false)
    );
    const leftBoundary = wallData.gridLines.find(
      (gridLine) => gridLine.cornerAlign === CornerGridAlign.Left
    )?.offsetFromLeftEdge;

    const rightBoundary = wallData.gridLines.find(
      (gridLine) => gridLine.cornerAlign === CornerGridAlign.Right
    )?.offsetFromLeftEdge;

    if (isOverlapWindow) {
      wallError?.message !== GRID_LINE_CROSS_WINDOW &&
        dispatch(
          setPlacementError({
            key: wallData.guid,
            state: true,
            message: GRID_LINE_CROSS_WINDOW,
          })
        );
    } else if (
      !inRange(elementOffset, leftBoundary!, rightBoundary!) &&
      inRange(elementOffset, 0, wallWidth)
    ) {
      wallError?.message !== GRID_LINE_CROSS_CORNER &&
        dispatch(
          setPlacementError({
            key: wallData.guid,
            state: true,
            message: GRID_LINE_CROSS_CORNER,
          })
        );
    } else {
      wallError?.state &&
        dispatch(setPlacementError({ key: wallData.guid, state: false }));
    }
  };

  const anyWallHasPlacementError = useMemo(
    () => Object.values(placementErrors).some((v) => v.state),
    [placementErrors]
  );

  return {
    placementErrors,
    anyWallHasPlacementError,
    validateGridPlacement,
    validateWindowPlacement,
  };
};

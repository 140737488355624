import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import MoveIcon from '@/components/Toolbar/icons/MoveIcon';
import { CanvasActionsModes } from '@/models';
import { useMouseMode } from '@/shared/hooks/useMouseMode';

const MoveMode = () => {
  const { onClick, isActive } = useMouseMode(CanvasActionsModes.move);

  return (
    <ToolbarButton
      id="toolbar-button-move-mode"
      icon={<MoveIcon />}
      tooltipText={'Move'}
      tooltipSubText={'RMB'}
      onClick={onClick}
      isActive={isActive}
    />
  );
};

export default MoveMode;

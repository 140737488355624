import React from 'react';

export const StraightPanelIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8376 12.9536L6.43762 16.7741C6.23795 16.8772 6 16.7322 6 16.5075V6.31292C6 6.20069 6.06265 6.09784 6.16238 6.04635L13.5624 2.22593C13.7621 2.12285 14 2.26779 14 2.4925V12.6871C14 12.7993 13.9374 12.9021 13.8376 12.9536Z"
        stroke="#414042"
        strokeLinejoin="round"
      />
    </svg>
  );
};

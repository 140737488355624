import { ProjectStructure, UnitSystemTypes } from '@/models';
import { ReportStatus } from '@/models/report';
import { CustomModalProps } from '@/models/shared.model';
import { IntusModal, IntusSwitcher } from '@/shared/elements';
import { Form, FormInstance } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import React, { useEffect, useMemo } from 'react';
import ErrorContent from './content/ErrorContent';
import ReadyContent from './content/ReadyContent';
import ProcessContent from './content/ProcessContent';
import GenerateContent from './content/GenerateContent';
import { getStatusReport } from '@/store/slices/reportSlice';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import moment from 'moment';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';

interface WrapperModalContentProps extends CustomModalProps {
  data: ProjectStructure;
  percent: number;
  generationTime: string | null;
}

const WrapperModalContent: React.FC<WrapperModalContentProps> = ({
  data,
  percent,
  setIsOpen,
  generationTime,
  ...rest
}) => {
  const { id } = useParams();

  if (!id) return <></>;

  const [form] = useForm<{ units: string }>();
  const link = document.createElement('a');
  const initialMeasurementSystem: UnitSystemTypes = data.unitSystem;
  const statusReport = useAppSelector((state) => getStatusReport(state, id));
  const { clearAllSelectedNodes } = useSelectedNodes();
  const isProcessing = [
    ReportStatus.PROCESSING_AI_GENERATION,
    ReportStatus.PROCESSING_DATA_COLLECTION,
    ReportStatus.PROCESSING_CREATE_REPORT,
  ].includes(statusReport);

  const handlePDFExport = () => {
    if (data.report.fileUrl) {
      link.href = data.report.fileUrl;
      link.download = data.report.fileName || 'document.pdf';
      link.target = '_blank';
      link.click();
    }
  };

  const modals = useMemo(() => {
    switch (statusReport) {
      case ReportStatus.PROCESSING_AI_GENERATION:
      case ReportStatus.PROCESSING_DATA_COLLECTION:
      case ReportStatus.PROCESSING_CREATE_REPORT:
        return <ProcessContent percent={percent} />;
      case ReportStatus.ERROR:
        return <ErrorContent />;
      case ReportStatus.READY:
        return <ReadyContent handlePDFExport={handlePDFExport} />;
      case ReportStatus.DEFAULT:
      default:
        return <GenerateContent />;
    }
  }, [statusReport, percent]);

  useWatch(['units'], form);

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        units: initialMeasurementSystem,
      });
    }
    clearAllSelectedNodes();
  }, []);

  return (
    <>
      <IntusModal
        centered
        open
        footer={null}
        styles={{ body: { padding: 0 } }}
        onCancel={() => handleCancel()}
        maskClosable={!isProcessing}
        closable={!isProcessing}
        keyboard={!isProcessing}
        width={580}
        {...rest}
      >
        <Form
          layout="vertical"
          form={form}
          className="flex flex-col items-center pt-[60px]"
        >
          <Title status={statusReport} />
          {modals}
          <Footer
            generationTime={generationTime}
            creationDateTime={data.report.creationDateTime}
            form={form}
            status={statusReport}
          />
        </Form>
      </IntusModal>
    </>
  );
};

const Title = ({ status }: { status: ReportStatus }) => {
  switch (status) {
    case ReportStatus.PROCESSING_DATA_COLLECTION:
    case ReportStatus.PROCESSING_AI_GENERATION:
    case ReportStatus.PROCESSING_CREATE_REPORT:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Report generation in progress...
        </h3>
      );
    case ReportStatus.READY:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Report is ready
        </h3>
      );
    case ReportStatus.ERROR:
      return (
        <p className="flex flex-col items-center gap-4 m-0 mb-10">
          <span className="font-medium text-base leading-6 m-0 text-dark-gray-100">
            Generation error
          </span>
          <span className="text-xs text-normal leading-5 m-0 text-dark-gray-100">
            Error text
          </span>
        </p>
      );
    default:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Report generation
        </h3>
      );
  }
};

const Footer = ({
  status,
  form,
  generationTime,
  creationDateTime,
}: {
  status: string;
  form: FormInstance<{
    units: string;
  }>;
  creationDateTime: string;
  generationTime: string | null;
}) => {
  switch (status) {
    case ReportStatus.PROCESSING_DATA_COLLECTION:
    case ReportStatus.PROCESSING_AI_GENERATION:
    case ReportStatus.PROCESSING_CREATE_REPORT:
      return (
        <div className="w-full mt-auto flex flex-col py-3 bg-light-gray-10">
          <p className="text-xs font-medium leading-5 m-0 px-6 text-dark-gray-10">
            Project editing is blocked
          </p>
          <p className="text-xs font-normal leading-5 m-0 px-6 text-dark-gray-10">
            To make changes to a project, duplicate it using the context menu in
            the project dashboard
          </p>
        </div>
      );
    case ReportStatus.READY:
      return (
        <div className="w-full mt-auto flex flex-col py-[14px] bg-light-gray-10">
          <p className="text-xs leading-5 font-normal text-dark-gray-100 m-0 ml-6">
            The report was generated on{' '}
            {moment(creationDateTime ?? generationTime).format(
              'MMMM D, YYYY [at] h:mm A'
            )}
          </p>
        </div>
      );
    case ReportStatus.ERROR:
      return (
        <div className="w-full mt-auto flex flex-col py-[14px] bg-light-gray-10">
          <p className="text-xs font-normal leading-5 m-0 px-6 text-dark-gray-100">
            There was an error and report generation failed. Please try again
          </p>
        </div>
      );
    default:
      return (
        <div className="w-full mt-auto flex items-center justify-between py-2 bg-light-gray-10">
          <p className="text-xs leading-5 m-0 ml-6 text-dark-gray-100">
            Report units of measure
          </p>
          <div className="bg-light-gray-20 rounded-lg w-fit mr-6">
            <Form.Item className="m-0" name="units">
              <IntusSwitcher
                onChange={(val) =>
                  form.setFieldValue('units', val as UnitSystemTypes)
                }
                data={[
                  { name: 'ft inch', value: UnitSystemTypes.Imperial },
                  { name: 'mm', value: UnitSystemTypes.Metric },
                ]}
              />
            </Form.Item>
          </div>
        </div>
      );
  }
};

export default WrapperModalContent;

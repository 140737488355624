import React from 'react';

export const DockRow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.5 3C2.67157 3 2 3.67157 2 4.5V11.5C2 12.3284 2.67157 13 3.5 13H6.5C7.32843 13 8 12.3284 8 11.5V4.5C8 3.67157 7.32843 3 6.5 3H3.5ZM3 4.5C3 4.22386 3.22386 4 3.5 4H5.5H6.5C6.77614 4 7 4.22386 7 4.5V11.5C7 11.7761 6.77614 12 6.5 12H5.5H3.5C3.22386 12 3 11.7761 3 11.5V4.5ZM9 4.5C9 3.67157 9.67157 3 10.5 3H16.5C17.3284 3 18 3.67157 18 4.5V15.5C18 16.3284 17.3284 17 16.5 17H10.5C9.67157 17 9 16.3284 9 15.5V4.5ZM10.5 4C10.2239 4 10 4.22386 10 4.5V15.5C10 15.7761 10.2239 16 10.5 16H13H14H16.5C16.7761 16 17 15.7761 17 15.5V4.5C17 4.22386 16.7761 4 16.5 4H14H13H10.5Z"
        fill="#414042"
      />
      <path d="M13 4V16H14V4H13Z" fill="#414042" />
    </svg>
  );
};

import React from 'react';
import { IntusButton } from '@/shared/elements';
import { ReportErrorIcon } from '@/shared/icons/ReportErrorIcon';
import { useAppDispatch } from '@/store/hooks';
import { setQuoteRequestStatus } from '@/store/slices/quoteSlice';
import { QuoteStatus } from '@/models/quote.model';

export const QuoteErrorContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleRequestQuote = () => {
    dispatch(setQuoteRequestStatus(QuoteStatus.PROCESSING_EXPORT));
  };

  return (
    <>
      <ReportErrorIcon className="mb-10" />
      <IntusButton
        id="report__repeat-generation-button"
        className="rounded-lg py-[5px] px-[18px] mb-[92px]"
        onClick={handleRequestQuote}
      >
        Request again
      </IntusButton>
    </>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { QuoteStatus } from '@/models/quote.model';

interface QuoteState {
  status: QuoteStatus;
}

const initialState: QuoteState = {
  status: QuoteStatus.DEFAULT,
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuoteRequestStatus: (state, { payload }: PayloadAction<QuoteStatus>) => {
      state.status = payload;
    },
  },
});

export const { setQuoteRequestStatus } = quoteSlice.actions;

export const getQuoteRequestStatusReport = (state: RootState) =>
  state.canvasReducer.quote.status;

import { IntusButton } from '@/shared/elements';
import React from 'react';
import { ReportReadyIcon } from '@/shared/icons/ReportReadyIcon';

interface QuoteReadyContentProps {
  setIsOpen: (value: boolean) => void;
}

export const QuoteReadyContent: React.FC<QuoteReadyContentProps> = ({
  setIsOpen,
}) => {
  const handleBackToProject = () => {
    setIsOpen(false);
  };

  return (
    <div className={'mb-[122px]'}>
      <ReportReadyIcon className="mb-10" />
      <div className="flex flex-col gap-2 w-full max-w-60">
        <IntusButton
          id="quote-modal_back-to-project-button"
          isActive={true}
          onClick={handleBackToProject}
        >
          Back to project
        </IntusButton>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { DemoProject } from '@/models';
import { IntusButton, IntusLoader, IntusSearch } from '@/shared/elements';
import { useFetchProjectsQuery } from '@/store/apis/projectsApi';
import { useDebouncedCallback } from 'use-debounce';
import { PlusOutlined } from '@ant-design/icons';
import noProjectIcon from '@/images/no-project-icon.svg';
import noSearchResultsIcon from '@/images/no-search-results-icon.svg';
import buildingSVG from '@/images/building.svg';
import schoolImg from '@/images/demo-projects/school.jpg';
import residentialImg from '@/images/demo-projects/residential.jpg';
import officeImg from '@/images/demo-projects/office.jpg';
import seniorImg from '@/images/demo-projects/senior.jpg';
import homeSVG from '@/images/home.svg';
import './DashboardProjectsView.scss';
import ProjectCard from './ProjectCard';
import { useNavigate } from 'react-router';
import { ArrowRightIcon } from '@/shared/icons';
import { Form } from 'antd';

const demoProjects: DemoProject[] = [
  {
    name: 'Residential',
    imgSrc: residentialImg,
  },
  {
    name: 'Office',
    imgSrc: officeImg,
  },
  {
    name: 'School',
    imgSrc: schoolImg,
  },
  {
    name: 'Senior Living',
    imgSrc: seniorImg,
  },
];

const DashboardProjectsView = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const { data, isFetching } = useFetchProjectsQuery(searchValue);

  const hasProjects = !!data?.totalCount;

  const searchAfterDebounce = useDebouncedCallback(
    (value: string) => setSearchValue(value),
    500
  );

  const ResetSearchButton = () => (
    <IntusButton
      type={'default'}
      className="mx-auto"
      onClick={resetSearchAction}
      id="dashboard-projects-view__reset-search-button"
    >
      Reset search
    </IntusButton>
  );

  const NoSearchResults = () => (
    <div className="mx-auto text-center">
      <img
        className="height-[165px]"
        alt="no project icon"
        src={noSearchResultsIcon}
      />
      <p className="text-sm mt-6 mb-1 font-medium">
        No results found for “{searchValue}”
      </p>
      <span className="text-sm font-normal">
        Try to adjust your search to find what you are looking for
      </span>
      <div className="mt-4">
        <ResetSearchButton />
      </div>
    </div>
  );

  const projectItems = () => {
    return data?.projects.length ? (
      <div className="grid gap-6 grid-cols-projectCards pb-[60px]">
        {data?.projects.map((project) => (
          <ProjectCard key={project.id + project.name} data={project} />
        ))}
      </div>
    ) : (
      <NoSearchResults />
    );
  };

  const handleCreateActionClick = () =>
    navigate('create', { relative: 'path' });

  const resetSearchAction = () => {
    form.setFieldValue('search', '');
    setSearchValue('');
  };

  const NoProjectView = () => (
    <div className="mx-auto text-center">
      <img
        className="height-[165px]"
        alt="no project icon"
        src={noProjectIcon}
      />
      <p className="text-sm mt-6 mb-1 font-medium">Create your first project</p>
      <span className="text-sm font-normal">
        Fortunately, it’s easy to create new one
      </span>
      <div className="mt-4 mx-auto">
        <IntusButton
          className="mx-auto"
          onClick={handleCreateActionClick}
          id="dashboard-projects-view__create-project-button"
        >
          <PlusOutlined /> New project
        </IntusButton>
      </div>
    </div>
  );

  return (
    <div className="flex h-full">
      <div
        aria-label="projects__sidebar"
        className="w-[210px] border-r border-light-gray-20  border-l-0 border-t-0 border-b-0 border-solid"
      >
        <div aria-label="projects_-sidebar__menu-items" className="mt-2">
          <div className="w-[200px] h-10 ml-auto flex items-center bg-light-green-15 font-normal text-light-green-100 text-sm rounded-l-md">
            <img alt="Home button icon" src={homeSVG} className="mr-2 ml-4" />
            All projects
          </div>
        </div>
      </div>
      <div className="w-full px-6 mx-auto flex flex-col h-full overflow-auto">
        <div
          aria-label="project-lists__demo-projects"
          className="rounded-xl bg-light-gray-10 p-6 pb-0 mt-6"
        >
          <div className="text-sm flex items-center">
            <img src={buildingSVG} alt="building icon" className="mr-1" />
            Explore demo projects
          </div>
          <div className="flex justify-between mt-3 flex-wrap gap-6">
            {demoProjects.map((project) => (
              <div
                className="bg-no-repeat aspect-[1.7] bg-cover flex items-end rounded-xl relative overflow-hidden mb-6 cursor-pointer group flex-1"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(65, 64, 66, 1) 0%, rgba(65, 64, 66, 0) 30%), url(${project.imgSrc})`,
                }}
                key={project.name}
              >
                <div className="text-white font-normal text-sm pl-4 py-2 border-light-gray-20 z-20 flex w-full">
                  <div>{project.name}</div>
                  <div className="ml-auto">
                    <ArrowRightIcon className="mr-5 group-hover:mr-4 duration-300" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div
            aria-label="project-lists__header font-medium"
            className="flex items-center my-6"
          >
            <h3 className="text-base m-0">
              All projects ({data?.projects.length || 0})
            </h3>
            <div className="ml-auto flex">
              <Form form={form} name="projects">
                <Form.Item name="search" shouldUpdate className="m-0">
                  <IntusSearch
                    name="search"
                    disabled={!hasProjects}
                    onChange={(e) => searchAfterDebounce(e.target.value)}
                    placeholder="Search"
                    value={searchValue}
                    className="w-[200px] h-8"
                  />
                </Form.Item>
              </Form>
              <IntusButton
                className="ml-3"
                onClick={handleCreateActionClick}
                id="dashboard-projects-view__create-project-button"
              >
                <PlusOutlined /> New project
              </IntusButton>
            </div>
          </div>
          <div className="flex-1 relative">
            <IntusLoader loading={isFetching}>
              {hasProjects ? projectItems() : <NoProjectView />}
            </IntusLoader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardProjectsView;

import { IntusButton } from '@/shared/elements';
import React from 'react';
import { ReportReadyIcon } from '@/shared/icons/ReportReadyIcon';
import { PDFIcon } from '@/shared/icons/PDFIcon';
import { MailIcon } from '@/shared/icons/MailIcon';
import { useParams } from 'react-router';

interface ReadyContentProps {
  handlePDFExport: () => void;
}

const ReadyContent: React.FC<ReadyContentProps> = ({ handlePDFExport }) => {
  const { id } = useParams();

  if (!id) return <></>;

  return (
    <>
      <ReportReadyIcon className="mb-10" />
      <div className="flex flex-col gap-2 mb-[53px] w-full max-w-60">
        <IntusButton
          id="report__pdg-button"
          onClick={handlePDFExport}
          className="rounded-lg gap-6 py-3 px-6 !h-max !bg-white hover:!bg-light-gray-10 border-light-gray-20 focus:!shadow-none focus:!outline-none"
        >
          <PDFIcon />
          <span className="m-0 leading-[22px] font-normal text-dark-gray-100">
            Download PDF
          </span>
        </IntusButton>
        <IntusButton
          id="report__email-button"
          className="invisible rounded-lg gap-6 py-3 px-6 !h-max !bg-white hover:!bg-light-gray-10 border-light-gray-20 focus:!shadow-none focus:!outline-none"
        >
          <MailIcon />
          <span className="m-0 leading-[22px] text-dark-gray-100">
            Send to email
          </span>
        </IntusButton>
      </div>
    </>
  );
};

export default ReadyContent;

import React, { useEffect, useMemo, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Group, Image, Line } from 'react-konva';
import useImage from 'use-image';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FlatVector2, UnitSystemTypes } from '@/models';
import {
  calculateInnerWindowPoints,
  calculateRealWindowGlassPoints,
  EDGE_POSITION,
  generateInnerFramePoints,
  getWindowOperationTypeViewPoints,
  OPERABLE_BORDER_THICKNESS,
  OUTER_BORDER_THICKNESS,
} from '@/components/WindowCreator/elements/creator-windows.helpers';
import { WindowBorder } from '@/components/WindowCreator/elements';
import WindowGlass from '@/components/WindowCreator/elements/WindowGlass';
import {
  WindowElementType,
  View,
} from '@/components/WindowCreator/models/konva-model';
import {
  getIsWindowNodeSelected,
  getValidationErrors,
  resetHoverArea,
  setHoverArea,
  setValidationError as setValidationErrorReducer,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { OperationType } from '@/models/window-configurator.model';
import { WINDOW_GLASS_SELECTED_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { INITIAL_SCALE } from '@/components/WindowCreator/constants';
import HandleOutsideSVG from '@/images/HandleOutside.svg';
import HandleInsideSVG from '@/images/HandleInside.svg';
import HandleOutsideRotatedSVG from '@/images/HandleOutsideRotated.svg';
import HandleInsideRotatedSVG from '@/images/HandleInsideRotated.svg';
import { get2DCenter } from '@/shared/helpers/konva';
import { validateWindow } from '@/components/WindowCreator/elements/InnerWindow/window-validators';
import PlacementElementError from '@/components/WindowCreator/elements/InnerWindow/PlacementElementError';
import { useWindowCreatorSelect } from '@/components/WindowCreator/elements/shared/useWindowCreatorSelect';

const WINDOW_HANDLE_HEIGHT = 134;
const WINDOW_HANDLE_WIDTH = 25;

interface OperableWindowProps {
  points: FlatVector2[];
  outerFramePoints: FlatVector2[];
  operationType: OperationType;
  frameColor: string;
  independent?: boolean;
  scale: number;
  units: UnitSystemTypes;
  view: View;
}

const OperableWindow = ({
  points,
  operationType,
  outerFramePoints,
  frameColor,
  independent,
  scale,
  units,
  view,
}: OperableWindowProps) => {
  const dispatch = useAppDispatch();
  const isImperialSystem = units === UnitSystemTypes.Imperial;

  const [handleOutsideImage] = useImage(HandleOutsideSVG);
  const [handleInsideImage] = useImage(HandleInsideSVG);
  const [handleOutsideRotatedImage] = useImage(HandleOutsideRotatedSVG);
  const [handleInsideRotatedImage] = useImage(HandleInsideRotatedSVG);

  const glassRef = useRef<Konva.Line>(null);
  const isSelected = useAppSelector(
    getIsWindowNodeSelected(glassRef?.current?._id ?? 0)
  );
  const validationErrors = useAppSelector(
    getValidationErrors(glassRef?.current?._id ?? 0)
  );

  const { selectElementInWindowCreator } = useWindowCreatorSelect(
    glassRef?.current?._id ?? 0,
    points
  );

  const outerContourPoints = useMemo(
    () => calculateInnerWindowPoints(points, outerFramePoints),
    [points, outerFramePoints]
  );

  const handleSelect = (event?: KonvaEventObject<MouseEvent>) => {
    selectElementInWindowCreator(
      WindowElementType.Window,
      outerContourPoints,
      event
    );
  };

  const windowGlassPoints = useMemo(() => {
    return outerContourPoints.map((p, i) =>
      generateInnerFramePoints(p, i, OPERABLE_BORDER_THICKNESS)
    );
  }, [outerContourPoints]);

  const realWindowGlassPoints = useMemo(() => {
    return windowGlassPoints.map((p, i) =>
      calculateRealWindowGlassPoints(p[0], i, 15)
    );
  }, [windowGlassPoints, points]);

  const windowOperationTypeViewPoints = useMemo(
    (): FlatVector2[][] | null =>
      getWindowOperationTypeViewPoints(windowGlassPoints, operationType, view),
    [operationType, outerContourPoints]
  );

  const windowHandlerImage = useMemo(() => {
    switch (operationType) {
      case OperationType.Hopper:
      case OperationType.Awning: {
        return view === View.Outside
          ? handleOutsideRotatedImage
          : handleInsideRotatedImage;
      }
      default:
        return view === View.Outside ? handleOutsideImage : handleInsideImage;
    }
  }, [operationType, handleOutsideImage, handleOutsideRotatedImage, view]);

  const windowHandlePoints = useMemo(() => {
    switch (operationType) {
      case OperationType.DualActionLeftTop:
      case OperationType.CasementLeft: {
        const borderCenter =
          view === View.Outside
            ? get2DCenter(
                outerContourPoints[EDGE_POSITION.RIGHT][0],
                outerContourPoints[EDGE_POSITION.RIGHT][1]
              )
            : get2DCenter(
                outerContourPoints[EDGE_POSITION.LEFT][0],
                outerContourPoints[EDGE_POSITION.LEFT][1]
              );

        return {
          x:
            view === View.Outside
              ? borderCenter[0] -
                OPERABLE_BORDER_THICKNESS / 2 -
                WINDOW_HANDLE_WIDTH / 2
              : borderCenter[0] +
                OUTER_BORDER_THICKNESS / 2 +
                WINDOW_HANDLE_WIDTH / 2,
          y: borderCenter[1] - WINDOW_HANDLE_HEIGHT / 2,
        };
      }
      case OperationType.DualActionRightTop:
      case OperationType.CasementRight: {
        const borderCenter =
          view === View.Outside
            ? get2DCenter(
                outerContourPoints[EDGE_POSITION.LEFT][0],
                outerContourPoints[EDGE_POSITION.LEFT][1]
              )
            : get2DCenter(
                outerContourPoints[EDGE_POSITION.RIGHT][0],
                outerContourPoints[EDGE_POSITION.RIGHT][1]
              );
        return {
          x:
            view === View.Outside
              ? borderCenter[0] +
                OUTER_BORDER_THICKNESS / 2 +
                WINDOW_HANDLE_WIDTH / 2
              : borderCenter[0] -
                OPERABLE_BORDER_THICKNESS / 2 -
                WINDOW_HANDLE_WIDTH,
          y: borderCenter[1] - WINDOW_HANDLE_HEIGHT / 2,
        };
      }
      case OperationType.Hopper: {
        const borderCenter = get2DCenter(
          outerContourPoints[EDGE_POSITION.TOP][0],
          outerContourPoints[EDGE_POSITION.TOP][1]
        );
        return {
          x: borderCenter[0] - WINDOW_HANDLE_HEIGHT / 2,
          y:
            borderCenter[1] +
            OPERABLE_BORDER_THICKNESS / 2 -
            WINDOW_HANDLE_WIDTH / 2,
        };
      }
      case OperationType.Awning: {
        const borderCenter = get2DCenter(
          outerContourPoints[EDGE_POSITION.BOTTOM][0],
          outerContourPoints[EDGE_POSITION.BOTTOM][1]
        );
        return {
          x:
            borderCenter[0] -
            OPERABLE_BORDER_THICKNESS / 2 -
            WINDOW_HANDLE_WIDTH / 2,
          y: borderCenter[1] - WINDOW_HANDLE_HEIGHT / 2,
        };
      }
      default:
        return null;
    }
  }, [outerContourPoints, operationType]);

  useEffect(() => {
    const id = glassRef.current!._id;

    const errors = validateWindow(
      realWindowGlassPoints,
      outerContourPoints.map((p) => p[0]),
      isImperialSystem
    );

    dispatch(
      setValidationErrorReducer({
        id,
        errors,
      })
    );

    isSelected && handleSelect();
    return () => {
      dispatch(
        setValidationErrorReducer({
          id,
          errors: [],
        })
      );
    };
  }, [realWindowGlassPoints, isImperialSystem, points, outerContourPoints]);
  return (
    <>
      <Group
        onPointerClick={handleSelect}
        onMouseOver={() =>
          independent && dispatch(setHoverArea(outerContourPoints))
        }
        onMouseLeave={() => independent && dispatch(resetHoverArea())}
      >
        <WindowGlass
          points={[
            windowGlassPoints[0][0],
            windowGlassPoints[1][0],
            windowGlassPoints[2][0],
            windowGlassPoints[3][0],
          ]}
          ref={glassRef}
        />
        {windowOperationTypeViewPoints &&
          windowOperationTypeViewPoints.map((operationPoints, i) => (
            <Line
              points={operationPoints.flat()}
              stroke={WINDOW_GLASS_SELECTED_COLOR}
              opacity={0.2}
              strokeWidth={(INITIAL_SCALE * 6) / scale}
              key={`operationType_${i}`}
            />
          ))}
        {outerContourPoints.length > 0 &&
          outerContourPoints.map((framePoint, i) => (
            <WindowBorder
              idx={i}
              points={framePoint}
              key={`operableWindowFrame_${i}`}
              borderWidth={OPERABLE_BORDER_THICKNESS}
              color={frameColor}
            />
          ))}
        {windowHandlePoints && (
          <Image
            image={windowHandlerImage}
            scaleX={0.25 / INITIAL_SCALE}
            scaleY={0.25 / INITIAL_SCALE}
            x={windowHandlePoints.x}
            y={windowHandlePoints.y}
          />
        )}
      </Group>
      {validationErrors && validationErrors.length > 0 && (
        <PlacementElementError
          position={[outerContourPoints[0][1][0], outerContourPoints[0][1][1]]}
          errorList={validationErrors}
          hoverArea={outerContourPoints}
          scale={scale}
        />
      )}
    </>
  );
};

export default OperableWindow;

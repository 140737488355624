import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import {
  PanelPlacementData,
  UnitSystemTypes,
  UserBuildingPanel,
} from '@/models';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { Group, Rect } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getDragNode,
  getIsPanelHovered,
  getIsPanelSelected,
  setHoveredPlacedPanel,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useFacadeDesignerSelection } from '@/components/FacadeDesigner/hooks';
import { KonvaEventObject } from 'konva/lib/Node';

interface PanelViewProps {
  panelPlacementData?: PanelPlacementData;
  panelInfo: UserBuildingPanel;
  scale: number;
  wallGUID?: string;
  reportView?: boolean;
  panelWidth: number;
  offsetX?: number;
}

const SELECTION_AREA_OFFSET = 15;

const PanelView: React.FC<PanelViewProps> = ({
  panelPlacementData,
  panelInfo,
  offsetX,
  panelWidth,
  scale,
  wallGUID,
  reportView,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const windowsInLibrary = useFetchWindowsQuery(id!).data!;
  const configColors = useFetchWindowConfigQuery().data!.colors;
  const isDragNodeActive = !!useAppSelector(getDragNode);

  const isHovered = panelPlacementData
    ? useAppSelector(getIsPanelHovered(panelPlacementData.guid))
    : false;
  const isSelected = panelPlacementData
    ? useAppSelector(getIsPanelSelected(panelPlacementData.guid))
    : false;

  const { handleSelectPanel, isSelectionMode } = useFacadeDesignerSelection();

  const isAbleToHover = isSelectionMode && !isDragNodeActive;

  const panelColor = useMemo(() => {
    if (panelInfo?.fillerMaterial === 'Glass') return 'rgba(204,213,213,1)';
    return getHexByName(configColors, panelInfo?.frameOutsideColor || '');
  }, [configColors, panelInfo]);

  const handleSelect = (event: KonvaEventObject<PointerEvent>) => {
    if (!panelPlacementData || !wallGUID || !isAbleToHover) return;
    handleSelectPanel({
      panelData: {
        guid: panelPlacementData.guid,
        wallGUID,
        isInitialCorner: !!panelPlacementData.isInitialCorner,
      },
      multiSelect: event.evt.shiftKey,
    });
  };

  if (!panelInfo) return null;

  return (
    <Group onPointerDown={handleSelect} x={offsetX} listening={isAbleToHover}>
      {/* We use this rect in order to add padding for snapshot, in some cases toImage method crop borders */}
      <Rect
        width={panelWidth}
        height={panelInfo.height}
        fill={panelColor}
        stroke={reportView ? '#B3B2B4' : undefined}
        strokeWidth={0.5 / scale}
        onPointerEnter={() =>
          panelPlacementData
            ? dispatch(setHoveredPlacedPanel(panelPlacementData.guid))
            : undefined
        }
      />
      {(isHovered || isSelected) && (
        <>
          <Rect
            x={SELECTION_AREA_OFFSET / 2}
            y={SELECTION_AREA_OFFSET / 2}
            width={panelWidth - SELECTION_AREA_OFFSET}
            height={panelInfo.height - SELECTION_AREA_OFFSET}
            fill={'#65BD51'}
            opacity={0.2}
            onPointerLeave={() => dispatch(setHoveredPlacedPanel(null))}
          />
          <Rect
            x={SELECTION_AREA_OFFSET / 2}
            y={SELECTION_AREA_OFFSET / 2}
            width={panelWidth - SELECTION_AREA_OFFSET}
            height={panelInfo.height - SELECTION_AREA_OFFSET}
            stroke={'#65BD51'}
            strokeWidth={1 / scale}
            onPointerLeave={() => dispatch(setHoveredPlacedPanel(null))}
          />
        </>
      )}
      {panelInfo.panelWindows.map((panelWindow, i) => {
        const windowData = windowsInLibrary.find(
          (window) => window.id === panelWindow.id
        );
        if (!windowData) return null;

        return (
          <WindowView
            data={windowData}
            scale={scale}
            offsetX={panelWindow.offsetFromLeftEdge}
            offsetY={panelInfo.height - windowData.distanceToFloor}
            units={UnitSystemTypes.Metric}
            listening={false}
            key={`panel-window__${panelWindow.id}-${i}`}
          />
        );
      })}
    </Group>
  );
};

export default PanelView;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import EditToolbarButton from './EditToolbarButton';
import { CutIcon, DeleteIcon, MergeIcon, SplitIcon } from './icons';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import {
  constructionElementTypes,
  getStatusByNodeOrParents,
} from '@/shared/helpers/construction-user-data';
import {
  CanvasActionsModes,
  CanvasCameraType,
  EditModes,
  NodeType,
  SelectedNode,
} from '@/models';
import { setEditMode, setMode } from '@/store/slices/canvasModesSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getIsEditToolsAvailable,
  setEditedNode,
  setHoveredNode,
} from '@/store/slices/canvasBuildingSlice';
import {
  resetSelectedWindowFromLibrary,
  setFacadeDesignerMode,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { Html } from '@react-three/drei';
import { findObjectByGuid } from '@/shared/helpers/canvas';
import { Box3, Group, Vector3 } from 'three';
import { useThree } from '@react-three/fiber';
import { getScaleRatioForCameraZoom } from '@/shared/helpers/canvas-verifiers';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { OFFSET_Y, SCALE_FACTOR } from './constants';
import { FacadeDesignerModes } from '@/models/shared.model';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { useMerge } from '@/shared/hooks/useMerge';
import { FacadeDesignerIcon } from '@/shared/icons';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

const EditToolbar = ({
  selectedNodes,
  projectId,
}: {
  projectId: string;
  selectedNodes: SelectedNode[];
}) => {
  const Divider = (
    <div className={'bg-light-gray-10 h-5 mx-0.5 my-auto w-0.5'} />
  );

  const dispatch = useAppDispatch();
  const userBuildingUtils = useUpdateUserBuildingData();
  const { isAllWallsIncludeInOneFacade } = useFacadeData();
  const { isIsolateModeEnabled, isNodeIsolated } = useIsolationHandlers();
  const projectData = useFetchProjectQuery(projectId).data!;
  const { getNodeData } = useFindNodeData();
  const { clearAllSelectedNodes } = useSelectedNodes();
  const { scene, camera, size, controls } = useThree();
  const toolbarRef = useRef<Group>(null);
  const [scale, setScale] = useState(1);
  const selectedNode = selectedNodes[0];
  const isMultiNodesSelected = selectedNodes.length > 1;
  const nodeData = getNodeData({
    guid: selectedNode.guid,
    nodeType: selectedNode.type,
  });

  const buildingGUID =
    selectedNode.type === NodeType.Building
      ? selectedNode.guid
      : nodeData?.parentNodes.find((node) => node.type === NodeType.Building)
          ?.guid;

  const { mergeWalls, validateMergeAvailability, mergeBlocks } = useMerge(
    buildingGUID || ''
  );

  const isIsolated = isNodeIsolated(selectedNode.guid);

  const isNodeHiddenByIsolation = isIsolateModeEnabled ? !isIsolated : false;

  const isAllowedElementSelected = constructionElementTypes.includes(
    selectedNode.type
  );

  const isFacadeDesignerModeAvailable =
    (selectedNodes.length === 1 ||
      isAllWallsIncludeInOneFacade(selectedNodes.map((node) => node.guid))) &&
    [NodeType.Building, NodeType.Block, NodeType.Wall].includes(
      selectedNode.type
    );

  const position = useMemo(() => {
    if (!selectedNodes.length) return new Vector3(0, 0, 0);

    const { sumX, maxY, sumZ } = selectedNodes.reduce(
      (acc, node) => {
        const objectData = findObjectByGuid(scene, node.guid);
        if (!objectData) return acc;

        const bbox = new Box3().setFromObject(objectData);
        const center = bbox.getCenter(new Vector3());
        center.y = bbox.max.y + OFFSET_Y;

        acc.sumX += center.x;
        acc.sumZ += center.z;
        acc.maxY = Math.max(acc.maxY, center.y);

        return acc;
      },
      { sumX: 0, maxY: 0, sumZ: 0 }
    );

    const avgCenter = new Vector3(
      sumX / selectedNodes.length,
      maxY,
      sumZ / selectedNodes.length
    );

    return selectedNodes.length > 1 ? avgCenter : new Vector3(sumX, maxY, sumZ);
  }, [nodeData]);

  const handleClickEnterFacadeDesigner = () => {
    dispatch(setMode(CanvasActionsModes.facadeDesigner));
    dispatch(resetSelectedWindowFromLibrary());
    dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));
    [NodeType.Block, NodeType.Building].includes(selectedNode.type) &&
      clearAllSelectedNodes();
  };

  const handleCameraMove = () => {
    if (!toolbarRef.current) return;

    toolbarRef.current.quaternion.copy(camera.quaternion);

    const center = new Box3()
      .setFromObject(toolbarRef.current)
      .getCenter(new Vector3(0, 0, 0));

    const scaleValue = getScaleRatioForCameraZoom(
      camera,
      size,
      convertFlatVector3ToVector([center.x, center.y, center.z]),
      SCALE_FACTOR
    );

    const restrictedScale = () => {
      const isPerspectiveCamera = camera.type === CanvasCameraType.Perspective;
      const cameraScale = isPerspectiveCamera
        ? 0.5 / scaleValue
        : 1 / scaleValue;

      if (cameraScale < 0.5) return 0.5;

      return cameraScale;
    };

    const newScaleValue = restrictedScale();

    setScale(newScaleValue);
  };

  useEffect(() => {
    controls && handleCameraMove();
    controls?.addEventListener('change', handleCameraMove);

    return () => {
      controls?.removeEventListener('change', handleCameraMove);
    };
  }, [controls]);

  const isEditToolsAvailable = useAppSelector(
    getIsEditToolsAvailable(buildingGUID || '')
  );

  const isLocked = projectData.locked;

  if (!nodeData) return null;

  const isNodeHidden = getStatusByNodeOrParents(nodeData, 'isHidden');
  const isNodeLocked = getStatusByNodeOrParents(nodeData, 'isLocked');

  const isSplitModeAvailable =
    (selectedNode.type === NodeType.Building &&
      nodeData.childNodes.filter((node) => node.type === NodeType.Block)
        .length === 1) ||
    (constructionElementTypes.includes(selectedNode.type) &&
      selectedNode.type !== NodeType.Building);

  const isCutModeAvailable =
    (selectedNode.type === NodeType.Building &&
      nodeData.childNodes.filter((node) => node.type === NodeType.Block)
        .length === 1) ||
    selectedNode.type === NodeType.Block;

  const isMergeAvailable = validateMergeAvailability(
    selectedNodes.map((node) => node.guid),
    selectedNodes[0].type
  );

  const handleDelete = () => {
    userBuildingUtils.deleteSelectedConstructions([nodeData]);
  };

  const handleClickCut = () => {
    const node =
      selectedNode.type === NodeType.Building
        ? nodeData.childNodes[0]
        : selectedNode;
    dispatch(setEditedNode(node));
    dispatch(setHoveredNode(node.guid));
    dispatch(setEditMode(EditModes.Cut));
  };

  const handleClickSplit = () => {
    const node =
      selectedNode.type === NodeType.Building
        ? nodeData.childNodes[0]
        : selectedNode;
    dispatch(setEditedNode(node));
    dispatch(setHoveredNode(node.guid));
    dispatch(setEditMode(EditModes.Split));
  };

  const handlePointerDown = (event: React.PointerEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const getActions = () => {
    const displayedActions = [];

    if (isEditToolsAvailable) {
      if (isCutModeAvailable && !isMultiNodesSelected) {
        displayedActions.push({
          element: (
            <EditToolbarButton
              id="edit-toolbar__cut-button"
              icon={<CutIcon />}
              onPointerDown={handleClickCut}
            />
          ),
        });
      }
      if (isSplitModeAvailable && !isMultiNodesSelected) {
        displayedActions.push({
          element: (
            <EditToolbarButton
              id="edit-toolbar__splite-button"
              icon={<SplitIcon />}
              onPointerDown={handleClickSplit}
            />
          ),
        });
      }

      if (isMergeAvailable) {
        const mergeHandler = () => {
          //for only one element
          if (!isMultiNodesSelected) {
            switch (selectedNodes[0].type) {
              case NodeType.Storey: {
                mergeWalls(
                  nodeData.childNodes
                    .filter((node) => node.type === NodeType.Wall)
                    .map((node) => node.guid),
                  NodeType.Storey
                );
                break;
              }
              case NodeType.Block: {
                mergeWalls(
                  nodeData.childNodes
                    .filter((node) => node.type === NodeType.Wall)
                    .map((node) => node.guid),
                  NodeType.Block
                );
                break;
              }
              case NodeType.Building: {
                const buildingData = selectedNodes
                  .map((node) =>
                    getNodeData({
                      guid: node.guid,
                      nodeType: NodeType.Building,
                    })?.childNodes.filter(
                      (node) => node?.type === NodeType.Block
                    )
                  )
                  .flat();
                mergeBlocks(buildingData.map((node) => node?.guid || ''));
              }
            }
          } else {
            //for multi selected elements
            switch (selectedNodes[0].type) {
              case NodeType.Wall: {
                mergeWalls(
                  selectedNodes.map((node) => node.guid),
                  NodeType.Wall
                );
                break;
              }
              case NodeType.Storey: {
                const storeysData = selectedNodes
                  .map((node) =>
                    getNodeData({
                      guid: node.guid,
                      nodeType: node.type,
                    })?.childNodes.filter(
                      (childNode) => childNode?.type === NodeType.Wall
                    )
                  )
                  .flat();

                mergeWalls(
                  storeysData.map((node) => node?.guid || ''),
                  NodeType.Storey
                );
                break;
              }
              case NodeType.Block: {
                mergeBlocks(selectedNodes.map((node) => node.guid));
                break;
              }
            }
          }
        };

        displayedActions.push({
          element: (
            <EditToolbarButton
              id={'edit-toolbar__merge-button'}
              icon={<MergeIcon />}
              onPointerDown={mergeHandler}
            />
          ),
        });
      }
    }

    if (isFacadeDesignerModeAvailable) {
      displayedActions.length > 0 &&
        displayedActions.push({ element: Divider });

      displayedActions.push({
        element: (
          <EditToolbarButton
            id="edit-toolbar__facade-designer-button"
            icon={<FacadeDesignerIcon fill="#414042" className="w-4 h-4" />}
            onPointerDown={handleClickEnterFacadeDesigner}
          />
        ),
      });
    }

    if (
      [NodeType.Block, NodeType.Panel, NodeType.Building].includes(
        selectedNode.type
      ) &&
      !isMultiNodesSelected
    ) {
      displayedActions.length > 0 &&
        displayedActions.push({ element: Divider });

      displayedActions.push({
        element: (
          <EditToolbarButton
            id="edit-toolbar__delete-button"
            icon={<DeleteIcon />}
            onPointerDown={handleDelete}
          />
        ),
      });
    }

    return displayedActions;
  };

  const showToolbar =
    isAllowedElementSelected &&
    !isNodeHidden &&
    !isNodeLocked &&
    !isNodeHiddenByIsolation &&
    !isLocked &&
    getActions().length > 0;

  return showToolbar ? (
    <group position={position} ref={toolbarRef}>
      <Html occlude={false} zIndexRange={[0, 0]}>
        <div
          className="relative"
          onPointerDown={handlePointerDown}
          style={{
            transform: `scale(${scale}) translate(${-50 / scale}%, ${-70 / scale}%)`,
            transformOrigin: 'center center',
          }}
        >
          <div
            className={`rounded-md shadow-toolbar flex bg-white p-0.5 gap-0.5 items-center`}
          >
            {getActions().map((action, i) => (
              <span key={i}>{action.element}</span>
            ))}
          </div>
          <div className="absolute top-[27px] left-[50%] w-0 h-0 border-x-[8px] border-solid border-x-transparent border-t-[8px] border-b-0 border-t-white translate-x-[-50%] mx-auto" />
        </div>
      </Html>
    </group>
  ) : (
    <></>
  );
};

export default EditToolbar;

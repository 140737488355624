import React from 'react';
import { FlatVector2 } from '@/models';
import { Html } from 'react-konva-utils';
import { IntusTooltip } from '@/shared/elements';
import { useAppDispatch } from '@/store/hooks';
import {
  resetHoverArea,
  setHoverArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';

const ERROR_SIZE = 36;

const PlacementElementError = ({
  position,
  errorList,
  hoverArea,
  scale,
}: {
  position: FlatVector2;
  errorList?: string[];
  hoverArea?: FlatVector2[][];
  scale: number;
}) => {
  const dispatch = useAppDispatch();

  const errorsText = errorList ? (
    <div className={'text-sm'}>
      {errorList.map((error) => (
        <div className={'p-0 m-0 flex items-center'} key={error}>
          <div
            className={'w-1 h-1 bg-red-80 rounded-full ml-2 mr-3 leading-5'}
          />
          {error}
        </div>
      ))}
    </div>
  ) : null;

  return (
    <Html
      groupProps={{
        x: position[0] - ERROR_SIZE / scale / 2,
        y: position[1],
        scaleX: 1 / scale,
        scaleY: 1 / scale,
      }}
    >
      <IntusTooltip
        title={errorsText}
        placement={'right'}
        overlayClassName={'white'}
        overlayInnerStyle={{
          background: 'white',
          backgroundColor: 'white',
          color: '#414042',
          padding: 8,
          borderRadius: 4,
        }}
        overlayStyle={{
          maxWidth: '100%',
        }}
      >
        <div
          className={`text-white bg-red-80 hover:bg-red w-4 h-4 rounded-full border-2 
            border-white border-solid flex items-center justify-center font-sans select-none`}
          onPointerEnter={() => dispatch(resetHoverArea())}
          onPointerLeave={() => hoverArea && dispatch(setHoverArea(hoverArea))}
        >
          !
        </div>
      </IntusTooltip>
    </Html>
  );
};

export default PlacementElementError;

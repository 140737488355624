import React from 'react';
import { IntusButton } from '@/shared/elements';
import { ReportErrorIcon } from '@/shared/icons/ReportErrorIcon';
import { ReportStatus } from '@/models/report';
import { useAppDispatch } from '@/store/hooks';
import { setStatusReport } from '@/store/slices/reportSlice';
import { useParams } from 'react-router';

const ErrorContent: React.FC = () => {
  const { id } = useParams();

  if (!id) return <></>;

  const dispatch = useAppDispatch();

  const handelGenerateReport = () => {
    dispatch(
      setStatusReport({ id, status: ReportStatus.PROCESSING_AI_GENERATION })
    );
  };

  return (
    <>
      <ReportErrorIcon className="mb-10" />
      <IntusButton
        id="report__repeat-generation-button"
        className="rounded-lg py-[5px] px-[18px] mb-[92px]"
        onClick={handelGenerateReport}
      >
        Repeat generation
      </IntusButton>
    </>
  );
};

export default ErrorContent;

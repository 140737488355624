import React from 'react';
import { IntusButton } from '@/shared/elements';
import { WarningIcon } from '@/shared/icons/WarningIcon';
import { useAppDispatch } from '@/store/hooks';
import { QuoteProcessingIcon } from '@/shared/icons';
import { setQuoteRequestStatus } from '@/store/slices/quoteSlice';
import { QuoteStatus } from '@/models/quote.model';

export const QuoteRequestContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleRequestQuote = () => {
    dispatch(setQuoteRequestStatus(QuoteStatus.PROCESSING_EXPORT));
  };

  return (
    <>
      <div className="mb-10">
        <QuoteProcessingIcon />
      </div>
      <IntusButton
        id="report__generate-button"
        className="rounded-lg py-[5px] px-[18px] mb-10"
        onClick={handleRequestQuote}
      >
        Request
      </IntusButton>
      <div className="flex gap-[10px] items-center mx-6 mb-6 p-3 bg-light-gray-10">
        <WarningIcon className="w-6 h-6" />
        <p className="text-xs leading-5 m-0 max-w-[478px] text-dark-gray-100 font-normal">
          The previously generated report will be deleted, but it can be
          generated once again after Quote requesting.
        </p>
      </div>
    </>
  );
};

import React, { useState } from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import SaveScreenIcon from '../icons/SaveScreenIcon';
import { publish } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { useAppSelector } from '@/store/hooks';
import { getIsInDrawMode } from '@/store/slices/canvasModesSlice';

const SaveScreen = () => {
  const isInDrawMode = useAppSelector(getIsInDrawMode);

  const [showTooltip, setShowTooltip] = useState(true);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleClick = () => {
    publish(MAKE_SCREENSHOT);
    setShowTooltip(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ToolbarButton
        id="toolbar-button-save-screen"
        icon={<SaveScreenIcon />}
        tooltipText={showTooltip ? 'Save screen capture' : ''}
        onClick={handleClick}
        disabled={isInDrawMode}
      />
    </div>
  );
};

export default SaveScreen;

import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import OrthoIcon from '../icons/OrthoIcon';
import PerspectiveIcon from '../icons/PerspectiveIcon';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getCameraType,
  setCameraType,
} from '@/store/slices/canvasCamerasSlice';
import { CanvasCameraType } from '@/models';
import { getIsInDrawMode } from '@/store/slices/canvasModesSlice';

const OrthoPerspectiveView = () => {
  const dispatch = useAppDispatch();

  const currentCamera = useAppSelector(getCameraType);
  const isInDrawMode = useAppSelector(getIsInDrawMode);

  const handleClick = () => {
    dispatch(
      setCameraType(
        currentCamera === CanvasCameraType.Orthographic
          ? CanvasCameraType.Perspective
          : CanvasCameraType.Orthographic
      )
    );
  };
  return (
    <ToolbarButton
      id="toolbar-button-ortho-perspective-view"
      icon={
        currentCamera !== CanvasCameraType.Orthographic ? (
          <OrthoIcon />
        ) : (
          <PerspectiveIcon />
        )
      }
      tooltipText={`Switch to ${currentCamera === CanvasCameraType.Orthographic ? CanvasCameraType.Perspective : CanvasCameraType.Orthographic}`}
      onClick={handleClick}
      disabled={isInDrawMode}
    />
  );
};

export default OrthoPerspectiveView;

import React, { useEffect } from 'react';
import IntusProgressBar from '@/shared/elements/ProgressBar/ProgressBar';
import { QuoteProcessingIcon } from '@/shared/icons';
import { setQuoteRequestStatus } from '@/store/slices/quoteSlice';
import { QuoteStatus } from '@/models/quote.model';
import { useAppDispatch } from '@/store/hooks';

interface QuoteProcessingContentProps {
  percent: number;
}

export const QuoteProcessingContent: React.FC<QuoteProcessingContentProps> = ({
  percent,
}) => {
  const estimatedTime = 2;
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      const r = Math.random() < 0.5;
      dispatch(
        setQuoteRequestStatus(r ? QuoteStatus.ERROR : QuoteStatus.READY)
      );
    }, 3000);
  }, []);

  return (
    <>
      <div className="mb-10">
        <QuoteProcessingIcon />
      </div>
      <div className="flex flex-col gap items-center mb-[86px]">
        <p className="m-0 mb-1 text-xs leading-5 text-dark-gray-100">
          {percent}%
        </p>
        <IntusProgressBar
          strokeColor={'#65BD51'}
          percent={percent}
          showInfo={false}
          className="h-[6px]"
        />
        <p className="m-0 mt-2 text-dark-gray-80 text-xs leading-5">
          Estimated waiting time {estimatedTime} minutes
        </p>
      </div>
    </>
  );
};

export const DIRECTIONAL_INPUT__SET = 'directional-input__set-value';

export const DIRECTIONAL_INPUT__UPDATE = 'directional-input__update-value';

export const DIRECTIONAL_INPUT__ESCAPE = 'directional-input__escape';

export const SAVE_CURRENT_CAMERA_VIEW = 'save_current_camera_view';

export const ROTATE_CAMERA_NORTH = 'rotate_camera_north';

export const SET_BUILDING_CREATION_PARAMETERS =
  'set_building_creation_parameters';

export const OPEN_CAMERAS_POPOVER = 'open_cameras_popover';

export const CHANGE_SAVE_CAMERA_STATE = 'change_save_camera_state';

export const MAKE_SCREENSHOT = 'make_screenshot';

export const CREATE_MULLION = 'create_mullion';

export const SET_WINDOW_CONTEXT_MENU_POSITION =
  'set_window_context_menu_position';

export const OPEN_WINDOW_CREATOR = 'open_window_creator';

export const MEASUREMENT_CHANGE_PROCESSING = 'measurement_change_processing';

export const GENERATE_CAMERA_SNAPSHOTS = 'generate_camera_snapshots';
export const GENERATE_CAMERA_SNAPSHOTS_SUCCESS =
  'generate_camera_snapshots_success';

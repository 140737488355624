import {
  CornerGridAlign,
  FlatVector2,
  GridLineData,
  UserBuildingCoordinatesObject,
} from '@/models';
import {
  DEFAULT_CORNER_GRID_WIDTH,
  getEdgePoints,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { inRange, isEqual } from 'lodash';
import {
  GridLineDataForFD,
  WallToCornerAlignments,
} from '@/components/FacadeDesigner/models/grids.model';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { removeSpacesFromThousands } from '@/shared/helpers/format-data';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useBuildingInfo } from '@/shared/hooks/useBuildingInfo';
import { round } from 'mathjs';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  convert2DPointsToDistanceInMeters,
  getWallWidth,
} from '@/shared/helpers/metrics';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { useParams } from 'react-router';
import { uuidv7 } from 'uuidv7';
import { useStore } from 'react-redux';
import { getGridPlacementAbsoluteOffset } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { RootState } from '@/store';

export const useGridLines = (buildingGUID: string) => {
  const store = useStore();
  const { updateBuildingPlacements } = useUpdateUserBuildingData();
  const { getBuildingWallsList } = useBuildingInfo(buildingGUID);
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });

  const generateCornerGrids = (
    building: UserBuildingCoordinatesObject
  ): void => {
    const contourPoints = building.blocks
      .map((block) => block.storeys.map((storey) => getEdgePoints(storey)))
      .flat(2);

    const map = new Map();
    contourPoints.forEach((v) =>
      map.set(
        JSON.stringify(v),
        map.has(JSON.stringify(v)) ? map.get(JSON.stringify(v)) + 1 : 1
      )
    );

    const buildingWallsList = getBuildingWallsList();

    const wallsAlignedWithContour: WallToCornerAlignments[] =
      buildingWallsList.reduce((acc: WallToCornerAlignments[], wall) => {
        const alignIndexes = wall.points
          .map((wallPoint, idx) => {
            const aligned = contourPoints.some((edgePoint) =>
              isEqual(edgePoint, wallPoint)
            );
            return aligned ? idx : null;
          })
          .filter((v) => v !== null);
        if (!alignIndexes.length) return acc;
        acc.push({
          wallData: wall,
          left: alignIndexes.some((idx) => idx === 1),
          right: alignIndexes.some((idx) => idx === 0),
        });
        return acc;
      }, []);

    const cornerGridLinesData: {
      wallGUID: string;
      gridLines: GridLineData[];
    }[] = wallsAlignedWithContour.map((wall) => {
      const wallWidth = Number(
        removeSpacesFromThousands(getWallWidthMetric([wall.wallData]), false)
      );

      const gridLines: GridLineData[] = [];
      if (wall.left)
        gridLines.push({
          offsetFromLeftEdge: DEFAULT_CORNER_GRID_WIDTH,
          cornerAlign: CornerGridAlign.Left,
          guid: uuidv7(),
        });

      if (wall.right)
        gridLines.push({
          offsetFromLeftEdge: round(wallWidth - DEFAULT_CORNER_GRID_WIDTH, 2),
          cornerAlign: CornerGridAlign.Right,
          guid: uuidv7(),
        });

      return { wallGUID: wall.wallData.guid, gridLines };
    });

    updateBuildingPlacements({
      buildingGUID: buildingGUID,
      placementData: cornerGridLinesData,
    });
  };

  const generateCustomGrids = ({
    wallsData,
    startPoint,
  }: {
    wallsData: WallSearchResults[];
    startPoint: { x: number; z: number };
  }): GridLineDataForFD[] => {
    const absoluteOffset = getGridPlacementAbsoluteOffset(
      store.getState() as RootState
    );
    if (!absoluteOffset) return [];

    const newGridLineGUIDs: GridLineDataForFD[] = [];

    const gridLineDataToUpdate: {
      wallGUID: string;
      gridLines: GridLineData[];
    }[] = wallsData.map((wall) => {
      const wallWidth = round(
        Number(
          convertMetersToMillimeters(getWallWidth(wall.points, multiplyRate))
        ),
        2
      );
      const gridLineData = {
        wallGUID: wall.guid,
        gridLines: [...wall.gridLines],
      };

      const wallBottomLeftPoint: FlatVector2 = [
        wall.points[1][0],
        wall.points[1][2],
      ];

      const wallOffset: number = round(
        Number(
          convertMetersToMillimeters(
            convert2DPointsToDistanceInMeters(
              wallBottomLeftPoint,
              [startPoint.x, startPoint.z],
              multiplyRate
            )
          )
        ),
        2
      );

      const gridOffset = round(absoluteOffset - wallOffset, 2);
      if (inRange(gridOffset, 0, wallWidth)) {
        const newGridLine = {
          offsetFromLeftEdge: gridOffset,
          guid: uuidv7(),
          cornerAlign: null,
        };

        gridLineData.gridLines.push(newGridLine);

        newGridLineGUIDs.push({
          ...newGridLine,
          wallGUID: wall.guid,
          absoluteOffset,
        });
      }

      return gridLineData;
    });

    updateBuildingPlacements({
      buildingGUID,
      triggerBEUpdate: true,
      placementData: gridLineDataToUpdate,
    });
    return newGridLineGUIDs;
  };

  return {
    generateCornerGrids,
    updateBuildingPlacements,
    generateCustomGrids,
  };
};

export const STAGE_WIDTH = window.innerWidth * 0.83;
export const STAGE_HEIGHT = window.innerHeight * 0.85;
export const INITIAL_SCALE = (STAGE_HEIGHT * 0.4) / 1000;
export const IMAGE_MAN_SCALE = 0.25;
export const SCALE_SPEED = 1.02;

export const STAGE_BOTTOM_PADDING_TO_FLOOR_0 = 12;

export const MEASUREMENT_PADDING = 30;

export const BORDERLINE_DEFAULT_POINTS: number[][] = [
  [-10000, (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE],
  [10000, (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE],
];

export const MAN_CONTOUR_PARAMS = {
  offsetFromWindowFrame: 150,
  width: (190 * INITIAL_SCALE) / IMAGE_MAN_SCALE,
  height:
    (1750 + STAGE_BOTTOM_PADDING_TO_FLOOR_0 / INITIAL_SCALE) * INITIAL_SCALE,
};

export const ACTIVE_ELEMENT_COLOR = '#65BD51';
export const DEFAULT_ELEMENT_COLOR = '#414042';
export const DISABLED_ELEMENT_COLOR = '#B3B2B4';
export const ERROR_ELEMENT_COLOR = '#D23736';

import { createSlice } from '@reduxjs/toolkit';

interface SharedState {}

const initialState: SharedState = {};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {},
});

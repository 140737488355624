import React from 'react';
import { Line } from 'react-konva';
import { BUILDING_SELECTED_CONTOUR_COLOR } from '@/shared/materials';

interface WallViewProps {
  wallWidth: number;
  wallHeight: number;
  scale: number;
  initialScale: number;
  reportView?: boolean;
}

const WallView: React.FC<WallViewProps> = ({
  wallWidth,
  wallHeight,
  scale,
  initialScale,
  reportView,
}) => {
  const wallPoints = [
    [0, 0],
    [wallWidth, 0],
    [wallWidth, wallHeight],
    [0, wallHeight],
  ];
  return (
    <>
      <Line
        points={wallPoints.flat()}
        fill={'#fff'}
        closed
        stroke={
          reportView
            ? '#B3B2B4'
            : `#${BUILDING_SELECTED_CONTOUR_COLOR.getHexString()}`
        }
        strokeWidth={reportView ? 1 : (initialScale * 20) / scale}
        strokeScaleEnabled={reportView ? false : true}
      />
    </>
  );
};

export default WallView;

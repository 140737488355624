export const GRID_LINE_CROSS_CORNER =
  'The grid line crosses the corner panel zone';

export const WINDOW_CROSS_CORNER = 'The window crosses the corner panel zone';

export const GRID_LINE_CROSS_WINDOW = 'The grid line crosses the window';
export const WINDOW_CROSS_GRID_LINE = 'The window crosses the grid line';
export const WINDOW_CROSS_WINDOW = 'The windows overlap';

export const ALLOWED_ERRORS_TO_UPDATE_INPUT = [
  GRID_LINE_CROSS_CORNER,
  GRID_LINE_CROSS_WINDOW,
  WINDOW_CROSS_WINDOW,
  WINDOW_CROSS_CORNER,
  WINDOW_CROSS_GRID_LINE,
];
